import { COLORS } from '@/assets/сolors/colors';

export const sxInputWrapper = {
  position: 'relative',
  mb: '12px',
  '&:last-child': {
    mb: '0px',
  },
};

export const sxFormField = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
};

export const sxFormFieldError = {
  '& label.Mui-focused': {
    color: COLORS.ERROR,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.ERROR,
    },
    '&:hover fieldset': {
      borderColor: COLORS.ERROR,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.ERROR,
    },
  },
};
