import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';

import { sxSpinner, sxSpinnerWrapper } from './styles';

const Spinner = () => {
  return (
    <Box sx={sxSpinnerWrapper}>
      <CircularProgress size={64} thickness={1} sx={sxSpinner} />
    </Box>
  );
};

export default Spinner;
