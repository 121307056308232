import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import { sxAccordionContacts, sxAccordionContactsMobile, sxAccordionSummary, sxAccordionSummaryMobile, sxLawyerButton, sxLawyerButtonMobile, sxWarning } from './styles';
import { AccessTime, WarningAmber } from '@mui/icons-material';
import { formatTime } from '@/utils/formatSecondsToTime';
import { Tracker } from '@/redux/types/tracker';
import { useAppDispatch } from '@/redux/store';
import { setSelectedTracker } from '@/redux/slices/trackerSlice';
import TrackerAvatar from '../../../_common/TrackerAvatar';
import Button from '../../../_common/Button';
import { Desktop, Mobile } from '@/utils/mediaQuery';

interface TrackerAccordionSummaryProps {
  trackerSummary: Tracker;
  onModalOpenChange: (isOpen: boolean) => void;
}

const TimeTrackerAccordionSummary: React.FC<TrackerAccordionSummaryProps> = ({ 
  trackerSummary,
  onModalOpenChange,
}) => {
  const {
  unitUser,
  timeSeconds,
  courtCase,
  status,
  } = trackerSummary;

  const dispatch = useAppDispatch();

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onModalOpenChange(true);
    dispatch(setSelectedTracker(trackerSummary));
  };

  return (
    <>
      <Desktop>
        <Box sx={sxAccordionSummary}>
          <Box 
            sx={{
              maxWidth: '420px',
              width: '100%',
              alignItems: 'center',
              background: 'none',
            }}>
            <Button
              sx={sxLawyerButton}
              variant='contained'
              title='Адвокат'
              onClick={handleButtonClick}
              buttonTitle={<TrackerAvatar name={unitUser.name} img={unitUser.image} />}
            />
          </Box>
          <Box sx={sxAccordionContacts}>
            <Box display='flex' alignItems='center'>
              <AccessTime />
              {status === 'tracking' ? (
                <>
                  <Typography marginLeft={1} marginRight={3}>Час зараз трекається</Typography>
                  {!courtCase && <WarningAmber sx={sxWarning} />}
                </>
              ) : (
                timeSeconds && courtCase ? (
                  <Typography marginLeft={1}>Затреканий час: {formatTime(timeSeconds)}</Typography>
                ) : (
                  <>
                  <Typography marginLeft={1} marginRight={3}>Затреканий час: {formatTime(timeSeconds)}</Typography>
                    <WarningAmber sx={sxWarning} />
                  </>
                )  
              )}
            </Box>
          </Box>
        </Box>
      </Desktop>

      <Mobile>
        <Box sx={sxAccordionSummaryMobile}>
          <Box>
            <Button
              sx={sxLawyerButtonMobile}
              variant='contained'
              title='Адвокат'
              onClick={handleButtonClick}
              buttonTitle={<TrackerAvatar name={unitUser.name} img={unitUser.image} />}
            />
          </Box>
          <Box sx={sxAccordionContactsMobile}>
            <Box display='flex' alignItems='center'>
              <AccessTime />
              {status === 'tracking' ? (
                <>
                  <Typography marginLeft={1} marginRight={3}>Час трекається</Typography>
                  {!courtCase && <WarningAmber sx={sxWarning} />}
                </>
              ) : (
                timeSeconds && courtCase ? (
                  <Typography marginLeft={1}>{formatTime(timeSeconds)}</Typography>
                ) : (
                  <>
                  <Typography marginLeft={1} marginRight={3}>{formatTime(timeSeconds)}</Typography>
                    <WarningAmber sx={sxWarning} />
                  </>
                )  
              )}
            </Box>
          </Box>
        </Box>
      </Mobile>
    </>
  );
};

export default TimeTrackerAccordionSummary;
