import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { sxAlert, sxAlertMobile, sxStack } from './styles';
import { getNotification, setClose } from '@/redux/slices/notificationSlice';
import { useAppSelector } from '@/redux/store';
import { useMediaQuery } from 'react-responsive';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Notification: React.FC = () => {
  const { type, message, open } = useAppSelector(getNotification);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleClose = () => {
    dispatch(setClose());
  };

  return (
    <Stack spacing={2} sx={sxStack}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type} sx={isMobile ? sxAlertMobile : sxAlert}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Notification;
