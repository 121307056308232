import { COLORS } from '@/assets/сolors/colors';

export const sxListItem = (active: boolean) => {
  return {
    background: 'transparent',
    padding: '16px 0',
    fontWeight: 700,
    display: 'flex',
    color: active ? COLORS.WHITE : COLORS.MAIN,
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    textAlign: 'start',
    '&:hover': {
      background: 'transparent',
    },
  };
};

export const sxWrapper = {
  padding: '12px',
  borderBottom: '1px solid #e8e8e8',
  '&:hover': {
    cursor: 'pointer',
  },
};

export const sxCardWrapper = {
  padding: '12px',
  boxShadow: '0 4px 12px 0 #e8e8e8',
  transition: '0.2s',
  '&:hover': {
    transition: '0.2s',
    cursor: 'pointer',
  },
};

export const sxWrapperActive = {
  background: COLORS.MAIN,
  padding: '12px',
  borderBottom: '1px solid #e8e8e8',
  transition: '0.2s',
};

export const sxListItemWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  textAlign: 'start',
  width: '100%',
};

export const sxDescription = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 8px',
};

export const sxPriceWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};
