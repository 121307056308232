import { Box, Collapse } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { sxWrapper } from './styles';
import { getSelectedCategory, setSelectedCategory } from '@/redux/slices/categoriesSlice';
import { CategoryData } from '@/redux/types/category';
import { ServiceData } from '@/redux/types/service';
import CategoryForm from '@/forms/Forms/CategoryForm';
import ServiceForm from '@/forms/Forms/ServiceForm';
import FormWrapper from '@/forms/FormWrapper';
import ServiceItem from '../ServiceItem';
import { useAppSelector } from '@/redux/store';

interface ServiceItemsProps {
  data: CategoryData[] | ServiceData[];
  deleteHandler?: (id: string) => void;
  selectable?: boolean;
  otherEditProps?: boolean;
  isInputVisible?: boolean;
  setInputVisible?: (arg: boolean) => void;
  btnTitle?: string;
}

const ServiceItems: React.FC<ServiceItemsProps> = ({
  data,
  deleteHandler,
  selectable,
  otherEditProps,
  setInputVisible,
  isInputVisible,
  btnTitle,
}) => {
  const dispatch = useDispatch();
  const isArray = Array.isArray(data);
  const categorySelectHandler = (category: CategoryData | ServiceData) => {
    dispatch(setSelectedCategory(category));
  };
  const selectedCategory = useAppSelector(getSelectedCategory);

  return (
    <Box>
      <Box sx={sxWrapper}>
        {isArray && (
          <TransitionGroup>
            {data.map((category) => {
              return (
                <Collapse timeout={600} key={category.id}>
                  <ServiceItem
                    otherEditProps={otherEditProps}
                    selectable={selectable}
                    category={category}
                    deleteHandler={deleteHandler}
                    selectHandler={categorySelectHandler}
                    {...category}
                  />
                </Collapse>
              );
            })}
          </TransitionGroup>
        )}
      </Box>
      {btnTitle === 'Додати категорію' && (
        <FormWrapper
          btnTitle={btnTitle}
          isInputVisible={isInputVisible}
          setInputVisible={setInputVisible}
        >
          <CategoryForm setInputVisible={setInputVisible} />
        </FormWrapper>
      )}
      {btnTitle === 'Додати сервіс' && selectedCategory  && (
        <FormWrapper
          btnTitle={btnTitle}
          isInputVisible={isInputVisible}
          setInputVisible={setInputVisible}
        >
          <ServiceForm setInputVisible={setInputVisible} />
        </FormWrapper>
      )}
    </Box>
  );
};

export default ServiceItems;
