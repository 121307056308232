import React, { useEffect, useState } from 'react';
import { deleteClient, getClient, getClients, setSelectedClient } from '@/redux/slices/clientsSlice';
import { rootActions, useAppDispatch, useAppSelector } from '@/redux/store';
import { SelectedClient } from '@/redux/types/client';
import ClientAccordionDetails from './ClientAccordionDetails/ClientAccordionDetails';
import ClientAccordionSummary from './ClientAccordionSummary/ClientAccordionSummary';
import ModalComponent from '../../_common/ModalComponent';
import ClientCard from './ClientCard';
import ClientForm from '@/forms/Forms/ClientForm';
import AccordionComponent from '../../_common/AccordionComponent/AccordionComponent';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import RequisitesForm from '@/forms/Forms/RequisitesForm';
import ServicePackageForm from '@/forms/Forms/ServicePackageForm';
import { Box } from '@mui/material';
import { sxClientsBox, sxClientsBoxMobile } from './styles';
import useActionCreators from '@/hooks/useActionCreators';
import { useNavigate } from 'react-router-dom';
import { getAllCategories } from '@/redux/slices/categoriesSlice';
import { getModalComponentStatus } from '@/redux/slices/modalComponentSlice';
import { useMediaQuery } from 'react-responsive';

interface ClientProps {
  client: SelectedClient;
}

const Client: React.FC<ClientProps> = ({ client }) => {
  const [expanded, setExpanded] = useState<string | boolean>(client.id);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [requisitesModalOpen, setRequisitesModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const [isPacketModalOpen, setIsPacketModalOpen] = useState<boolean>(false);
  const categories = useAppSelector(getAllCategories) || [];
  const actions = useActionCreators(rootActions);
  const modalComponentStatus = useAppSelector(getModalComponentStatus);
  const { clientError, clientStatus } = useAppSelector(state => state.clients);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const servicePackegesHandler = () => {
    setIsPacketModalOpen(true)
  };
  
  useEffect(() => {
    if (client)
    setExpanded(client.id);
  }, [client.id]);
  
  const accordionOpen =
    (panel: string, client: SelectedClient) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : true);
      dispatch(setSelectedClient(client));
    };

  const modalCloseHandler = () => {
    actions.setModalComponentStatus(false);
    setEdit(false);
    setRequisitesModalOpen(false);
    setIsPacketModalOpen(false);
  };

  const deleteClientHandler = async () => {
    if (unitId) {
      await dispatch(deleteClient({ unitId, clientId: client.id }));
      await dispatch(getClients({ unitId }));
    }
  };

  const requisitesHandler = () => {
    setRequisitesModalOpen(true);
  };

  const editClientHandler = () => {
    setEdit(true);
  };

  const openClientHandler = () => {
    if (unitId) {
      actions.setModalComponentStatus(true);
      dispatch(getClient({ unitId, clientId: client.id }));
    }
  }

  const id = client?.id;

  if (clientError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={clientStatus === 'loading'
      ? (isMobile ? { ...sxClientsBoxMobile, pointerEvents: 'none' } : { ...sxClientsBox, pointerEvents: 'none' }) 
      : (isMobile ? { ...sxClientsBoxMobile }  : { ...sxClientsBox })}
    >
      <AccordionComponent
        sx={{ width: '100%' }}
        id={id}
        expanded={expanded === id}
        handleAccordionExpandChange={accordionOpen(id, client)}
        accordionSummaryChildren={<ClientAccordionSummary {...client} />}
        ariaControls={id}
        accordionDetailsChildren={
          <ClientAccordionDetails
            deleteClient={deleteClientHandler}
            modalClientOpen={openClientHandler}
          />
        }
      />
      <ModalComponent
        title={edit ? 'Редагування' : 'Дані клієнта'}
        isOpen={modalComponentStatus}
        setClose={modalCloseHandler}
        backStep={edit ? setEdit : modalCloseHandler}
      >
        {edit ? (
          <ClientForm
            title='Зберегти'
            modalClose={modalCloseHandler}
            isEditForm={edit}
            setEditForm={setEdit}
          />
        ) : (
          <ClientCard
            client={client}
            setEdit={editClientHandler}
            setRequisitesModalOpen={requisitesHandler}
            setClose={modalCloseHandler}
            servicePackegesHandler={servicePackegesHandler}
          />
        )}
      </ModalComponent>
      <ModalComponent
        title='Дані клієнта'
        isOpen={isModalOpen}
        setClose={() => setIsModalOpen(false)}
      >
        <ClientForm modalClose={() => setIsModalOpen(false)} />
      </ModalComponent>
      <ModalComponent
        title={client && client.requisites ? 'Редагувати реквізити' : 'Створити реквізити'}
        isOpen={requisitesModalOpen}
        setClose={modalCloseHandler}
        backStep={client && client.requisites ? setRequisitesModalOpen : modalCloseHandler}
      >
        {requisitesModalOpen ? (
          <RequisitesForm
            setIsOpen={setRequisitesModalOpen}
            client={client}
          />
        ) : (
          <ClientCard
          client={client}
          setEdit={editClientHandler}
          setRequisitesModalOpen={requisitesHandler}
          setClose={modalCloseHandler}
          servicePackegesHandler={servicePackegesHandler}
        />
        )}
      </ModalComponent>
      <ModalComponent
        title={client && client.servicePackages && client.servicePackages.length > 0 ? 'Редагувати пакет послуг' : 'Створити пакет послуг'}
        isOpen={isPacketModalOpen}
        setClose={modalCloseHandler}
        backStep={client && client.servicePackages ? setIsPacketModalOpen : modalCloseHandler}
      >
        {isPacketModalOpen ? (
          <ServicePackageForm
            categories={categories}
            client={client}
            setIsOpen={setIsPacketModalOpen}
          />
        ) : (
          <ClientCard
            client={client}
            setEdit={editClientHandler}
            setRequisitesModalOpen={requisitesHandler}
            setClose={modalCloseHandler}
            servicePackegesHandler={servicePackegesHandler}
          />
        )}
      </ModalComponent>
    </Box>
  );
};

export default Client;
