import { AlertColor } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import { MyStore } from '../store';

const initialState: object = {};
const noteFactory = (payload: string, type: AlertColor) => ({
  id: Number(new Date()),
  type,
  message: payload,
  open: !!payload,
});
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setSuccess: (state, { payload }) => noteFactory(payload, 'success'),
    setError: (state, { payload }) => noteFactory(payload, 'error'),
    setWarning: (state, { payload }) => noteFactory(payload, 'warning'),
    setInfo: (state, { payload }) => noteFactory(payload, 'info'),
    setClose: () => initialState,
  },
});

export const { setSuccess, setError, setClose } = notificationSlice.actions;

export const getNotification = (state: MyStore) => state[notificationSlice.name];

export default notificationSlice;
