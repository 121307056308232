export const sxContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

export const sxWrapper = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const sxImage = {
  minWidth: 100,
  minHeight: 100,
  border: '1px solid #e8e8e8',
  mb: '12px',
};

export const sxImageWrapper = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  minWidth: '140px',
};

export const sxHeaderWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  boxSizing: 'border-box',
};

export const sxStatusWrapper = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
