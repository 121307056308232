import { COLORS } from '@/assets/сolors/colors';

export const sxBlockedStatusBtn = {
  backgroundColor: 'transparent',
  color: '#ed6c02',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const sxActiveStatusBtn = {
  backgroundColor: 'transparent',
  color: COLORS.SUCCESS,
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const sxFiredStatusBtn = {
  backgroundColor: 'transparent',
  color: COLORS.DELETE,
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const sxEditBtn = {
  width: '100%',
  color: COLORS.MAIN,
  background: COLORS.WHITE,
  span: {
    margin: '0px',
  },
  svg: {
    ml: '8px',
  },
  '&:hover': {
    background: COLORS.WHITE,
    color: COLORS.SUCCESS,
  },
};
