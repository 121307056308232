import { COLORS } from '@/assets/сolors/colors';

export const sxDeleteItem = {
  '&:hover': {
    background: COLORS.DELETE,
  },
};

export const sxButtonIcon = {
  marginLeft: '10px',
};

export const sxInvoiceModal = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
};

export const sxInvoiceHeader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  mb: 2,
};

export const sxClient = {
  marginRight: '15px',
  marginTop: '10px',
};

export const sxStatus = {
  marginRight: '15px',
  marginTop: '10px',
  width: '50%',
};

export const sxInvoiceTerm = {
  marginTop: '10px',
};

export const sxRemoveLine = {
  borderBottom: 'none',
};
