/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { setSuccess } from './notificationSlice';
import { UnitUserRequest, UnitUsers } from '../types/unitUsers';
import { MyStore } from '../store';

export const postUnitUser = createAsyncThunk(
  'unitUsers/postUnitUser',
  async ({ unitId, body }: { unitId: string, body: UnitUserRequest }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.UNIT_USERS}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні користувача!');
      }

      dispatch(setSuccess('Користувача створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getUnitUsers = createAsyncThunk(
  'unitUsers/getUnitUsers',
  async (unitId: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.UNIT_USERS}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку користувачів!');
      }

      dispatch(setUnitUsers(res));
      dispatch(setSelectedUser(res.data[0]));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getUnitRoles = createAsyncThunk(
  'unitUsers/getUnitRoles',
  async (unitId: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.ORGANIZATIONS}/${unitId}${API_ENDPOINTS.UNIT_ROLES}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку ролей користувачів!');
      }

      dispatch(setUnitRole(res.data));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getUnitUser = createAsyncThunk(
  'unitUsers/getUnitUser',
  async (unitId: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNIT_USERS}/${unitId}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі користувача!');
      }

      dispatch(setSelectedUser(res));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const editUnitUser = createAsyncThunk(
  'unitUsers/editUnitUser',
  async ({ id, body }: { id: string, body: UnitUserRequest }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNIT_USERS}/${id}`;
      const res = await Http.patch(url, body);

      if (res) {
        dispatch(setSuccess('Користувача змінено успішно'));
        return res;
      } else {
        throw new Error('Немає відповіді з сервера при редагуванні клієнта!');
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.unitUserStatus = 'loading';
  state.unitUserError = null;
}

const setResolved = (state: any) => {
  state.unitUserStatus = 'resolved';
}

const setError = (state: any, action: any) => {
  state.unitUserStatus = 'rejected';
  state.unitUserError = action.payload;
}

const initialState: UnitUsers = {
  data: [],
  pagination: null,
  selectedUser: null,
  role: null,
  unitUserStatus: null,
  unitUserError: null,
};

const unitUsersSlice = createSlice({
  name: 'unitUsers',
  initialState,
  reducers: {
    setUnitUsers: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setUnitRole: (state, action) => {
      state.role = action.payload;
    },
    setUnitUserError: (state, action) => {
      state.unitUserError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUnitUser.pending, setLoading)
      .addCase(getUnitUsers.pending, setLoading)
      .addCase(getUnitRoles.pending, setLoading)
      .addCase(getUnitUser.pending, setLoading)
      .addCase(editUnitUser.pending, setLoading)
      .addCase(postUnitUser.fulfilled, setResolved)
      .addCase(getUnitUsers.fulfilled, setResolved)
      .addCase(getUnitRoles.fulfilled, setResolved)
      .addCase(getUnitUser.fulfilled, setResolved)
      .addCase(editUnitUser.fulfilled, setResolved)
      .addCase(postUnitUser.rejected, setError)
      .addCase(getUnitUsers.rejected, setError)
      .addCase(getUnitRoles.rejected, setError)
      .addCase(getUnitUser.rejected, setError)
      .addCase(editUnitUser.rejected, setError);
  },
});

export const { setUnitUsers, setSelectedUser, setUnitRole, setUnitUserError } = unitUsersSlice.actions;

export const getAllUnitUsers = (state: MyStore) => state[unitUsersSlice.name].data;
export const getSelectedUnitUser = (state: MyStore) => state[unitUsersSlice.name].selectedUser;
export const getUnitUserError = (state: MyStore) => state[unitUsersSlice.name].unitUserError;

export default unitUsersSlice;
