import { Box, Typography } from '@mui/material';
import React from 'react';
import { Invitation } from '@/redux/types/invitation';
import InvitationOrganization from './InvitationOrganization';
import InvitationRole from './InvitationRole';
import InvitationUnit from './InvitationUnit';
import moment from 'moment/moment';
import { sxInvitationInfo } from './styles';

const InvitationsInfo: React.FC<Invitation> = ({
  organization,
  expires,
  unit,
  role,
}) => {
  const expiresTime = moment(expires).format('LLL');
  return (
    <Box sx={sxInvitationInfo}>
      <Box>
        {organization && (
          <InvitationOrganization {...organization} />
        )}
        {unit && (
          <InvitationUnit {...unit} />
        )}
        {role && (
          <InvitationRole {...role} />
        )}
      </Box>
      <Typography fontSize='12px' variant='button'>
        Запрошення дійсне до: {expiresTime}
      </Typography>
    </Box>
  );
};

export default InvitationsInfo;
