import { Box, List, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { appItems } from './defaultData';
import { sxAppBarMenuContainer, sxAppBarMenuWrapper, sxAppBarMenuWrapperMobile } from './styles';
import Logout from '../../Logout';
import AppBarMenuListItems from '../AppBarMenuListItems';
import { useMediaQuery } from 'react-responsive';

interface AppMenuProps {
  isOpen: boolean;
  openHandler: () => void;
  closeHandler: () => void;
}

const AppBarMenu: React.FC<AppMenuProps> = ({
  isOpen,
  openHandler,
  closeHandler,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <SwipeableDrawer
      swipeAreaWidth={30}
      minFlingVelocity={100}
      open={isOpen}
      onClick={closeHandler}
      onClose={closeHandler}
      onOpen={openHandler}
    >
      <List component='nav' sx={isMobile ? sxAppBarMenuWrapperMobile : sxAppBarMenuWrapper}>
        <Box sx={sxAppBarMenuContainer}>
          <AppBarMenuListItems appManuListItemsData={appItems} />
          <Logout />
        </Box>
      </List>
    </SwipeableDrawer>
  );
};

export default AppBarMenu;
