import { Box } from '@mui/material';
import React from 'react';
import { sxUserItem } from './styles';
import { useAppSelector } from '@/redux/store';
import { UnitUserData } from '@/redux/types/unitUsers';
import {
  sxListItem,
  sxListItemWrapper,
  sxWrapper,
  sxWrapperActive,
} from '../../Services/ServiceItem/styles';
import UserCard from '../UserCard';
import Button from '../../../_common/Button';
import { getSelectedUnitUser } from '@/redux/slices/unitUsersSlice';
import { Desktop, Mobile } from '@/utils/mediaQuery';

interface UserItemProps {
  selectHandler: (arg: UnitUserData) => void;
  userData: UnitUserData;
  selectable?: boolean;
  selectedUser?: boolean;
  userModalOpen?: () => void;
}

const UserItem: React.FC<UserItemProps> = ({
  selectHandler,
  userData,
  selectable,
  selectedUser,
  userModalOpen,
}) => {
  const { id } = useAppSelector(getSelectedUnitUser) || {};
  const name = userData?.name;
  const selected = id === userData?.id;

  return (
    <>
      <Desktop>
        {userData && (
          <Box sx={selected ? sxWrapperActive : sxWrapper}>
            <Box sx={sxListItemWrapper}>
              <Box
                sx={sxUserItem}
                onClick={() => selectable && selectHandler(userData)}
              >
                <Button
                  fullWidth
                  sx={sxListItem(selected)}
                  disableRipple
                  buttonTitle={name}
                />
                {selectedUser && userData && <UserCard unitUser={userData} />}
              </Box>
            </Box>
          </Box>
        )}
      </Desktop>

      <Mobile>
        {userData && (
          <Box sx={sxWrapper}>
            <Box sx={sxListItemWrapper}>
              <Box
                sx={sxUserItem}
                onClick={() => selectable && selectHandler(userData)}
              >
                <Button
                  fullWidth
                  sx={sxListItem(false)}
                  disableRipple
                  buttonTitle={name}
                  onClick={userModalOpen}
                />
                {selectedUser && userData && <UserCard unitUser={userData} />}
              </Box>
            </Box>
          </Box>
        )}
      </Mobile>
    </>
  );
};

export default UserItem;
