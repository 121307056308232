import { OptionsData } from '@/components/ui/SelectComponent/SelectComponent';
import { CategoryData } from '@/redux/types/category';

export const createCategoryOptions = (categories: CategoryData[]) => {
  return (
    categories.reduce((arr, item) => {
      const options =
        item.services.reduce((arr, element) => {
          return [
            ...arr,
            {
              label: element.name,
              value: element.id,
            },
          ];
        }, [] as OptionsData[]) || [];

      return [
        ...arr,
        {
          label: item.name,
          value: item.id,
          options,
        },
      ];
    }, [] as OptionsData[]) || []
  );
};

export const createServiceOptions = (selectedCategory: OptionsData[]) => {
  return (
    selectedCategory.reduce((arr, { services, label, value }) => {
      const options =
        services?.reduce((arr, { name, id }) => {
          return [
            ...arr,
            {
              label: name,
              value: id,
              rating: label,
            },
          ];
        }, [] as OptionsData[]) || [];

      return [
        ...arr,
        {
          label,
          value,
          options,
        },
      ];
    }, [] as OptionsData[]) || []
  );
};

export const createSelectCategoryOptions = (categories: CategoryData[]) => {
  return (
    categories.reduce((arr, item) => {
      return [
        ...arr,
        {
          label: item.name,
          value: item.id,
          services: item.services,
        },
      ];
    }, [] as OptionsData[]) || []
  );
};
