import {
  AccessTimeFilled,
  Groups,
  Home,
  Key,
  ReceiptLong,
  ReportGmailerrorred,
  SupervisedUserCircle,
  Work
} from '@mui/icons-material';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sxNotFoundBtn, sxNotFoundBtnMobile, sxNotFoundIcon } from '../assets/_shared/styles';
import Button from '@/components/_common/Button';
import { ROUTES } from '@/constants';
import { getAgreementError, setAgreementError } from '@/redux/slices/agreementSlice';
import { getCaseNoteError, setCaseNoteError } from '@/redux/slices/caseNoteSlice';
import { getCaseError, setCaseError } from '@/redux/slices/casesSlice';
import { getCategoryError, setCategoryError } from '@/redux/slices/categoriesSlice';
import { getClientError, setClientError } from '@/redux/slices/clientsSlice';
import { getInvitationError, setInvitationError } from '@/redux/slices/invitationSlice';
import { getInvoiceError, setInvoiceError } from '@/redux/slices/invoiceSlice';
import { getSearchError, setSearchError } from '@/redux/slices/SearchSlice';
import { getServiceError, setServiceError } from '@/redux/slices/servicesSlice';
import { getTrackerError, setTrackerError } from '@/redux/slices/trackerSlice';
import { getUnitUserError, setUnitUserError } from '@/redux/slices/unitUsersSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { useMediaQuery } from 'react-responsive';

interface ErrorPageProps {
  errorMessage?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ errorMessage }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchError = useAppSelector(getSearchError);
  const clientError = useAppSelector(getClientError);
  const agreementError = useAppSelector(getAgreementError);
  const caseError = useAppSelector(getCaseError);
  const caseNoteError = useAppSelector(getCaseNoteError);
  const invoiceError = useAppSelector(getInvoiceError);
  const categoryError = useAppSelector(getCategoryError);
  const serviceError = useAppSelector(getServiceError);
  const trackerError = useAppSelector(getTrackerError);
  const unitUserError = useAppSelector(getUnitUserError);
  const invitationError = useAppSelector(getInvitationError);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleSearchClick = () => {
    dispatch(setSearchError(null));
    navigate(ROUTES.SEARCH);
  }

  const handleClientClick = () => {
    dispatch(setAgreementError(null));
    dispatch(setClientError(null));
    navigate(ROUTES.CLIENTS);
  }

  const handleCaseClick = () => {
    dispatch(setCaseError(null));
    dispatch(setCaseNoteError(null));
    navigate(ROUTES.CASES);
  }

  const handleInvoiceClick = () => {
    dispatch(setInvoiceError(null));
    navigate(ROUTES.BILLS);
  }

  const handleServiceClick = () => {
    dispatch(setCategoryError(null));
    dispatch(setServiceError(null));
    navigate(ROUTES.SERVICES);
  }

  const handleTrackerClick = () => {
    dispatch(setTrackerError(null));
    navigate(ROUTES.TRACKER);
  }

  const handleUnitUserClick = () => {
    dispatch(setUnitUserError(null));
    navigate(ROUTES.USERS);
  }

  const handleMainClick = () => {
    dispatch(setAgreementError(null));
    dispatch(setClientError(null));
    dispatch(setCaseError(null));
    dispatch(setSearchError(null));
    dispatch(setCaseNoteError(null));
    dispatch(setInvoiceError(null));
    dispatch(setCategoryError(null));
    dispatch(setServiceError(null));
    dispatch(setTrackerError(null));
    dispatch(setUnitUserError(null));
    dispatch(setInvitationError(null));
    navigate(ROUTES.MAIN);
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ReportGmailerrorred color='warning' sx={sxNotFoundIcon} />
      </Box>
      <Typography variant='h5' component='h3' align='center'>
        Сервіс тимчасово недоступний.
      </Typography>
      {errorMessage && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {errorMessage}
        </Typography>
      )}
      {searchError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {searchError}
        </Typography>
      )}
      {clientError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {clientError}
        </Typography>
      )}
      {agreementError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {agreementError}
        </Typography>
      )}
      {caseError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {caseError}
        </Typography>
      )}
      {caseNoteError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {caseNoteError}
        </Typography>
      )}
      {invoiceError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {invoiceError}
        </Typography>
      )}
      {categoryError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {categoryError}
        </Typography>
      )}
      {serviceError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {serviceError}
        </Typography>
      )}
      {trackerError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {trackerError}
        </Typography>
      )}
      {unitUserError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {unitUserError}
        </Typography>
      )}
      {invitationError && (
        <Typography variant='h5' component='h3' align='center'>
          Виникла помилка: {invitationError}
        </Typography>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px', flexDirection: 'column' }}>
        {searchError && (
          <Button
            variant='contained'
            sx={isMobile ? sxNotFoundBtnMobile : sxNotFoundBtn}
            startIcon={<Groups />}
            onClick={handleSearchClick}
            buttonTitle='Повернутися на сторінку пошуку'
          />
        )}
        {(clientError || agreementError) && (
          <Button
            variant='contained'
            sx={isMobile ? sxNotFoundBtnMobile : sxNotFoundBtn}
            startIcon={<Groups />}
            onClick={handleClientClick}
            buttonTitle='Повернутися на сторінку клієнтів'
          />
        )}
        {(caseError || caseNoteError) && (
          <Button
            variant='contained'
            sx={isMobile ? sxNotFoundBtnMobile : sxNotFoundBtn}
            startIcon={<Work />}
            onClick={handleCaseClick}
            buttonTitle='Повернутися на сторінку справ'
          />
        )}
        {invoiceError && (
          <Button
            variant='contained'
            sx={isMobile ? sxNotFoundBtnMobile : sxNotFoundBtn}
            startIcon={<ReceiptLong />}
            onClick={handleInvoiceClick}
            buttonTitle='Повернутися на сторінку рахунків'
          />
        )}
        {(categoryError || serviceError) && (
          <Button
            variant='contained'
            sx={isMobile ? sxNotFoundBtnMobile : sxNotFoundBtn}
            startIcon={<Key />}
            onClick={handleServiceClick}
            buttonTitle='Повернутися на сторінку сервісів'
          />
        )}
        {trackerError && (
          <Button
            variant='contained'
            sx={isMobile ? sxNotFoundBtnMobile : sxNotFoundBtn}
            startIcon={<AccessTimeFilled />}
            onClick={handleTrackerClick}
            buttonTitle='Повернутися на сторінку трека часу'
          />
        )}
        {unitUserError && (
          <Button
            variant='contained'
            sx={isMobile ? sxNotFoundBtnMobile : sxNotFoundBtn}
            startIcon={<SupervisedUserCircle />}
            onClick={handleUnitUserClick}
            buttonTitle='Повернутися на сторінку користувачів'
          />
        )}
        <Button
          variant='contained'
          sx={isMobile ? sxNotFoundBtnMobile : sxNotFoundBtn}
          startIcon={<Home />}
          onClick={handleMainClick}
          buttonTitle='На головну'
        />
      </Box>
    </>
  );
};

export default ErrorPage;
