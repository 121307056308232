import { COLORS } from '@/assets/сolors/colors';

export const sxTimeTrackerWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  pb: '50px',
};

export const sxTimeTrackerWrapperMobile = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pb: '25px',
};


export const sxTimeTrackerBox = (width: string, background?: string) => {
  return {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    border: `1px solid ${background}`,
    width,
    margin: '0 auto',
    color: COLORS.MAIN,
  };
};

export const sxTimeTrackerBoxAppBar = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: COLORS.WHITE,
  width: '100%',
};

export const sxTimeTrackerBtnWrapper = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const sxTimeTrackerBtn = {
  background: 'transparent',
  color: COLORS.MAIN,
  span: {
    mr: '0px',
  },
  '&:hover': {
    color: COLORS.SUCCESS,
    background: 'transparent',
  },
};

export const sxTimeTrackerBtnAppBar = {
  background: 'transparent',
  color: COLORS.WHITE,
  span: {
    mr: '0px',
  },
  '&:hover': {
    color: COLORS.SUCCESS_SECONDARY,
    background: 'transparent',
  },
};

export const sxBtnImage = {
  width: '32px',
  height: 'auto',
};

export const sxTimeTrackerTimerWrapper = {
  display: 'flex',
  width: '100%',
};

export const sxCallReceivedButton = {
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '10px',
  padding: '0',
  color: COLORS.WHITE,
  background: 'transparent',
  '&:hover': {
    color: COLORS.SUCCESS_SECONDARY,
    background: 'transparent',
  },
};

export const sxCallClientReceivedButton = {
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '10px',
  padding: '0',
  color: COLORS.MAIN,
  '&:hover': {
    color: COLORS.SUCCESS,
    background: COLORS.WHITE,
  },
};
