import { CategoryFormValues, ServiceFormValues } from '../types';
import { ClientServicePackage } from '@/redux/types/client';
import { InvoiceServicePackage } from '@/redux/types/invoice';
import { Requisites } from '@/redux/types/agreement';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clientInitialValues: any = {
  name: '',
  phone: '',
  email: '',
  birthday: '',
  idCode: '',
  comment: '',
  personType: '',
};

export const authInitialValues = {
  email: '',
  password: '',
};

export const categoryInitialValues: CategoryFormValues = {
  name: '',
  image: '',
};

export const serviceInitialValues: ServiceFormValues = {
  name: '',
  description: '',
  duration: 0,
  price: 0,
};

export const unitInitialValues = {
  status: '',
  roleId: 'fcf2798a-ce0c-4514-91d6-a534b9227c7e',
  image: '',
  phone: '',
  email: '',
  name: '',
  specialization: '',
  category: '',
  services: [],
};

export const servicePackagesInitialValues: ClientServicePackage = {
  serviceIds: [],
  totalPrice: '',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const timeTrackerInitialValues: any = {
  userId: '',
};

export const invoicePackagesInitialValues: InvoiceServicePackage = {
  category: '',
  serviceIds: '',
  total: '',
};

export const requisitesInitialValues: Requisites = {
  organization: '',
  edrpou: '',
  iban: '',
  rnokpp: '',
  bank: '',
};
