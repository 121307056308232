import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import Spinner from '@/components/_common/Spinner';
import Title from '@/components/_common/Title';
import useUser from '@/hooks/useUser';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import Client from '@/components/screens/Clients/Client';
import { getClient, getSelectedClient } from '@/redux/slices/clientsSlice';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '@/redux/slices/categoriesSlice';

const ClientPage: React.FC = () => {
  const { getUser } = useUser();
  const client = useAppSelector(getSelectedClient);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const clientId = localStorage.getItem(LOCAL_STORAGE.SEARCH_ENTITY_ID);
  const dispatch = useAppDispatch();
  const { clientStatus, clientError } = useAppSelector(state => state.clients);
  const { categoryError } = useAppSelector(state => state.categories);
  const navigate = useNavigate();

  useEffect(() => {
    if (unitId && clientId)
    Promise.all([
      getUser(),
      dispatch(getClient({ unitId, clientId })),
      dispatch(getCategories(unitId)),
    ])
  }, [unitId, clientId]);

  const clientData = useMemo(() => {
    return client;
  }, [client]);

  if (clientError || categoryError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Клієнт' />
      {clientStatus === 'loading' && <Spinner />}
      {clientData && <Client client={clientData} />}
      {/* <Suspense fallback={<Spinner />}>
        {clientData && <Client client={clientData} />}
      </Suspense> */}
    </Container>
  );
};

export default ClientPage;
