import { COLORS } from '@/assets/сolors/colors';

export const sxSearchAndSelectorBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const sxSearch = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
  width: '100%',
  marginRight: '10px',
  boxShadow: '0 4px 12px 0 #e8e8e8',
};

export const sxSearchMobile = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
  width: '100%',
  marginRight: '5px',
  boxShadow: '0 4px 12px 0 #e8e8e8',
};

export const sxSearchBox = {
  display: 'flex',
  width: '100%',
};

export const sxButtonIcon = {
  marginLeft: '10px',
};

export const sxSearchButtonIcon = {
  marginLeft: '10px',
  minWidth: '80px',
  minHeight: '35px',
};

export const sxSearchButtonIconMobile = {
  marginLeft: '10px',
  minWidth: '30px',
  minHeight: '35px',
};

export const sxSearchTypeIcon = {
  color: COLORS.MAIN,
};

export const sxTableСontainer = {
  marginTop: '30px',
  marginBottom: '30px',
};

export const sxTypeIcon = {
  minWidth: '40px',
  minHeight: '40px',
};

export const sxResultTableCell = {
  paddingLeft: '5%',
};

export const sxSearchNotFound = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '50px',
};

export const sxTableСell = {
  padding: '0px',
  textAlign: 'center',
};
