import { COLORS } from '@/assets/сolors/colors';

export const sxIntervalBtn = {
  background: 'transparent',
  color: COLORS.MAIN,
  span: {
    mr: '0px',
  },
  '&:hover': {
    color: COLORS.SUCCESS,
    background: 'transparent',
  },
};

export const sxTrackerBox = {
  display: 'flex',
  alignItems: 'center',
};

export const sxWarningMargin = {
  marginLeft: 1,
};

export const sxAlignIntervalBtn = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
