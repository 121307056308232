import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FormikValues } from 'formik';
import React, { ChangeEvent, FC } from 'react';

import { sxLabel, sxRadioBtn, sxRadioWrapper } from './styles';
import { RadioFields } from './types';

interface BasicRadioGroupProps {
  title: string;
  name: string;
  onChange: (arg: ChangeEvent<FormikValues>) => void;
  fields: RadioFields[];
  values: FormikValues;
}

const BasicRadioGroup: FC<BasicRadioGroupProps> = ({
  title,
  onChange,
  fields,
  name,
  values,
}) => {
  return (
    <FormControl>
      <FormLabel sx={sxLabel}>{title}</FormLabel>
      <RadioGroup
        value={values[name]}
        sx={sxRadioWrapper}
        onChange={onChange}
        name={name}
      >
        {fields.map((item, index) => {
          return (
            <FormControlLabel
              {...item}
              key={index}
              sx={sxRadioBtn}
              disabled={item.disabled}
              control={<Radio size='small' color='success' />}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default BasicRadioGroup;
