import { TextField } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import React, { ChangeEvent, FC, FocusEventHandler } from 'react';

import { sxFormField, sxFormFieldError } from './styles';
import { SxProps } from '@mui/system';
import ErrorMessage from '../ErrorMessage';

interface BasicTextFieldProps {
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
  name: string;
  maxLength: number;
  label: string;
  placeholder: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: FocusEventHandler;
  type?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  sx?: SxProps;
}

const BasicTextField: FC<BasicTextFieldProps> = ({
  values,
  errors,
  touched,
  maxLength,
  handleChange,
  handleBlur,
  type = 'text',
  variant = 'outlined',
  sx = sxFormField,
  color = 'success',
  ...props
}) => {
  const isError =
    errors[props.name] && touched[props.name] ? sxFormFieldError : sx;

  return (
    <>
      <TextField
        sx={isError}
        color={color}
        value={values[props.name]}
        fullWidth
        type={props.name}
        inputProps={{ maxLength, type }}
        variant={variant}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      <ErrorMessage errors={errors} touched={touched} name={props.name} />
    </>
  );
};

export default BasicTextField;
