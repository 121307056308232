import {
  CallReceived,
  PauseCircle,
  PlayCircle,
  ReplayCircleFilled,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { TimeTrackerContext } from '../TimeTrackerProvider/TimeTrackerProvider';
import {
  sxBtnImage,
  sxCallReceivedButton,
  sxTimeTrackerBox,
  sxTimeTrackerBtn,
  sxTimeTrackerBtnWrapper,
  sxTimeTrackerTimerWrapper,
} from '../styles';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import Button from '../../../_common/Button';
import { getAppPagination } from '@/redux/slices/PaginationSlice';
import { editTracker, getActiveTrackers, getTracker, getTrackers, postTracker } from '@/redux/slices/trackerSlice';

interface TimeTrackerProps {
  classNameBtn?: object;
  className?: object;
  activeTrackerButton?: object;
}

const TimeTrackerButtons: React.FC<TimeTrackerProps> = ({
  classNameBtn = sxTimeTrackerBtn,
  className = sxTimeTrackerBox('25%'),
  activeTrackerButton = sxCallReceivedButton,
}) => {
  const { time, setTime, running, setRunning } = useContext(TimeTrackerContext);
  const hours = ('0' + Math.floor((time / 3600000) % 60)).slice(-2);
  const minutes = ('0' + Math.floor((time / 60000) % 60)).slice(-2);
  const seconds = ('0' + Math.floor((time / 1000) % 60)).slice(-2);
  const navigate = useNavigate();
  const activeTrackers = useAppSelector(getActiveTrackers);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const { page, limit } = useAppSelector(getAppPagination);
  const dispatch = useAppDispatch();
  const { trackerError } = useAppSelector(state => state.tracker);

  const handleStart = async () => {
    if (activeTrackers && activeTrackers.length > 0) {
      if (unitId) {
        await dispatch(editTracker({ unitId, body: {id: activeTrackers[0].id, status: 'tracking' }}));
        await dispatch(getTrackers({ page, limit }));
        await dispatch(getTracker(activeTrackers[0].id));
      }
    }
    if (activeTrackers && activeTrackers.length === 0) {
      if (unitId) {
        dispatch(postTracker({ unitId, body: { userId: 'YPYo1nOLWmhFy3tDBK2WEf6Y3NW2' }}));
      }
    }
    if (!activeTrackers) {
      return;
    }

    setRunning(true);
    localStorage.setItem( LOCAL_STORAGE.RUNNING, 'true');
  };

  const handlePause = async() => {
    if (unitId) {
      if (activeTrackers && activeTrackers.length > 0 && activeTrackers[0].id) {
        await dispatch(editTracker({ unitId, body: {id: activeTrackers[0].id, status: 'paused' }}))
        await dispatch(getTrackers({ page, limit }))
        await dispatch(getTracker(activeTrackers[0].id));
      }
    }
    setRunning(false);
    localStorage.setItem(LOCAL_STORAGE.RUNNING, 'false');
    localStorage.setItem(LOCAL_STORAGE.SAVED_TRACKER_TIME, JSON.stringify(time));
  };

  const handleReset = () => {
    setTime(0);
    localStorage.removeItem(LOCAL_STORAGE.SAVED_TRACKER_TIME);
    localStorage.removeItem(LOCAL_STORAGE.RUNNING);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (running) {
        localStorage.setItem(LOCAL_STORAGE.SAVED_TRACKER_TIME, JSON.stringify(time));
        localStorage.setItem(LOCAL_STORAGE.RUNNING, 'true');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [time, running]);

  useEffect(() => {
    const savedTime = localStorage.getItem(LOCAL_STORAGE.SAVED_TRACKER_TIME);
    const savedRunning = localStorage.getItem(LOCAL_STORAGE.RUNNING);

    if (savedTime) {
      setTime(JSON.parse(savedTime));
    }

    if (savedRunning === 'true') {
      setRunning(true);
    }
  }, [setTime, setRunning]);

  const handleTrackerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    
    if (activeTrackers) {
      if (
        !target.closest('.start') &&
        !target.closest('.pause') &&
        !target.closest('.reset')
      ) {
        navigate(`${ROUTES.TRACKER}/${activeTrackers[0].id}`);
      }
    } else { /* empty */ }
  };

  if (trackerError) {
    navigate(ROUTES.ERROR);
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Box sx={className} onClick={activeTrackers && activeTrackers.length ? handleTrackerClick : () => {}}>
      <Box sx={sxTimeTrackerBtnWrapper}>
        <Button
          className='reset'
          sx={classNameBtn}
          onClick={handleReset}
          startIcon={<ReplayCircleFilled sx={sxBtnImage} />}
        />
        <Button
          className='start'
          sx={classNameBtn}
          onClick={handleStart}
          startIcon={<PlayCircle sx={sxBtnImage} />}
        />
        <Button
          className='pause'
          sx={classNameBtn}
          onClick={handlePause}
          startIcon={<PauseCircle sx={sxBtnImage} />}
        />
      </Box>
      <Box sx={sxTimeTrackerTimerWrapper}>
        <Button
          sx={activeTrackerButton}
          startIcon={<CallReceived sx={activeTrackers && activeTrackers.length > 0 ? {} : { visibility: 'hidden' }} />}
        />
        <Box display='flex'>
          <Typography>{hours}:</Typography>
          <Typography>{minutes}:</Typography>
          <Typography>{seconds}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeTrackerButtons;
