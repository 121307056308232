import { GroupBase, StylesConfig } from 'react-select';
import { COLORS } from '@/assets/сolors/colors';
import { CategoryData } from '@/redux/types/category';
import { ServiceData } from '@/redux/types/service';

const color = {
  primary: COLORS.MAIN,
  secondary: COLORS.SUCCESS_SECONDARY,
  light: COLORS.WHITE,
  dark: COLORS.DARK,
  hover: COLORS.SUCCESS,
};

const selectStyles: StylesConfig<
  CategoryData[] | ServiceData[],
  boolean,
  GroupBase<CategoryData[] | ServiceData[]>
> = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '340px',
    height: '100%',
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'transparent',
    minHeight: '56px',
    marginBottom: '12px',
    boxShadow: 'none',
    borderColor: isFocused ? color.hover : color.secondary,
    ':hover': {
      borderColor: color.hover,
    },
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? color.secondary : undefined,
      color: color.dark,
      cursor: 'pointer',
      padding: '16px',
      ':hover': {
        ...styles[':hover'],
        color: color.light,
        backgroundColor: isFocused ? color.primary : undefined,
      },
    };
  },
  input: (styles) => ({
    ...styles,
    color: color.light,
    cursor: 'pointer',
  }),
  placeholder: (styles) => ({ ...styles, color: color.primary }),
  singleValue: (styles) => ({
    ...styles,
    color: color.dark,
  }),
  multiValue: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
  }),

  multiValueRemove: (styles) => ({
    ...styles,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: color.primary,
  }),
};

export default selectStyles;
