import { FormikValues } from 'formik';
import React from 'react';
import Select, { GroupBase, PropsValue, StylesConfig } from 'react-select';
import { CategoryData } from '@/redux/types/category';
import { ServiceData } from '@/redux/types/service';

export interface SelectComponentProps {
  options: ServiceData[] | CategoryData[] | OptionsData[];
  isMulti?: boolean;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: PropsValue<any>;
  onChange?: (value: OptionsData) => void;
  onBlur?: (e: FormikValues | FocusEvent) => void;
  styles?: StylesConfig<
    CategoryData[] | ServiceData[],
    boolean,
    GroupBase<CategoryData[] | ServiceData[]>
  >;
}

export interface OptionsData {
  value: string;
  label: string;
  services?: ServiceData[];
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  styles,
  onBlur,
  value,
  isMulti = false,
  options,
  ...props
}) => {
  return (
    <Select
      components={{ IndicatorSeparator: () => null }}
      styles={styles}
      options={options}
      isMulti={isMulti}
      onBlur={onBlur}
      value={value}
      getOptionLabel={(option) =>
        option.rating
          ? `${option.label}: [${option.rating}]`
          : `${option.label}`
      }
      {...props}
    />
  );
};

export default SelectComponent;
