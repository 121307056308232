import { ServiceFields } from '../types';

export const signUp = [
  {
    label: 'Пошта користувача',
    placeholder: 'Пошта користувача',
    name: 'email',
    type: 'email',
    maxLength: 30,
  },
  {
    label: 'Пароль',
    placeholder: 'Пароль',
    name: 'password',
    type: 'password',
    maxLength: 20,
  },
];

export const loginData = [
  {
    label: 'Пошта користувача',
    placeholder: 'Пошта користувача',
    name: 'email',
    type: 'email',
    maxLength: 30,
  },
  {
    label: 'Пароль',
    placeholder: 'Пароль',
    name: 'password',
    type: 'password',
    maxLength: 20,
  },
];
export const caseFields = [
  {
    label: 'Справа',
    placeholder: 'Справа',
    name: 'case',
    maxLength: 30,
  },
  {
    label: 'Назва суду',
    placeholder: 'ДНІПРОПЕТРОВСЬКИЙ ОКРУЖНИЙ АДМІНІСТРАТИВНИЙ СУД',
    name: 'court',
    maxLength: 50,
  },
  {
    label: 'Суддя',
    placeholder: 'СТЕПАНЕНКО СТЕПАН СТЕПАНОВИЧ',
    name: 'judge',
    maxLength: 50,
  },
  {
    label: 'Опис',
    placeholder: 'Опис справи',
    name: 'description',
    maxLength: 50,
  },
  {
    label: 'Дата суду',
    placeholder: '01.01.2023',
    name: 'date',
    maxLength: 20,
  },
  {
    label: 'Статус',
    placeholder: 'Розгляд',
    name: 'status',
    maxLength: 20,
  },
];

export const categoryFields: ServiceFields[] = [
  {
    label: 'Категорія',
    placeholder: 'Категорія',
    name: 'name',
    maxLength: 50,
  },
];

export const serviceFields: ServiceFields[] = [
  {
    label: 'Послуга',
    placeholder: 'Послуга',
    name: 'name',
    maxLength: 70,
  },
  {
    label: 'Опис послуги',
    placeholder: 'Опис послуги',
    name: 'description',
    maxLength: 100,
  },
  {
    label: 'Тривалість',
    placeholder: 'Тривалість',
    name: 'duration',
    maxLength: 10,
  },
  {
    label: 'Ціна',
    placeholder: 'Ціна',
    name: 'price',
    maxLength: 10,
  },
];

export const unitUserFields: ServiceFields[] = [
  {
    label: "Ім'я",
    placeholder: "Ім'я",
    name: 'name',
    maxLength: 50,
  },
  {
    label: 'Телефон',
    placeholder: 'Телефон',
    name: 'phone',
    maxLength: 13,
  },
  {
    label: 'Пошта',
    placeholder: 'Пошта',
    name: 'email',
    maxLength: 50,
  },
  {
    label: 'Категорія',
    placeholder: 'Категорія',
    name: 'category',
    maxLength: 50,
  },
  {
    label: 'Посада',
    placeholder: 'Посада',
    name: 'specialization',
    maxLength: 50,
  },
];

export const roleFields = [
  {
    label: 'Адміністратор',
    value: 'fcf2798a-ce0c-4514-91d6-a534b9227c7e',
  },
  {
    label: 'Головний адміністратор',
    value: '17eddd5c-1ee5-4179-ad90-4364f29c50e7',
    disabled: true,
  },
  {
    label: 'Гість',
    value: '5ce753b7-4ca3-451d-8dba-6a30da5bb7bf',
    disabled: true,
  },
];

export const requisitesFields = [
  {
    label: 'Організація',
    placeholder: 'Введіть організацію',
    name: 'organization',
    maxLength: 50,
  },
  {
    label: 'ЄДРПОУ',
    placeholder: 'Введіть код ЄДРПОУ',
    name: 'edrpou',
    maxLength: 8,
  },
  {
    label: 'IBAN',
    placeholder: 'Введіть IBAN',
    name: 'iban',
    maxLength: 50,
  },
  {
    label: 'РНОКПП',
    placeholder: 'Введіть РНОКПП',
    name: 'rnokpp',
    maxLength: 50,
  },
  {
    label: 'Банк',
    placeholder: 'Ввдіть назву банка',
    name: 'bank',
    maxLength: 50,
  },
];
