export const sxStack = {
  maxWidth: '420px',
  width: '100%',
  alignItems: 'center',
};

export const sxStackMobile = {
  maxWidth: '250px',
  width: '100%',
  alignItems: 'center',
};

export const sxAvatar = {
  maxWidth: 56,
  maxHeight: 56,
};