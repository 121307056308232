import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';

import Title from '@/components/_common/Title';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import Spinner from '@/components/_common/Spinner';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import ActiveTimeTracker from '@/components/screens/TimeTracker/ActiveTimeTracker';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '@/redux/slices/categoriesSlice';
import { getServices } from '@/redux/slices/servicesSlice';
import { getUnitUsers } from '@/redux/slices/unitUsersSlice';
import { getActiveTrackers } from '@/redux/slices/trackerSlice';

const ActiveTrackerPage: React.FC = () => {
  const activeTrackers = useAppSelector(getActiveTrackers)
  const navigate = useNavigate();
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const dispatch = useAppDispatch();
  const { serviceError, serviceStatus } = useAppSelector(state => state.services);
  const { categoryError } = useAppSelector(state => state.categories);
  const { unitUserError } = useAppSelector(state => state.unitUsers);

  useEffect(() => {
    if (unitId) {
      Promise.all([
        dispatch(getUnitUsers(unitId)),
        dispatch(getCategories(unitId)),
        dispatch(getServices(unitId)),
      ])
    }
  }, [unitId]);

  const trackersData = useMemo(() => {
    return activeTrackers;
  }, [activeTrackers]);

  useEffect(() => {
    if (!trackersData || trackersData.length === 0) {
      navigate(ROUTES.TRACKER);
    }
  }, [trackersData]);

  if (serviceError || categoryError || unitUserError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Активний трекер' />
      {serviceStatus === 'loading' && <Spinner />}
      {trackersData && <ActiveTimeTracker accordionData={trackersData} />}
    </Container>
  );
};

export default ActiveTrackerPage;
