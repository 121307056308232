import React from 'react';
import Modal from '@mui/material/Modal';
import { Box, IconButton, Typography } from '@mui/material';
import { sxCloseIcon, sxModalWrapper, sxModalWrapperMobile } from './styles';
import { Close } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';

interface ModalComponentProps {
  isOpen: boolean;
  setClose: (arg: boolean) => void;
  backStep?: (arg: boolean) => void;
  children: JSX.Element;
  title: string;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  isOpen,
  setClose,
  children,
  title,
  backStep,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleClose = () => {
    if (backStep) {
      backStep(false);
    } else {
      setClose(false);
    }
  };

  return (
    <Modal onClose={setClose} open={isOpen}>
      <Box sx={isMobile ? sxModalWrapperMobile : sxModalWrapper}>
        <Box>
          <Typography textAlign='center' mb='16px' id='#' variant='h5'>
            {title}
          </Typography>
          <IconButton sx={sxCloseIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default ModalComponent;
