import { createTheme } from '@mui/material';

import { COLORS } from '../сolors/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.MAIN,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
    error: {
      main: COLORS.DELETE,
    },
    success: {
      main: COLORS.SUCCESS_SECONDARY,
    },
    info: {
      main: COLORS.WHITE,
    },
  },
});
