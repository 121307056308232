import React from 'react';
import { Box } from '@mui/material';
import { sxInvitationCard } from './styles';
import { Invitation } from '@/redux/types/invitation';
import InvitationsInfo from './InvitationsInfo';
import InvitationsButtons from './InvitationsButtons/InvationsButtons';

interface InvitationsProps {
  invitation: Invitation;
  id: string;
}

const Invitations: React.FC<InvitationsProps> = ({ invitation, id }) => {
  return (
    <Box sx={sxInvitationCard}>
      <InvitationsInfo {...invitation} />
      <InvitationsButtons id={id} />
    </Box>
  );
};

export default Invitations;
