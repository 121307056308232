import { COLORS } from '@/assets/сolors/colors';

export const sxAgreeBtn = {
  width: '100%',
};

export const sxDisagreeBtn = {
  width: '100%',
  '&:hover': {
    background: COLORS.DELETE,
  },
};
