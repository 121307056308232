import { Requisites } from './agreement';
import { ServiceData } from './service';

export interface ClientRequest {
  clients: ClientsData | null;
  selectedClient: SelectedClient | null;
  clientStatus: string | null;
  clientError: string | null;
}

export interface ClientsData {
  data: SelectedClient[];
  pagination: ClientsPagination;
}

export interface SelectedClient {
  id: string;
  name: string;
  phone: string;
  birthday: string;
  email: string;
  idCode: string;
  comment: string;
  importance: string;
  createdAt: string;
  congratulateBirthday: boolean;
  discount: number;
  visits: string;
  status: string;
  personType: PersonType;
  agreementNumber: string;
  requisites: Requisites;
  servicePackages: ServicePackages[];
  courtCases: ClientCourtCase[];
}

export interface ClientsPagination {
  limit: number;
  page: number;
  pagesTotal: number;
  totalCount: number;
}

export interface ClientCourtCase {
  caseNumber: string;
  courtName: string;
  description: string;
  judgmentCode: number;
  judgmentCodeValue: string;
  lastStatus: string;
}

export enum PersonType {
  Juridical = 'juridical',
  Private = 'private',
}

export interface ServicePackages {
  id: string;
  totalPrice: number;
  services: ServiceData[];
}

export interface ClientServicePackage {
  serviceIds: string[];
  totalPrice: number | string;
}
