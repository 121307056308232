import React from 'react';
import { Typography } from '@mui/material';
import { InvitationUnitData } from '@/redux/types/invitation';

const InvitationUnit: React.FC<InvitationUnitData> = ({ name }) => {
  return (
    <Typography marginRight='8px' variant='button'>
      Шановний {name}, Вас запрошено в компанію на посаду:
    </Typography>
  );
};

export default InvitationUnit;
