import { Home, ReportGmailerrorred } from '@mui/icons-material';
import { Container } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  sxNotFoundBtn,
  sxNotFoundIcon,
  sxNotFoundWrapper,
} from '@/assets/_shared/styles';
import Button from '@/components/_common/Button';
import Title from '@/components/_common/Title';
import { ROUTES } from '@/constants';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container sx={sxNotFoundWrapper}>
      <Title title='Сторінку не зайдено' />
      <ReportGmailerrorred color='warning' sx={sxNotFoundIcon} />
      <Button
        variant='contained'
        sx={sxNotFoundBtn}
        startIcon={<Home />}
        onClick={() => navigate(ROUTES.MAIN)}
        buttonTitle='На головну'
      />
    </Container>
  );
};

export default NotFoundPage;
