import { Box, Link, List, ListItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Case, CasePerson } from '@/redux/types/case';
import LinkIcon from '@mui/icons-material/Link';
import { sxCaseTextBox, sxCaseTextBoxMobile, sxDecisionsButton, sxLink, sxLinkBox, sxLinkIcon, sxListItem } from './styles';
import Button from '../../../_common/Button';
import { useMediaQuery } from 'react-responsive';

interface CaseTextBlockProps {
  selectedCase: Case;
}

const CaseTextBlock: React.FC<CaseTextBlockProps> = ({selectedCase}) => {
  const [isFirstDecisionsOpen, setIsFirstDecisionsOpen] = useState(false);
  const [isAppealDecisionsOpen, setIsAppealDecisionsOpen] = useState(false);
  const [isCassationDecisionsOpen, setIsCassationDecisionsOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  return (
    <Box sx={isMobile ? sxCaseTextBoxMobile : sxCaseTextBox}>
      <Typography mb='10px'>
        Номер справи: {selectedCase.opendata.number}
      </Typography>
      <Box>
        Опис: {selectedCase.opendata.description}
      </Box>
      <Typography mb='10px'>
        Суд: {selectedCase.opendata.court_name}
      </Typography>
      <Typography mb='10px'>
        Останній статус: {selectedCase.opendata.last_status}
      </Typography>
      <Box>
        <List>
          Позивачі: {selectedCase.opendata.plaintiffs && selectedCase.opendata.plaintiffs.map((plaintiff: CasePerson, index: number) => (
          <ListItem key={index} sx={sxListItem}>
            {`${index + 1}. ${plaintiff.name}`}
          </ListItem>
        ))}
        </List>
      </Box>
      <Box>
        <List>
          Відповідачі: {selectedCase.opendata.defendants && selectedCase.opendata.defendants.map((defendant: CasePerson, index: number) => (
            <ListItem key={index} sx={sxListItem}>
              {`${index + 1}. ${defendant.name}`}
            </ListItem>
          ))}
        </List>
      </Box>
      {selectedCase.opendata.stages.first && (
        <>
          <Typography mb='10px'>
            Суд першої інстанції
          </Typography>
          <Typography>
            Суд: {selectedCase.opendata.stages.first.court_name}
          </Typography>
          <Typography mb='10px'>
            Суддя: {selectedCase.opendata.stages.first.judge}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>
            Ухвали:
          </Typography>
          <Button
            onClick={() => setIsFirstDecisionsOpen(!isFirstDecisionsOpen)}
            sx={sxDecisionsButton}
            buttonTitle={isFirstDecisionsOpen ? 'Приховати список ухвал' : 'Показати список ухвал'}
          />
          </Box>
          {isFirstDecisionsOpen && (
            <List>
              {selectedCase.opendata.stages.first.decisions.map((decision, index) => (
                <ListItem key={index} sx={sxListItem}>
                  <Box>
                    <Typography mb='10px'>
                    {index + 1}.&nbsp;{decision.justice_name}
                    </Typography>
                    <Typography>
                      Суд: {decision.court_name}
                    </Typography>
                    <Typography>
                      Суддя: {decision.judge}
                    </Typography>
                    <Typography>
                      Винесено: {decision.adjudication_date}
                    </Typography>
                    <Typography>
                      Отримано: {decision.receipt_date}
                    </Typography>
                    <Box>
                      <Link href={decision.link} target='_blank' sx={sxLink}>
                        <Box sx={sxLinkBox}>
                        <LinkIcon sx={sxLinkIcon} /> {decision.link}
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
      {selectedCase.opendata.stages.appeal && (
        <>
          <Typography mb='10px'>
            Апеліяційний суд
          </Typography>
          <Typography>
            Суд: {selectedCase.opendata.stages.appeal.court_name}
          </Typography>
          <Typography mb='10px'>
            Суддя: {selectedCase.opendata.stages.appeal.judge}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>
            Ухвали:
          </Typography>
          <Button
            onClick={() => setIsAppealDecisionsOpen(!isAppealDecisionsOpen)}
            sx={sxDecisionsButton}
            buttonTitle={isAppealDecisionsOpen ? 'Приховати список ухвал' : 'Показати список ухвал'}
          />
          </Box>
          {isAppealDecisionsOpen && (
            <List>
              {selectedCase.opendata.stages.appeal.decisions.map((decision, index) => (
                <ListItem key={index} sx={sxListItem}>
                  <Box>
                    <Typography mb='10px'>
                    {index + 1}.&nbsp;{decision.justice_name}
                    </Typography>
                    <Typography>
                      Суд: {decision.court_name}
                    </Typography>
                    <Typography>
                      Суддя: {decision.judge}
                    </Typography>
                    <Typography>
                      Винесено: {decision.adjudication_date}
                    </Typography>
                    <Typography>
                      Отримано: {decision.receipt_date}
                    </Typography>
                    <Box>
                      <Link href={decision.link} target='_blank' sx={sxLink}>
                        <Box sx={sxLinkBox}>
                        <LinkIcon sx={sxLinkIcon} /> {decision.link}
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
      {selectedCase.opendata.stages.cassation && (
        <>
          <Typography mb='10px'>
            Касаційний суд
          </Typography>
          <Typography>
            Суд: {selectedCase.opendata.stages.cassation.court_name}
          </Typography>
          <Typography mb='10px'>
            Суддя: {selectedCase.opendata.stages.cassation.judge}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>
            Ухвали:
          </Typography>
          <Button
            onClick={() => setIsCassationDecisionsOpen(!isCassationDecisionsOpen)}
            sx={sxDecisionsButton}
            buttonTitle={isCassationDecisionsOpen ? 'Приховати список ухвал' : 'Показати список ухвал'}
          />
          </Box>
          {isCassationDecisionsOpen && (
            <List>
              {selectedCase.opendata.stages.cassation.decisions.map((decision, index) => (
                <ListItem key={index} sx={sxListItem}>
                  <Box>
                    <Typography mb='10px'>
                    {index + 1}.&nbsp;{decision.justice_name}
                    </Typography>
                    <Typography>
                      Суд: {decision.court_name}
                    </Typography>
                    <Typography>
                      Суддя: {decision.judge}
                    </Typography>
                    <Typography>
                      Винесено: {decision.adjudication_date}
                    </Typography>
                    <Typography>
                      Отримано: {decision.receipt_date}
                    </Typography>
                    <Box>
                      <Link href={decision.link} target='_blank' sx={sxLink}>
                        <Box sx={sxLinkBox}>
                        <LinkIcon sx={sxLinkIcon} /> {decision.link}
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </Box>
  )
};

export default CaseTextBlock;
