import React from 'react';
import { Box } from '@mui/material';
import { sxPrintButton, sxPrintTopButtons, sxPrintTopButtonsMobile, sxWarningIcon, sxWarningIconBox, sxWarningIconHiden } from './styles';
import { Add, Edit, LibraryBooks, LocalPrintshop, WarningAmber, WorkOutline } from '@mui/icons-material';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { SelectedClient } from '@/redux/types/client';
import Button from '../Button';
import { getClient } from '@/redux/slices/clientsSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import Spinner from '../Spinner';
import { postAgreement, printAgreement } from '@/redux/slices/agreementSlice';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../Tooltip/Tooltip';
import { Desktop, Mobile } from '@/utils/mediaQuery';

type Props = {
  client: SelectedClient,
  modalOpen: () => void,
  setEdit: () => void,
  setRequisitesModalOpen: () => void,
  servicePackegesHandler: () => void,
};

const Print: React.FC<Props> = ({ client, modalOpen, setEdit, servicePackegesHandler, setRequisitesModalOpen }) => {
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const dispatch = useAppDispatch();
  const { clientStatus, clientError } = useAppSelector(state => state.clients);
  const { agreementError } = useAppSelector(state => state.agreement);
  const navigate = useNavigate();

  const isJuridicalClientWithoutServicePackages = client.personType === 'juridical' && client.servicePackages.length === 0;  
  const isCreateAgreementButtonDisabled = Boolean(client.agreementNumber) || isJuridicalClientWithoutServicePackages || !client.requisites;

  const handleCreateAgreement = async () => {
    if (unitId) {
      await dispatch(postAgreement({ clientId: client.id, unitId }));
      await dispatch(getClient({ unitId, clientId: client.id }));
    }
  };

  const handlePrintAgreement = async () => {
      dispatch(printAgreement(client.id));
  };

  if (clientError || agreementError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <>
      <Desktop>
        <Box sx={sxPrintTopButtons}>
          {clientStatus === 'loading' && <Spinner />}
          <Button
            sx={sxPrintButton}
            variant='contained'
            fullWidth
            startIcon={<LocalPrintshop />}
            disabled={!client.agreementNumber}
            onClick={handlePrintAgreement}
            buttonTitle='Договір (на друк)'
          />
          <Button
            sx={sxPrintButton}
            variant='contained'
            fullWidth
            startIcon={<LibraryBooks />}
            disabled={isCreateAgreementButtonDisabled}
            onClick={handleCreateAgreement}
            buttonTitle='Створити договір'
          />
          {client.requisites ? (
            <Button
              sx={sxPrintButton}
              variant='contained'
              fullWidth
              startIcon={<LibraryBooks />}
              onClick={setRequisitesModalOpen}
              buttonTitle='Редагувати реквізити'
            />
          ) : (
            <Button
              sx={sxPrintButton}
              variant='contained'
              fullWidth
              startIcon={<LibraryBooks />}
              onClick={setRequisitesModalOpen}
              buttonTitle='Створити реквізити'
            />
          )}
          <Button
            variant='contained'
            fullWidth
            startIcon={<Add />}
            onClick={modalOpen}
            sx={sxPrintButton}
            buttonTitle='Додати справу'
          />
          <Button
            variant='contained'
            fullWidth
            title='Пакет послуг'
            sx={sxPrintButton}
            startIcon={<WorkOutline />}
            onClick={servicePackegesHandler}
            disabled={client.personType !== 'juridical'}
            buttonTitle='Пакет послуг'
          />
          <Button
            variant='contained'
            fullWidth
            startIcon={<Edit />}
            onClick={setEdit}
            sx={sxPrintButton}
            buttonTitle='Редагувати дані'
          />
          <Box sx={sxWarningIconBox}>
            <Tooltip title='Для створення договору, стоворіть спочатку реквізити'>
              <WarningAmber sx={client.requisites ? sxWarningIconHiden : sxWarningIcon} />
            </Tooltip>
          </Box>
        </Box>
      </Desktop>

      <Mobile>
        <Box sx={sxPrintTopButtonsMobile}>
          {clientStatus === 'loading' && <Spinner />}
          <Box sx={sxWarningIconBox}>
            <Tooltip title='Для створення договору, стоворіть спочатку реквізити'>
              <WarningAmber sx={client.requisites ? sxWarningIconHiden : sxWarningIcon} />
            </Tooltip>
          </Box>
          <Button
            sx={{ ...sxPrintButton, mt: '12px' }}
            variant='contained'
            fullWidth
            startIcon={<LocalPrintshop />}
            disabled={!client.agreementNumber}
            onClick={handlePrintAgreement}
            buttonTitle='Договір (на друк)'
          />
          <Button
            sx={sxPrintButton}
            variant='contained'
            fullWidth
            startIcon={<LibraryBooks />}
            disabled={isCreateAgreementButtonDisabled}
            onClick={handleCreateAgreement}
            buttonTitle='Створити договір'
          />
          {client.requisites ? (
            <Button
              sx={sxPrintButton}
              variant='contained'
              fullWidth
              startIcon={<LibraryBooks />}
              onClick={setRequisitesModalOpen}
              buttonTitle='Редагувати реквізити'
            />
          ) : (
            <Button
              sx={sxPrintButton}
              variant='contained'
              fullWidth
              startIcon={<LibraryBooks />}
              onClick={setRequisitesModalOpen}
              buttonTitle='Створити реквізити'
            />
          )}
          <Button
            variant='contained'
            fullWidth
            startIcon={<Add />}
            onClick={modalOpen}
            sx={sxPrintButton}
            buttonTitle='Додати справу'
          />
          <Button
            variant='contained'
            fullWidth
            title='Пакет послуг'
            sx={sxPrintButton}
            startIcon={<WorkOutline />}
            onClick={servicePackegesHandler}
            disabled={client.personType !== 'juridical'}
            buttonTitle='Пакет послуг'
          />
          <Button
            variant='contained'
            fullWidth
            startIcon={<Edit />}
            onClick={setEdit}
            sx={sxPrintButton}
            buttonTitle='Редагувати дані'
          />
        </Box>
      </Mobile>
    </>
  );
};

export default Print;
