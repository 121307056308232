import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedRoutes from './ProtectedRoutes';
import Spinner from '@/components/_common/Spinner';
import Layout from '@/components/layouts/Layout';
import { ROUTES } from '@/constants';
import BillsPage from '@/pages/BillsPage';
import CasesPage from '@/pages/CasesPage';
import ClientsPage from '@/pages/ClientsPage';
import MainPage from '@/pages/MainPage';
import NotFoundPage from '@/pages/NotFoundPage';
import RegistrationPage from '@/pages/RegistrationPage';
import SearchPage from '@/pages/SearchPage';
import ServicesPage from '@/pages/ServicesPage';
import TrackerPage from '@/pages/TrackerPage';
import UsersPage from '@/pages/UsersPage';
import InvitationsPage from '@/pages/InvitationsPage';
import ActiveTrackerPage from '@/pages/ActiveTrackerPage';
import SelectOrganizationPage from '@/pages/SelectOrganizationPage';
import ClientPage from '@/pages/ClientPage';
import UserPage from '@/pages/UserPage';
import CasePage from '@/pages/CasePage';
import ErrorPage from '@/pages/ErrorPage';

const AppRouter = () => {
  const Login = lazy(() => import('@/pages/LoginPage'));

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route element={<Layout />}>
          <Route
            index
            element={
              <ProtectedRoutes>
                <MainPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.CLIENTS}
            element={
              <ProtectedRoutes>
                <ClientsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.CLIENT}
            element={
              <ProtectedRoutes>
                <ClientPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.CASES}
            element={
              <ProtectedRoutes>
                <CasesPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.CASE}
            element={
              <ProtectedRoutes>
                <CasePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.TRACKER}
            element={
              <ProtectedRoutes>
                <TrackerPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.ACTIVE_TRACKER}
            element={
              <ProtectedRoutes>
                <ActiveTrackerPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.BILLS}
            element={
              <ProtectedRoutes>
                <BillsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.SEARCH}
            element={
              <ProtectedRoutes>
                <SearchPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.USERS}
            element={
              <ProtectedRoutes>
                <UsersPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.USER}
            element={
              <ProtectedRoutes>
                <UserPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.SERVICES}
            element={
              <ProtectedRoutes>
                <ServicesPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.INVITATIONS}
            element={
              <ProtectedRoutes>
                <InvitationsPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path={ROUTES.REGISTRATION} element={<RegistrationPage />} />
        <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.SELECT_ORGANIZATION} element={<SelectOrganizationPage />} />
        <Route path={ROUTES.ERROR} element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
