import { useEffect, useState } from 'react';
// import { LOCAL_STORAGE } from '@constants';
// import { BroadcastChannel } from 'broadcast-channel';

// const dataChannel = new BroadcastChannel('time_tracker_data');

const useTime = () => {
  const [running, setRunning] = useState<boolean>(false);
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (running) {
      interval = setInterval(() => {
        setTime((prevTime: number) => prevTime + 1000);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [running]);

  // useEffect(() => {
  //   if (time) {
  //     localStorage.setItem(LOCAL_STORAGE.SAVED_TRACKER_TIME, JSON.stringify(time));
  //   }
  //   if (running) {
  //     localStorage.setItem(LOCAL_STORAGE.RUNNING, String(running));
  //   }
  //   }, [time, running]);

  // useEffect(() => {
  //   dataChannel.postMessage({
  //     time: time,
  //     running: running,
  //   });
  // }, [time, running]);

  // useEffect(() => {
  //   const handleDataChannelMessage = (event: any) => {
  //     if (event && event.time) {
  //       console.log('event.time:', event.time)
  //       setTime(event.time);
  //     }
  //     if (event && event.running !== undefined) {
  //       console.log('event.running:', event.running)
  //       setRunning(event.running);
  //     }
  //   };

  //   dataChannel.addEventListener('message', handleDataChannelMessage);
  //   dataChannel.postMessage({});

  //   return () => {
  //     dataChannel.removeEventListener('message', handleDataChannelMessage);
  //   };
  // }, []);

  return {
    running,
    setRunning,
    time,
    setTime,
  };
};

export default useTime;
