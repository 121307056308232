export const sxClientsBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  pb: '50px',
};

export const sxClientsBoxMobile = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pb: '25px',
};