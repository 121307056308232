import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import AcceptBlock from '@/components/_common/AcceptBlock';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { Box } from '@mui/material';
import { Interval, TrackerInterval } from '@/redux/types/tracker';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { sxTimeDatePicker } from '@/components/screens/TimeTracker/TrackerIntervals/styles';
import { trackerIntervalSchema, trackerIntervalSchemaMobile } from './constants/schema';
import { getAppPagination } from '@/redux/slices/PaginationSlice';
import { editTrackerInterval, getSelectedTracker, getTracker, getTrackers } from '@/redux/slices/trackerSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { useMediaQuery } from 'react-responsive';

interface TrackerIntervalFormProps {
  setIsOpen: (arg: boolean) => void;
  interval: Interval;
}

const TrackerIntervalEditForm: React.FC<TrackerIntervalFormProps> = ({
  setIsOpen,
  interval,
}) => {
  const { page, limit } = useAppSelector(getAppPagination);
  const tracker = useAppSelector(getSelectedTracker);
  const [startTime, setStartTime] = useState<Date>(moment(interval.startedAt).toDate());
  const [endTime, setEndTime] = useState<Date>(moment(interval.finishedAt).toDate());
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackerError } = useAppSelector(state => state.tracker);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onSubmit = async (values: TrackerInterval) => {
    if (tracker) {
      await dispatch(editTrackerInterval({ id: tracker.id, intervalId: interval.id, body: {
        startedAt: values.startTime,
        finishedAt: values.endTime,
      }}));
      await dispatch(getTrackers({ page, limit }));
      await dispatch(getTracker(tracker.id));
      setIsOpen(false);
    }
  };

  const initialValues: TrackerInterval = {
    startTime: startTime,
    endTime: endTime,
  };

  if (trackerError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Formik
      validationSchema={isMobile ? trackerIntervalSchemaMobile : trackerIntervalSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        setFieldValue,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box>
              <DateTimePicker
                label='Початок інтервалу'
                ampm={false}
                value={startTime}
                onChange={(value) => {
                  setStartTime(value as Date);
                  setFieldValue('startTime', value);
                }}
                sx={sxTimeDatePicker}
                maxDate={endTime}
              />
              <DateTimePicker
                label='Кінець інтервалу'
                ampm={false}
                value={endTime}
                onChange={(value) => {
                  setEndTime(value as Date);
                  setFieldValue('endTime', value);
                }}
                sx={sxTimeDatePicker}
                minDate={startTime}
              />
            </Box>
            <AcceptBlock
              variant='contained'
              disabled={!isValid || isSubmitting}
              handleAgree={handleSubmit}
              handleDisagree={() => setIsOpen(false)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default TrackerIntervalEditForm;
