import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { unitUserFields } from './constants/defaultData';
import { unitInitialValues } from './constants/initialValues';
import { unitSchema, unitSchemaMobile } from './constants/schema';
import { sxClientForm } from './styles';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import AcceptBlock from '@/components/_common/AcceptBlock';
import BasicTextField from '@/components/_common/BasicTextField';
import { sxInputWrapper } from '@/components/_common/BasicTextField/styles';
import { UnitUserData } from '@/redux/types/unitUsers';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import Button from '@/components/_common/Button';
import { editUnitUser, getSelectedUnitUser, getUnitUser, getUnitUsers, postUnitUser } from '@/redux/slices/unitUsersSlice';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface UnitUserFormProps {
  setInputVisible?: (arg: boolean) => void;
  isEditForm?: boolean;
  handleDisagree?: (arg: boolean) => void;
  unit?: UnitUserData;
}

const UnitUserForm: React.FC<UnitUserFormProps> = ({
  setInputVisible,
  isEditForm = false,
  handleDisagree,
}) => {
  const unitUser = useAppSelector(getSelectedUnitUser);
  const dispatch = useAppDispatch();
  const { unitUserError } = useAppSelector(state => state.unitUsers);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);

    if (unitId) {
      if (setInputVisible && !isEditForm) {
        await dispatch(postUnitUser({unitId, body: {
          ...values,
          status: 'active',
          image: '',
          services: [],
          roleId: 'fcf2798a-ce0c-4514-91d6-a534b9227c7e',
        }}));
        await dispatch(getUnitUsers(unitId));
        setInputVisible(false);
      } else {
        if (unitUser) {
          await dispatch(editUnitUser({ id: unitUser.id, body: {
            ...values,
            status: unitUser.status,
            image: unitUser.image,
            services: unitUser.services,
            roleId: 'fcf2798a-ce0c-4514-91d6-a534b9227c7e',
          }}));
          await dispatch(getUnitUsers(unitId));
          await dispatch(getUnitUser(unitUser.id));
          handleDisagree && handleDisagree(false);
        }
      }
    }
  };

  if (!unitUser) {
    return null;
  }

  if (unitUserError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Formik
      validationSchema={isMobile ? unitSchemaMobile : unitSchema}
      initialValues={isEditForm ? unitUser : unitInitialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleBlur,
        values,
        handleChange,
        touched,
        errors,
        isValid,
        // setFieldValue,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box sx={sxClientForm}>
              <Box display='flex' flexDirection='column'>
                {unitUserFields.map((item, index) => {
                  return (
                    <Box sx={sxInputWrapper} key={index}>
                      <BasicTextField
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        {...item}
                      />
                    </Box>
                  );
                })}
                {/* Temporarily hiden */}
                {/* <BasicRadioGroup
                  name={isEditForm ? 'role' : 'roleId'}
                  title='Доступ'
                  values={values}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('roleId', e.target.value);
                  }}
                  fields={roleFields}
                /> */}
                {/* <UploadButton setValue={() => {}} /> */}
                {isEditForm ? (
                  <AcceptBlock
                    variant='contained'
                    disabled={!isValid}
                    handleAgree={handleSubmit}
                    handleDisagree={() =>
                      handleDisagree && handleDisagree(false)
                    }
                  />
                ) : (
                  <Button
                    variant='contained'
                    disabled={!isValid}
                    type='submit'
                    buttonTitle='Зберегти'
                  />
                )}
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UnitUserForm;
