import { COLORS } from '@/assets/сolors/colors';

export const sxWrapper = {
  display: 'flex',
  justifyContent: 'space-around',
};

export const sxAgreeIcon = {
  padding: '8px',
  border: 'none',
  background: COLORS.SUCCESS_SECONDARY,
  '&:hover': {
    border: 'none',
    background: COLORS.SUCCESS,
  },
  span: {
    margin: '0px',
  },
};

export const sxDeleteIcon = {
  ml: '4px',
  padding: '8px',
  border: 'none',
  background: COLORS.DELETE_LIGHT,
  '&:hover': {
    border: 'none',
    background: COLORS.DELETE,
  },
  span: {
    margin: '0px',
  },
};
