// import { Box, Typography } from '@mui/material';
import React from 'react';
import { Grid } from '@mui/material';
// import { sxContent } from './styles';
import { User } from '@/redux/types/user';
import Spinner from '../../_common/Spinner';
// import Column from '../../_common/Column';

interface MainProps {
  user: User | null;
}

const Main: React.FC<MainProps> = ({ user }) => {
  if (user) {
    return (
      <Grid paddingBottom='120px' container spacing={2}>
        {/* <Column title='left'>
          <Box sx={sxContent}>
            <Typography>{user.email}</Typography>
          </Box>
        </Column>
        <Column title='right'>
          <Box sx={sxContent}>
            <Typography>{user.id}</Typography>
          </Box>
        </Column> */}
      </Grid>
    );
  } else return <Spinner />;
};
export default Main;
