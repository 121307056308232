import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ClientInformation from '../ClientInformation';
import ModalSidePanel from '../../../_common/ModalSidePanel';
import Print from '../../../_common/Print';
import { SelectedClient } from '@/redux/types/client';
import { COLORS } from '@/assets/сolors/colors';
import { sxCallClientReceivedButton, sxTimeTrackerBox, sxTimeTrackerBtn } from '../../TimeTracker/styles';
import ModalComponent from '../../../_common/ModalComponent';
import TimeTrackerButtons from '../../TimeTracker/TimeTrackerButtons';
import AddCase from '../../Cases/AddCase';
import { useDispatch } from 'react-redux';
import { setSelectedCase } from '@/redux/slices/casesSlice';
import { Desktop, Mobile } from '@/utils/mediaQuery';
import { sxClientCardBoxMobile } from './styles';

interface ClientCardProps {
  client: SelectedClient;
  setClose: () => void;
  setEdit: () => void;
  setRequisitesModalOpen: () => void;
  servicePackegesHandler: () => void;
}

const ClientCard: React.FC<ClientCardProps> = ({ client, setEdit, servicePackegesHandler, setRequisitesModalOpen }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!modalOpen) {
      dispatch(setSelectedCase(null));
    }
  }, [modalOpen]);

  if (modalOpen) {
    return (
      <ModalComponent
        isOpen={modalOpen}
        setClose={() => setModalOpen(false)}
        title='Справа'
      >
        <AddCase
          setIsOpen={setModalOpen}
          client={client}
        />
      </ModalComponent>
    );
  } else {
    return (
      <>
        <Desktop>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' flexDirection='column'>
              <ClientInformation {...client} />
            </Box>
            <ModalSidePanel>
              <Print
                client={client}
                modalOpen={() => setModalOpen(true)}
                setEdit={setEdit}
                setRequisitesModalOpen={setRequisitesModalOpen}
                servicePackegesHandler={servicePackegesHandler}
              />
              <Box sx={{ display: 'none' }} >
                <TimeTrackerButtons
                  classNameBtn={sxTimeTrackerBtn}
                  className={sxTimeTrackerBox('85%', COLORS.MAIN)}
                  activeTrackerButton={sxCallClientReceivedButton}
                />
              </Box>
            </ModalSidePanel>
          </Box>
        </Desktop>

        <Mobile>
          <Box sx={sxClientCardBoxMobile}>
            <ClientInformation {...client} />
              <Print
                client={client}
                modalOpen={() => setModalOpen(true)}
                setEdit={setEdit}
                setRequisitesModalOpen={setRequisitesModalOpen}
                servicePackegesHandler={servicePackegesHandler}
              />
              <Box sx={{ display: 'none' }} >
                <TimeTrackerButtons
                  classNameBtn={sxTimeTrackerBtn}
                  className={sxTimeTrackerBox('85%', COLORS.MAIN)}
                  activeTrackerButton={sxCallClientReceivedButton}
                />
              </Box>
          </Box>
        </Mobile>
      </>
    );
  }
};

export default ClientCard;
