import React from 'react';
import { Role } from '@/redux/types/unitUsers';
import { Typography } from '@mui/material';

const InvitationRole: React.FC<Role> = ({ name }) => {
  return (
    <Typography fontWeight='bold' variant='button'>
      {name}
    </Typography>
  );
};

export default InvitationRole;
