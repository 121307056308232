import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';

import Title from '@/components/_common/Title';
import TimeTracker from '@/components/screens/TimeTracker';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import Spinner from '@/components/_common/Spinner';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '@/redux/slices/categoriesSlice';
import { getServices } from '@/redux/slices/servicesSlice';
import { getAllTrackers, getTrackers } from '@/redux/slices/trackerSlice';
import { getUnitUsers } from '@/redux/slices/unitUsersSlice';

const TrackerPage: React.FC = () => {
  const { trackers } = useAppSelector(getAllTrackers);
  const dispatch = useAppDispatch();
  const { serviceError } = useAppSelector(state => state.services);
  const { categoryError } = useAppSelector(state => state.categories);
  const { trackerError, trackerStatus } = useAppSelector(state => state.tracker);
  const { unitUserError } = useAppSelector(state => state.unitUsers);
  const navigate = useNavigate();

  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);

  useEffect(() => {
    if (unitId) {
      Promise.all([
        dispatch(getUnitUsers(unitId)),
        dispatch(getCategories(unitId)),
        dispatch(getServices(unitId)),
        dispatch(getTrackers({})),
      ])
    }
}, [unitId]);

  const trackersData = useMemo(() => {
    return trackers;
  }, [trackers]);

  if (trackerError || serviceError || categoryError || unitUserError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Трек часу' />
      {trackerStatus === 'loading' && <Spinner />}
      {trackersData && trackersData.data.length > 0 && trackersData.pagination && (
        <TimeTracker accordionData={trackersData.data} pagination={trackersData.pagination} />
      )}
    </Container>
  );
};

export default TrackerPage;
