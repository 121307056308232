import { createLogger } from 'redux-logger';

const logger = createLogger({
  level: 'log',
  logger: console,
  logErrors: true,
  collapsed: () => true,
  predicate: undefined,
  duration: false,
  timestamp: false,
  stateTransformer: (state: unknown) => state,

  actionTransformer: (action: unknown) => action,

  errorTransformer: (error: unknown) => error,
  colors: {
    title: () => 'inherit',
    prevState: () => '#9E9E9E',
    action: () => '#03A9F4',
    nextState: () => '#4CAF50',
    error: () => '#F20404',
  },
  diff: true,
  diffPredicate: undefined,
});

const exportedArray = [logger];

export default exportedArray;
