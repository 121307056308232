import { COLORS } from '@/assets/сolors/colors';

export const sxAccordionBtn = {
  alignSelf: 'flex-end',
  display: 'flex',
  minWidth: '240px',
  mb: '12px',
};

export const sxAccordionBtnMobile = {
  display: 'flex',
  alignSelf: 'center',
  minWidth: '240px',
  mb: '12px',
};

export const sxAccordionDeleteBtn = {
  alignSelf: 'flex-end',
  minWidth: '240px',
  background: COLORS.MAIN,
  '&:hover': {
    background: COLORS.DELETE,
  },
};

export const sxAccordionDeleteBtnMobile = {
  alignSelf: 'center',
  minWidth: '240px',
  '&:hover': {
    background: COLORS.DELETE,
  },
};

export const sxAccordionWrapper = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
};
