import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Cases from '@/components/screens/Cases';
import Spinner from '@/components/_common/Spinner';
import Title from '@/components/_common/Title';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { getAllCases, getCases } from '@/redux/slices/casesSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';

const CasesPage: React.FC = () => {
  const { cases } = useAppSelector(getAllCases);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const { caseStatus, caseError } = useAppSelector(state => state.cases);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (unitId) {
      dispatch(getCases({ unitId }));
    }
  }, [unitId]);

  const casesData = useMemo(() => {
    return cases;
  }, [cases]);

  if (caseError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Справи' />
      {caseStatus === 'loading' && <Spinner />}
      {casesData && casesData.data.length > 0 && casesData.pagination && <Cases cases={casesData.data} pagination={casesData.pagination} />}
    </Container>
  );
};

export default CasesPage;
