import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from './app/router/AppRouter';
import ReduxStoragePersist from './redux/ReduxStoragePersist';
import './modules/config';

function App() {
  return (
    <ReduxStoragePersist>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ReduxStoragePersist>
  );
}

export default App;
