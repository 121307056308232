import React, { useState } from 'react';
import { deleteClient, getClient, getClients, getSelectedClient, setSelectedClient } from '@/redux/slices/clientsSlice';
import { rootActions, useAppDispatch, useAppSelector } from '@/redux/store';
import { ClientsPagination, SelectedClient } from '@/redux/types/client';
import ClientAccordionDetails from './ClientAccordionDetails/ClientAccordionDetails';
import ClientAccordionSummary from './ClientAccordionSummary/ClientAccordionSummary';
import ModalComponent from '../../_common/ModalComponent';
import ClientCard from './ClientCard';
import ClientForm from '@/forms/Forms/ClientForm';
import { sxAddClientBtn } from '../../_common/AppBar/styles';
import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import AccordionComponent from '../../_common/AccordionComponent/AccordionComponent';
import { LOCAL_STORAGE } from '@/constants';
import Pagination from '../../_common/Pagination';
import RequisitesForm from '@/forms/Forms/RequisitesForm';
import ServicePackageForm from '@/forms/Forms/ServicePackageForm';
import { sxClientsBox, sxClientsBoxMobile } from './styles';
import useActionCreators from '@/hooks/useActionCreators';
import Button from '../../_common/Button';
import ErrorPage from '@/pages/ErrorPage';
import { getAllCategories } from '@/redux/slices/categoriesSlice';
import { getModalComponentStatus } from '@/redux/slices/modalComponentSlice';
import { useMediaQuery } from 'react-responsive';
import { scrollAccordionToCenter } from '@/utils/scrollAccordionToCenter';
import { Desktop, Mobile } from '@/utils/mediaQuery';

interface ClientsProps {
  accordionData: SelectedClient[];
  pagination?: ClientsPagination;
}

const Clients: React.FC<ClientsProps> = ({ accordionData, pagination }) => {
  const client = useAppSelector(getSelectedClient);
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [requisitesModalOpen, setRequisitesModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const [isPacketModalOpen, setIsPacketModalOpen] = useState<boolean>(false);
  const categories = useAppSelector(getAllCategories) || [];
  const { clientStatus } = useAppSelector(state => state.clients);
  const actions = useActionCreators(rootActions);
  const modalComponentStatus = useAppSelector(getModalComponentStatus);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { page, limit, pagesTotal, totalCount } = pagination || {
    page: 1,
    limit: 50,
    pagesTotal: 1,
    totalCount: 0,
  };

  const handlePaginationChange = (newPage: number) => {
    if (unitId) {
      dispatch(getClients({ unitId, page: newPage, limit }));
    }
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    if (unitId) {
      dispatch(getClients({ unitId, page: 1, limit: newLimit }));
    }
  };

  const servicePackegesHandler = () => {
    setIsPacketModalOpen(true)
  }

  const accordionOpen =
    (panel: string, client: SelectedClient) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      dispatch(setSelectedClient(client));
    };

  const accordionOpenMobile = (panel: string, client: SelectedClient) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    dispatch(setSelectedClient(client));
  
    if (isExpanded) {
      scrollAccordionToCenter(panel);
    }
  };

  const modalCloseHandler = () => {
    actions.setModalComponentStatus(false);
    setEdit(false);
    setRequisitesModalOpen(false);
    setIsPacketModalOpen(false);
  };

  const deleteClientHandler = async () => {
    if (unitId && client) {
      await dispatch(deleteClient({ unitId, clientId: client.id }));
      await dispatch(getClients({ unitId, page, limit }));
    }
  };

  const requisitesHandler = () => {
    setRequisitesModalOpen(true);
  };

  const editClientHandler = () => {
    setEdit(true);
  };

  const openClientHandler = async () => {
    if (unitId && client) {
      await dispatch(getClient({ unitId, clientId: client.id }));
      actions.setModalComponentStatus(true);
    }
  }

  return (
    <Box sx={clientStatus === 'loading'
      ? (isMobile ? { ...sxClientsBoxMobile, pointerEvents: 'none' } : { ...sxClientsBox, pointerEvents: 'none' }) 
      : (isMobile ? { ...sxClientsBoxMobile }  : { ...sxClientsBox })}
    >
      <Button
        variant='contained'
        sx={sxAddClientBtn}
        startIcon={<Add />}
        onClick={() => setIsModalOpen(true)}
        buttonTitle='Додати клієнта'
      />
      <Desktop>
        <>
          {accordionData
            .slice(0, limit)
            .map((client, index) => {
            const id = client?.id;
            
            return (
              <AccordionComponent
                sx={{ width: '100%' }}
                id={id}
                expanded={expanded === id}
                handleAccordionExpandChange={accordionOpen(id, client)}
                accordionSummaryChildren={<ClientAccordionSummary {...client} />}
                ariaControls={id}
                key={index}
                accordionDetailsChildren={
                  <ClientAccordionDetails
                    deleteClient={deleteClientHandler}
                    modalClientOpen={openClientHandler}
                  />
                }
              />
            );
          })}
        </>
      </Desktop>
      
      <Mobile>
        <>
          {accordionData
            .slice(0, limit)
            .map((client, index) => {
            const id = client?.id;
            
            return (
              <AccordionComponent
                sx={{ width: '100%' }}
                id={id}
                expanded={expanded === id}
                handleAccordionExpandChange={accordionOpenMobile(id, client)}
                accordionSummaryChildren={<ClientAccordionSummary {...client} />}
                ariaControls={id}
                key={index}
                accordionDetailsChildren={
                  <ClientAccordionDetails
                    deleteClient={deleteClientHandler}
                    modalClientOpen={openClientHandler}
                  />
                }
              />
            );
          })}
        </>
      </Mobile>
      
      <ModalComponent
        title={edit ? 'Редагування' : 'Дані клієнта'}
        isOpen={modalComponentStatus}
        setClose={modalCloseHandler}
        backStep={edit ? setEdit : modalCloseHandler}
      >
        {edit ? (
          <ClientForm
            title='Зберегти'
            modalClose={modalCloseHandler}
            isEditForm={edit}
            setEditForm={setEdit}
            page={page}
            limit={limit}
          />
        ) : (
          client ? (
            <ClientCard
            client={client}
            setEdit={editClientHandler}
            setRequisitesModalOpen={requisitesHandler}
            setClose={modalCloseHandler}
            servicePackegesHandler={servicePackegesHandler}
          />
          ) : <ErrorPage errorMessage='Клієнта не знайдено' />
        )}
      </ModalComponent>
      <ModalComponent
        title={client && client.requisites ? 'Редагувати реквізити' : 'Створити реквізити'}
        isOpen={requisitesModalOpen}
        setClose={modalCloseHandler}
        backStep={requisitesModalOpen ? setRequisitesModalOpen : modalCloseHandler}
      >
        {requisitesModalOpen && client ? (
          <RequisitesForm
            setIsOpen={setRequisitesModalOpen}
            client={client}
          />
        ) : (
          client ? (
            <ClientCard
              client={client}
              setEdit={editClientHandler}
              setRequisitesModalOpen={requisitesHandler}
              setClose={modalCloseHandler}
              servicePackegesHandler={servicePackegesHandler}
            />
          ) : <ErrorPage errorMessage='Клієнта не знайдено' />
        )}
      </ModalComponent>
      <ModalComponent
        title='Дані клієнта'
        isOpen={isModalOpen}
        setClose={() => setIsModalOpen(false)}
      >
        <ClientForm modalClose={() => setIsModalOpen(false)} />
      </ModalComponent>
      <ModalComponent
        title={client && client.servicePackages && client.servicePackages.length > 0 ? 'Редагувати пакет послуг' : 'Створити пакет послуг'}
        isOpen={isPacketModalOpen}
        setClose={modalCloseHandler}
        backStep={client && client.servicePackages ? setIsPacketModalOpen : modalCloseHandler}
      >
        {isPacketModalOpen && client ? (
          <ServicePackageForm
            categories={categories}
            client={client}
            setIsOpen={setIsPacketModalOpen}
          />
        ) : (
          client ? (
            <ClientCard
              client={client}
              setEdit={editClientHandler}
              setRequisitesModalOpen={requisitesHandler}
              setClose={modalCloseHandler}
              servicePackegesHandler={servicePackegesHandler}
            />
          ) : <ErrorPage errorMessage='Клієнта не знайдено' />
        )}
      </ModalComponent>
    <Pagination
      page={page}
      limit={limit}
      pagesTotal={pagesTotal}
      totalItems={totalCount}
      onPageChange={handlePaginationChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
    </Box>
  );
};

export default Clients;
