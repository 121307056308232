import { Box } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { sxClientForm } from './styles';
import BasicLoadingButton from '@/components/_common/BasicLoadingButton';
import BasicRadioGroup from '@/components/_common/BasicRadioGroup';
import BasicTextField from '@/components/_common/BasicTextField';
import { sxInputWrapper } from '@/components/_common/BasicTextField/styles';
import { businessFields, clientFields } from '@/components/screens/Clients/ClientBox/defaultData';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { SelectedClient } from '@/redux/types/client';
import { clientSchema, clientSchemaMobile } from './constants/schema';
import { clientInitialValues } from './constants/initialValues';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { editClient, getClients, getSelectedClient, postClient } from '@/redux/slices/clientsSlice';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface ClientFormProps {
  modalClose: () => void;
  title?: string;
  isEditForm?: boolean;
  setEditForm?: (arh: boolean) => void;
  page?: number;
  limit?: number;
}

const ClientForm: React.FC<ClientFormProps> = ({
  modalClose,
  title,
  isEditForm = false,
  setEditForm,
  page,
  limit,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const selectedClient = useAppSelector(getSelectedClient);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const dispatch = useAppDispatch();
  const { clientError } = useAppSelector(state => state.clients);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onSubmit = (
    values: SelectedClient,
    helpers: FormikHelpers<SelectedClient>
  ) => {
    helpers.setTouched({});
    helpers.setSubmitting(false);
    setLoading(true);

    setTimeout(async () => {
      setLoading(false);
      if (unitId) {
        if (isEditForm) {
          await dispatch(editClient({ unitId, body: { ...values }}))
          setEditForm && setEditForm(false);
          await dispatch(getClients({ unitId, page, limit }));
        } else {
          await dispatch(postClient({ unitId, body: { ...values } }))
          modalClose();
          await dispatch(getClients({ unitId, page, limit }));
        }
      }
    }, 1000);
  };

  if (clientError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Formik
      validationSchema={isMobile ? clientSchemaMobile : clientSchema}
      initialValues={isEditForm ? selectedClient : clientInitialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleBlur,
        values,
        handleChange,
        touched,
        errors,
        isValid,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box sx={sxClientForm}>
              {clientFields.map((item, index) => {
                return (
                  <Box sx={sxInputWrapper} key={index}>
                    <BasicTextField
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      {...item}
                    />
                  </Box>
                );
              })}
              <Box display='flex' flexDirection='column'>
                <BasicRadioGroup
                  name='personType'
                  title='Діяльність'
                  values={values}
                  onChange={handleChange}
                  fields={businessFields}
                />
              </Box>
              <BasicLoadingButton
                title={title}
                loading={loading}
                isValid={isValid}
              />
            </Box>

          </Form>
        );
      }}
    </Formik>
  );
};
export default ClientForm;
