import { Box, IconButton, Link, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { signUp } from './constants/defaultData';
import { sxAuthForm, sxBasicTextFieldBox, sxBtnWrapper, sxFieldWrapper, sxShowPasswordIcon } from './styles';
import { ClientFormValues } from './types';
import { ROUTES } from '@/constants';
import { useAuth } from '@/hooks/useAuth';
import BasicLoadingButton from '@/components/_common/BasicLoadingButton';
import BasicTextField from '@/components/_common/BasicTextField';
import { sxInputWrapper } from '@/components/_common/BasicTextField/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface SignUpProps {
  schema: object;
  initialValues: ClientFormValues;
}

const RegistrationForm: React.FC<SignUpProps> = ({ schema, initialValues }) => {
  const navigate = useNavigate();
  const [sending, setSending] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { registration } = useAuth();

  const onSubmit = async (
    values: ClientFormValues,
    helpers: FormikHelpers<ClientFormValues>
  ) => {
    helpers.setTouched({});
    helpers.setSubmitting(false);
    setSending(true);
    const { email, password } = values;

    await registration(email, password, setSending);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleBlur,
        values,
        handleChange,
        touched,
        errors,
        isValid,
      }) => {
        return (
          <Form style={sxAuthForm} onSubmit={handleSubmit}>
            <Box display='flex'>
              <Box sx={sxFieldWrapper}>
                {signUp.map((item, index) => {
                  return (
                    <Box sx={sxInputWrapper} key={index}>
                      <Box sx={sxBasicTextFieldBox}>
                        <BasicTextField
                          {...item}
                          errors={errors}
                          touched={touched}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          type={item.name === 'password' ? (showPassword ? 'text' : 'password') : item.type}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box>
                <IconButton
                  onClick={() => setShowPassword(prevState => !prevState)}
                  aria-label={showPassword ? 'Сховати пароль' : 'Показати пароль'}
                  edge='end'
                  sx={sxShowPasswordIcon}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Box>
            </Box>
            <Box sx={sxBtnWrapper}>
              <Typography>
                Маєте аккаунт?
                <Link onClick={() => navigate(ROUTES.LOGIN)}>Увійти</Link>
                <BasicLoadingButton loading={sending} isValid={isValid} />
              </Typography>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegistrationForm;
