export function scrollAccordionToCenter(panel: string) {
  const element = document.getElementById(panel);
  if (element) {
    const elementRect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    
    const scrollTo = elementRect.top + elementRect.height / 2 - viewportHeight / 2;
    const currentScroll = window.scrollY;
    const targetScroll = currentScroll + scrollTo;

    window.scrollTo({
      top: targetScroll,
      behavior: 'smooth'
    });
  }
}
