export const sxStack = {
  width: '100%',
};

export const sxAlert = {
  minWidth: '300px',
};

export const sxAlertMobile = {
  width: '100%',
};
