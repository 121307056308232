import {
  AccessTimeFilled,
  Groups,
  Home,
  Key,
  ReceiptLong,
  Search,
  SupervisedUserCircle,
  Work,
} from '@mui/icons-material';
import * as React from 'react';

import { ROUTES } from '@/constants';

export const appItems = [
  {
    icon: <Home />,
    title: 'Головна',
    target: ROUTES.MAIN,
  },
  {
    icon: <Groups />,
    title: 'Клієнти',
    target: ROUTES.CLIENTS,
  },
  {
    icon: <Work />,
    title: 'Справи',
    target: ROUTES.CASES,
  },
  {
    icon: <AccessTimeFilled />,
    title: 'Трек часу',
    target: ROUTES.TRACKER,
  },
  {
    icon: <ReceiptLong />,
    title: 'Рахунки',
    target: ROUTES.BILLS,
  },
  {
    icon: <Search />,
    title: 'Пошук',
    target: ROUTES.SEARCH,
  },
  {
    icon: <SupervisedUserCircle />,
    title: 'Користувачі',
    target: ROUTES.USERS,
  },
  {
    icon: <Key />,
    title: 'Послуги',
    target: ROUTES.SERVICES,
  },
];
