import { Box } from '@mui/material';
import React from 'react';
import { useAppSelector } from '@/redux/store';
import { UnitUserData } from '@/redux/types/unitUsers';
import UserCard from './UserCard';
import Spinner from '../../_common/Spinner';
import { sxUserBox } from './styles';
import { useMediaQuery } from 'react-responsive';

interface UserProps {
  user: UnitUserData;
}

const User: React.FC<UserProps> = ({ user }) => {
  const { unitUserStatus } = useAppSelector(state => state.unitUsers);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box sx={unitUserStatus === 'loading' ? { ...sxUserBox, pointerEvents: 'none'} : sxUserBox }>
      {unitUserStatus === 'loading' && <Spinner />}
      <Box sx={isMobile ? {} : { width: '50%' }}>
        <UserCard unitUser={user} />
      </Box>
    </Box>
  );
};

export default User;
