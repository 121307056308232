import React from 'react';
import { TablePagination } from '@mui/material';
import { sxPagination } from './styles';
import { Desktop, Mobile } from '@/utils/mediaQuery';

type Props = {
  page: number;
  limit: number;
  pagesTotal: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (limit: number) => void;
};

const Pagination: React.FC<Props> = ({
  page,
  limit,
  pagesTotal,
  totalItems,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(newRowsPerPage);
  };

  return (
    <>
      <Desktop>
        <TablePagination
          component='div'
          count={totalItems}
          page={(page > 0 && totalItems === limit) ? 0 : page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage='Записів на сторінці'
          labelDisplayedRows={() => `Сторінки ${page} / ${pagesTotal}. Всього записів: ${totalItems}`}
          sx={sxPagination}
          nextIconButtonProps={{
            onClick: () => onPageChange(page + 1),
            disabled: page >= pagesTotal || totalItems === 0,
          }}
          backIconButtonProps={{
            onClick: () => onPageChange(page - 1),
            disabled: page <= 1,
          }}
        />
      </Desktop>

      <Mobile>
      <TablePagination
        component='div'
        count={totalItems}
        page={(page > 0 && totalItems === limit) ? 0 : page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        labelRowsPerPage=''
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
        sx={sxPagination}
        nextIconButtonProps={{
          onClick: () => onPageChange(page + 1),
          disabled: page >= pagesTotal || totalItems === 0,
        }}
        backIconButtonProps={{
          onClick: () => onPageChange(page - 1),
          disabled: page <= 1,
        }}
      />
      </Mobile>
    </>
  );
};

export default Pagination;
