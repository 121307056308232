import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  sxCenterContent,
  sxRow,
  sxTable,
} from './styles';
import { useAppSelector } from '@/redux/store';
import { Tracker } from '@/redux/types/tracker';
import moment from 'moment';
import { setError } from '@/redux/slices/notificationSlice';
import { useDispatch } from 'react-redux';
import EditTimeTracker from '../EditTimeTracker';
import ViewTimeTracker from '../ViewTimeTracker';
import { setSelectedTracker } from '@/redux/slices/trackerSlice';
import { getAllCategories } from '@/redux/slices/categoriesSlice';

interface TrackerAccordionDetailsProps {
  deleteTrackerAccordion: () => void;
  trackerAccordion: Tracker;
}

const TimeTrackerAccordionDetails: React.FC<TrackerAccordionDetailsProps> = ({
  deleteTrackerAccordion,
  trackerAccordion,
}) => {
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [isEditTracker, setIsEditTracker] = useState<boolean>(false);
  const [trackerDate, setTrackerDate] = useState('');
  const categories = useAppSelector(getAllCategories) || [];
  const dispatch = useDispatch();

  useEffect(() => {
      const сurrentDate = trackerAccordion.trackerDate;
      const formattedDate = сurrentDate.toString().split('T')[0];
      
      setTrackerDate(formattedDate);
  }, []);

  const deleteHandler = () => {
    setIsWarningOpen(false);
    deleteTrackerAccordion();
  };

  const editHandler = () => {
    setIsEditTracker(!isEditTracker);
    dispatch(setSelectedTracker(trackerAccordion));
  }; 

  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = moment(e.target.value);
    const currentDate = moment();
  
    let minDate: moment.Moment;
    if (currentDate.date() >= 1 && currentDate.date() < 5) {
      minDate = moment(currentDate).subtract(1, 'month').date(5);
    } else {
      minDate = moment(currentDate).date(5);
    }
    const maxDate = currentDate;
  
    if (selectedDate.isSameOrAfter(minDate, 'day') && selectedDate.isSameOrBefore(maxDate, 'day')) {
      setTrackerDate(e.target.value);
    } else {
      dispatch(setError('Не валідна дата'));
    }
  };

  return (
    <Container sx={sxCenterContent}>
      <Table
        sx={sxTable}
        aria-label='simple table'
        padding='none'
        size='small'
      >
        <TableHead>
          <TableRow sx={sxRow}>
            <TableCell>Дата</TableCell>
            <TableCell>Справа / Послуга</TableCell>
            <TableCell align='center'>Трек часу</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEditTracker ? (
            <EditTimeTracker
              trackerDate={trackerDate}
              handleChangeDate={handleChangeDate}
              setIsEditTracker={setIsEditTracker}
              categories={categories}
              tracker={trackerAccordion}
              editHandler={editHandler}
              isWarningOpen={isWarningOpen}
              setIsWarningOpen={setIsWarningOpen}
              deleteHandler={deleteHandler}
            />
            ) : (
              <ViewTimeTracker
                tracker={trackerAccordion}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
                isWarningOpen={isWarningOpen}
                setIsWarningOpen={setIsWarningOpen}
              />
            )
          }
        </TableBody>
      </Table>
      </Container>
  );
};

export default TimeTrackerAccordionDetails;
