import { COLORS } from '@/assets/сolors/colors';

export const sxText = {
  fontSize: '20px',
  fontWeight: 700,
  color: COLORS.MAIN,
  mb: '16px',
  textAlign: 'center',
};

export const sxBtn = {
  width: '100%',
};

export const sxBtnDisabled = {
  background: COLORS.DISABLED,
  color: COLORS.DARK,
};
