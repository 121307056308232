/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { Category, CategoryRequest } from '../types/category';
import { setSuccess } from './notificationSlice';

export const postCategory = createAsyncThunk(
  'categories/postCategory',
  async ({ unitId, body }: { unitId: string, body: CategoryRequest }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.SERVICE_CATEGORIES}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні категорії!');
      }

      dispatch(setSuccess('Категорію створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async (unitId: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.SERVICE_CATEGORIES}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку категорій!');
      }

      dispatch(setCategories(res));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const editCategory = createAsyncThunk(
  'categories/editCategory',
  async ({ unitId, categoryId, body }: { unitId: string, categoryId: string, body: CategoryRequest }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.SERVICE_CATEGORIES}/${categoryId}`;
      const res = await Http.patch(url, body);

      if (res) {
        dispatch(setSuccess('Категорію замінено'));
        return res;
      } else {
        throw new Error('Немає відповіді з сервера при редагуванні категорії!');
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async ({ unitId, categoryId }: { unitId: string, categoryId: string }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.SERVICE_CATEGORIES}/${categoryId}`;
      await Http.delete(url);

      dispatch(setSuccess('Категорію видалено'));
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.categoryStatus = 'loading';
  state.categoryError = null;
}

const setResolved = (state: any) => {
  state.categoryStatus = 'resolved';
}

const setError = (state: any, action: any) => {
  state.categoryStatus = 'rejected';
  state.categoryError = action.payload;
}

const initialState: Category = {
  data: [],
  pagination: null,
  selectedCategory: null,
  selectedCategories: [],
  categoryStatus: null,
  categoryError: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedCategories: (state, action) => {
      state.selectedCategories = action.payload;
    },
    setCategoryError: (state, action) => {
      state.categoryError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCategory.pending, setLoading)
      .addCase(getCategories.pending, setLoading)
      .addCase(editCategory.pending, setLoading)
      .addCase(deleteCategory.pending, setLoading)
      .addCase(postCategory.fulfilled, setResolved)
      .addCase(getCategories.fulfilled, setResolved)
      .addCase(editCategory.fulfilled, setResolved)
      .addCase(deleteCategory.fulfilled, setResolved)
      .addCase(postCategory.rejected, setError)
      .addCase(getCategories.rejected, setError)
      .addCase(editCategory.rejected, setError)
      .addCase(deleteCategory.rejected, setError);
  },
});

export const { setCategories, setSelectedCategory, setSelectedCategories, setCategoryError } = categoriesSlice.actions;

export const getAllCategories = (state: MyStore) => state[categoriesSlice.name].data;
export const getSelectedCategory = (state: MyStore) => state[categoriesSlice.name].selectedCategory;
export const getSelectedCategories = (state: MyStore) => state[categoriesSlice.name].selectedCategories;
export const getCategoryError = (state: MyStore) => state[categoriesSlice.name].categoryError;

export default categoriesSlice;
