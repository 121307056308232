import MenuIcon from '@mui/icons-material/Menu';
import { Toolbar, IconButton, AppBar as MuiAppBar, Box } from '@mui/material';
import React, { useState } from 'react';

import AppBarMenu from './AppBarMenu';
import {
  sxAppBarContent,
  sxAppBarWrapper,
  sxMenuIcon,
  sxMenuIconMobile,
  sxTrackerWrapper,
} from './styles';
import { useAppSelector } from '@/redux/store';
import AppBarSearch from '../AppBarSearch';
import {
  sxTimeTrackerBoxAppBar,
  sxTimeTrackerBtnAppBar,
} from '../../screens/TimeTracker/styles';
import AppUserMenu from './AppUserMenu';
import TimeTrackerButtons from '../../screens/TimeTracker/TimeTrackerButtons';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { getActiveTrackers } from '@/redux/slices/trackerSlice';
import Tooltip from '../Tooltip/Tooltip';
import { useMediaQuery } from 'react-responsive';


const AppBar: React.FC = () => {
  const [isAppBarOpen, setIsAppBarOpen] = useState<boolean>(false);
  const activeTrackers = useAppSelector(getActiveTrackers);
  const location = useLocation();
  const isSearchPage = location.pathname === `${ROUTES.SEARCH}`;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const appBarHandler = () => {
    setIsAppBarOpen(!isAppBarOpen);
  };

  const renderTimeTracker = () => {
    return (
      <Box sx={sxTrackerWrapper}>
        <TimeTrackerButtons
          className={sxTimeTrackerBoxAppBar}
          classNameBtn={sxTimeTrackerBtnAppBar}
        />
      </Box>
    );
  };

  return (
    <>
      <AppBarMenu
        isOpen={isAppBarOpen}
        openHandler={appBarHandler}
        closeHandler={appBarHandler}
      />
      <MuiAppBar sx={sxAppBarWrapper}>
        <Toolbar>
          <Box sx={sxAppBarContent}>
            <IconButton
              sx={isMobile ? sxMenuIconMobile : sxMenuIcon}
              color='inherit'
              aria-label='Menu'
              onClick={appBarHandler}
            >
              <MenuIcon />
            </IconButton>
            {!isSearchPage && !isMobile && <AppBarSearch />}
          </Box>
          {activeTrackers && activeTrackers.length > 0
            && ( activeTrackers[0].clientName || activeTrackers[0].courtCase?.caseNumber || activeTrackers[0].service?.name ) ? (
            <Tooltip
              title={
                <>
                  {activeTrackers[0].clientName && (
                    <Box>
                      Клієнт: {activeTrackers[0].clientName}
                    </Box>
                  )}
                  {activeTrackers[0].courtCase && (
                    <Box>
                      Cправа: {activeTrackers[0].courtCase.caseNumber}
                    </Box>
                  )}
                  {activeTrackers[0].service && activeTrackers[0].service.name && (
                  <Box>
                    Сервіс: {activeTrackers[0].service.name}
                  </Box>
                  )}
                </>
              } 
              >
              {renderTimeTracker()}
            </Tooltip>
          ) : (
            <Tooltip
              title='Дані трекера ще не додані' 
              >
              {renderTimeTracker()}
            </Tooltip>
          )}
          <AppUserMenu />
        </Toolbar>
      </MuiAppBar>
    </>
  );
};

export default AppBar;
