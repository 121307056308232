/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { Cases } from '../types/case';
import { setSuccess, setError } from './notificationSlice';

export const postCase = createAsyncThunk(
  'cases/postCase',
  async ({ unitId, body }: { unitId: string, body: any }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.COURT_CASES}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні справи!');
      }

      dispatch(setSuccess('Справу додано успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getCases = createAsyncThunk(
  'cases/getCases',
  async ({ unitId, page = 1, limit = 50 }: { unitId: string, page?: number, limit?: number }, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.COURT_CASES}?page=${page}&limit=${limit}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку справ!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getCase = createAsyncThunk(
  'cases/getCase',
  async ({ unitId, caseNumber }: { unitId: string, caseNumber: string }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.COURT_CASES}/${encodeURIComponent(caseNumber)}`;
      const res = await Http.get(url);

      if (res) {
        dispatch(setSelectedCase(res));
        return res;
      } else {
        dispatch(setSelectedCase(null));
        dispatch(setError('Справа не знайдена'));
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.caseStatus = 'loading';
  state.caseError = null;
}

const setCasesResolved = (state: any, action: any) => {
  state.caseStatus = 'resolved';
  state.cases = action.payload;
}

const setResolved = (state: any) => {
  state.caseStatus = 'resolved';
}

const ErrorCase = (state: any, action: any) => {
  state.caseStatus = 'rejected';
  state.caseError = action.payload;
}

const initialState: Cases = {
  cases: null,
  selectedCase: null,
  caseStatus: null,
  caseError: null,
};
const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    setSelectedCase: (state, action) => {
      state.selectedCase = action.payload;
    },
    setCaseError: (state, action) => {
      state.caseError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCase.pending, setLoading)
      .addCase(getCases.pending, setLoading)
      .addCase(getCase.pending, setLoading)
      .addCase(postCase.fulfilled, setResolved)
      .addCase(getCases.fulfilled, setCasesResolved)
      .addCase(getCase.fulfilled, setResolved)
      .addCase(postCase.rejected, ErrorCase)
      .addCase(getCases.rejected, ErrorCase)
      .addCase(getCase.rejected, ErrorCase);
  },
});

export const { setSelectedCase, setCaseError } = casesSlice.actions;

export const getAllCases = (state: MyStore) => state[casesSlice.name];
export const getSelectedCase = (state: MyStore) => state[casesSlice.name].selectedCase;
export const getCaseError = (state: MyStore) => state[casesSlice.name].caseError;

export default casesSlice;
