import { COLORS } from '@/assets/сolors/colors';

export const sxWrapper = {
  display: 'flex',
  justifyContent: 'space-around',
  minHeight: '64px',
};

export const sxWrapperMobile = {
  display: 'flex',
  justifyContent: 'space-around',
  minHeight: '56px',
  minWidth: '80px'
};

export const sxDeleteIcon = {
  ml: '4px',
  padding: '8px',
  border: 'none',
  background: 'transparent',
  color: COLORS.DELETE_LIGHT,
  '&:hover': {
    border: 'none',
    color: COLORS.DELETE,
    background: 'transparent',
  },
  span: {
    margin: '0px',
  },
};

export const sxDeleteIconMobile = {
  padding: '8px',
  background: 'transparent',
  color: COLORS.DELETE_LIGHT,
  '&:hover': {
    border: 'none',
    color: COLORS.DELETE,
    background: 'transparent',
  },
  span: {
    margin: '0px',
  },
};

export const sxEditIcon = (active: boolean) => {
  return {
    padding: '8px',
    background: 'transparent',
    color: active ? COLORS.WHITE : COLORS.MAIN,
    '&:hover': {
      color: COLORS.SUCCESS,
      background: 'transparent',
    },
    span: {
      margin: '0px',
    },
  };
};
