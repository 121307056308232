import { Box } from '@mui/material';
import React from 'react';
import { Case } from '@/redux/types/case';
import CaseTextBlock from './CaseTextBlock';
import PersonIcon from '@mui/icons-material/Person';
import { sxCaseBox, sxCaseBoxMobile, sxClientBtn } from './styles';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { useNavigate } from 'react-router-dom';
import CaseNotes from './CaseNotes';
import Button from '../../_common/Button';
import { Desktop, Mobile } from '@/utils/mediaQuery';

interface CaseProps {
  selectedCase: Case;
}

const CaseView: React.FC<CaseProps> = ({selectedCase}) => {
  const navigate = useNavigate();

  const handleClientBtn = () => {
    localStorage.setItem(LOCAL_STORAGE.SEARCH_ENTITY_ID, selectedCase.clientId);
    navigate(`${ROUTES.CLIENTS}/${selectedCase.clientId}`);
  }

  return (
    <>
      <Desktop>
        <Box sx={sxCaseBox}>
          <CaseNotes selectedCase={selectedCase} />
          <CaseTextBlock selectedCase={selectedCase} />
          <Box sx={{ width: '20%' }}>
            <Button
              variant='contained'
              fullWidth
              title='Перейти до клієнта'
              startIcon={<PersonIcon />}
              onClick={handleClientBtn}
              sx={sxClientBtn}
              buttonTitle='Клієнт'
            />
          </Box>
        </Box>
      </Desktop>

      <Mobile>
        <Box sx={sxCaseBoxMobile}>
          <Box sx={{minWidth: '240px'}}>
            <Button
              variant='contained'
              fullWidth
              title='Перейти до клієнта'
              startIcon={<PersonIcon />}
              onClick={handleClientBtn}
              sx={sxClientBtn}
              buttonTitle='Клієнт'
            />
          </Box>
          <CaseTextBlock selectedCase={selectedCase} />
          <CaseNotes selectedCase={selectedCase} />
        </Box>
      </Mobile>
    </>
  )
};

export default CaseView;
