/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { auth } from '@/modules/config';
import { setError, setSuccess } from '@/redux/slices/notificationSlice';
import { getCurrentUser, removeUser, setUser } from '@/redux/slices/userSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { connectSocket, socket } from '@/services/socket';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector(getCurrentUser);
  const id = user?.id;
  const email = user?.id;
  const isToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
  
  const login = async (
    email: string,
    password: string,
    loading: (arg: boolean) => void,
    target: string,
  ) => {
    
    await signInWithEmailAndPassword(auth, email, password)
      .then(async ({ user }: any) => {
        localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, user.accessToken);
        connectSocket();
        dispatch(setSuccess('Успіх'));
        localStorage.setItem(LOCAL_STORAGE.ACTIVE_MENU_ITEM, 'Головна');

        if (target) {
          navigate(target);
        } else {
          navigate(ROUTES.MAIN);
        }
      })
      .catch(() => {
        dispatch(setError('Не правильна пошта чи пароль'));
        loading(false);
      });
  };

  const registration = (
    email: string,
    password: string,
    loading: (arg: boolean) => void
  ) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(({ user }: any) => {
        localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, user.accessToken);
        dispatch(
          setUser({
            email: user.email,
            token: user.accessToken,
            id: user.uid,
          })
        );
        dispatch(setSuccess('Успіх'));
        navigate(ROUTES.SELECT_ORGANIZATION);
      })
      .catch((e) => {
        dispatch(setError('Помилка'));
        loading(false);
        console.log('error: ', e)
      });
  };

  const logOut = () => {
    socket.disconnect();
    auth
      .signOut()
      .then(() => navigate(ROUTES.LOGIN))
      .then(() => dispatch(removeUser()));
  };

  return {
    registration,
    login,
    logOut,
    isAuth: !!isToken,
    email,
    id,
  };
};
