import { COLORS } from '@/assets/сolors/colors';

export const sxListItemsWrapper = {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',
};

export const sxListUser = {
  background: COLORS.MAIN_LIGHT,
  color: COLORS.MAIN,
  minHeight: '64px',
  cursor: 'default',
};

export const sxCloseMenu = {
  background: COLORS.MAIN,
  minHeight: '68px',
  cursor: 'pointer',
};

export const sxListUserIcon = {
  color: COLORS.MAIN,
};

export const sxCloseMenuIcon = {
  color: COLORS.WHITE,
};
