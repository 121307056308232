/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { SearchData } from '../types/search';

export const getSearch = createAsyncThunk(
  'search/getSearch',
  async (searchPhrase: string, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.SEARCH}?query=${searchPhrase}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.searchStatus = 'loading';
  state.searchError = null;
}

const setResolved = (state: any, action: any) => {
  state.searchStatus = 'resolved';
  state.search = action.payload;
}

const setError = (state: any, action: any) => {
  state.searchStatus = 'rejected';
  state.searchError = action.payload;
}

const initialState: SearchData = {
  search: [],
  searchPhrase: null,
  searchStatus: null,
  searchError: null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSearchPhrase: (state, action) => {
      state.searchPhrase = action.payload;
    },
    setSearchError: (state, action) => {
      state.searchError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearch.pending, setLoading)
      .addCase(getSearch.fulfilled, setResolved)
      .addCase(getSearch.rejected, setError);
  },
});

export const { setSearch, setSearchPhrase, setSearchError } = searchSlice.actions;

export const getSearchPhrase = (state: MyStore) =>
  state[searchSlice.name].searchPhrase;
export const getSearchData = (state: MyStore) =>
  state[searchSlice.name].search;
export const getSearchError = (state: MyStore) =>
  state[searchSlice.name].searchError;

export default searchSlice;
