import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { sxActiveListItemMenu, sxListItemMenu } from './styles';
import { LOCAL_STORAGE } from '@/constants';
import { AppMenuItemData } from '../AppBarMenuListItems/types';

const AppBarMenuItem = ({ icon, target, title, disabled }: AppMenuItemData) => {
  const navigation = useNavigate();
  const isActiveMenuItem =
    localStorage.getItem(LOCAL_STORAGE.ACTIVE_MENU_ITEM) === title
      ? sxActiveListItemMenu
      : sxListItemMenu;

  return (
    <ListItemButton
      disabled={disabled}
      sx={isActiveMenuItem}
      onClick={() => {
        localStorage.setItem(LOCAL_STORAGE.ACTIVE_MENU_ITEM, title);
        navigation(target);
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export default AppBarMenuItem;
