import React from 'react';
import { trackerPackagesSchema } from './constants/schema';
import { Field, Form, Formik } from 'formik';
import FormSelect from '@/components/ui/FormSelect/FormSelect';
import AcceptBlock from '@/components/_common/AcceptBlock';
import { OptionsData } from '@/components/ui/SelectComponent/SelectComponent';
import { CategoryData } from '@/redux/types/category';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { getSelectedCategory, setSelectedCategory } from '@/redux/slices/categoriesSlice';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { Grid } from '@mui/material';
import { TrackerServicePackage } from '@/redux/types/tracker';
import { getAppPagination } from '@/redux/slices/PaginationSlice';
import { editTracker, getSelectedTracker, getTracker, getTrackers } from '@/redux/slices/trackerSlice';
import { useNavigate } from 'react-router-dom';

interface TrackerPackageFormProps {
  categories: CategoryData[];
  setIsOpen: (arg: boolean) => void;
  trackerDate: string;
}

const TimeTrackerPackageForm: React.FC<TrackerPackageFormProps> = ({
  categories,
  setIsOpen,
  trackerDate,
}) => {
  const selectedCategory = useAppSelector(getSelectedCategory);
  const tracker = useAppSelector(getSelectedTracker);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const { page, limit } = useAppSelector(getAppPagination);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackerError } = useAppSelector(state => state.tracker);

  const onSubmit = async (values: TrackerServicePackage) => {
    if (tracker && unitId) {
      await dispatch(editTracker({ unitId, body: {
        id: tracker.id,
        serviceId: values.serviceIds,
        overwriteTotal: true,
        trackerDate: trackerDate,
      }}));
      dispatch(setSelectedCategory(null));
      await dispatch(getTrackers({ page, limit }));
      await dispatch(getTracker(tracker.id));
      setIsOpen(false);
    }
  };

  const categoryOptions: OptionsData[] = categories.map(item => ({
    label: item.name,
    value: item.id,
    services: item.services,
  }));
  
  const serviceOptions: OptionsData[] = selectedCategory?.services?.map(item => ({
    label: item.name,
    value: item.id,
  })) || [];

  const initialValues: TrackerServicePackage = {
    category: '',
    serviceIds: '',
  };

  if (trackerError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Formik
      validationSchema={trackerPackagesSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isValid,
        values,
      }) => {
        const isCategorySelected = !!values.category;
        
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems='center' marginBottom='200px'>
              <Grid item xs={6}>
                <Field
                  name='category'
                  placeholder='Оберіть категорію'
                  component={FormSelect}
                  options={categoryOptions}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  visibility: isCategorySelected ? 'visible' : 'hidden',
                  pointerEvents: isCategorySelected ? 'auto' : 'none',
                }}
              >
                <Field
                  name='serviceIds'
                  placeholder='Оберіть послугу'
                  component={FormSelect}
                  options={serviceOptions}
                />
              </Grid>
            </Grid>
            <AcceptBlock
              variant='contained'
              disabled={!isValid}
              handleAgree={handleSubmit}
              handleDisagree={() => setIsOpen(false)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default TimeTrackerPackageForm;
