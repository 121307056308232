import { Table } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { sxTableContainer, sxTableRow, sxTableRowMobile } from './styles';
import { TableContent } from './types';

interface BasicTableProps {
  content: TableContent[];
}

const BasicTable: React.FC<BasicTableProps> = ({ content }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  return (
    <TableContainer sx={sxTableContainer} component={Paper}>
      <Table>
        <TableBody>
          {content.map(({ title, status }, index) => {
            return (
              <TableRow key={index} sx={isMobile ? sxTableRowMobile : sxTableRow}>
                <TableCell component='th' scope='row'>
                  {title}
                </TableCell>
                <TableCell align='right'>{status}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
