import { Box } from '@mui/material';
import React, { useState } from 'react';
import { sxContainer } from './styles';
import { UnitUserData } from '@/redux/types/unitUsers';
import UnitUserForm from '@/forms/Forms/UnitUserForm';
import UserInformation from '../UserInformation';
import { sxCardWrapper, sxListItem } from '../../Services/ServiceItem/styles';
import ModalComponent from '../../../_common/ModalComponent';
import Button from '../../../_common/Button';
import { useMediaQuery } from 'react-responsive';

interface UserCardProps {
  unitUser: UnitUserData;
}

const UserCard: React.FC<UserCardProps> = ({ unitUser }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box sx={isMobile ? {} : sxCardWrapper}>
      <Button
        fullWidth
        sx={sxListItem(false)}
        disableRipple
        buttonTitle={unitUser.name}
      />
      <Box sx={sxContainer}>
        <ModalComponent
          isOpen={isEdit}
          setClose={() => setIsEdit(false)}
          title='Редагування користувача'
        >
          <UnitUserForm isEditForm handleDisagree={setIsEdit} />
        </ModalComponent>
        <UserInformation unitUser={unitUser} isEdit={isEdit} setEdit={setIsEdit} />
      </Box>
    </Box>
  );
};

export default UserCard;
