import { Typography } from '@mui/material';
import React from 'react';

import { sxText } from './styles';

interface TextComponentProps {
  children: string;
  textAlign?: 'left' | 'center' | 'right';
}

const TextComponent: React.FC<TextComponentProps> = ({
  children,
  textAlign = 'left',
}) => {
  return (
    <Typography textAlign={textAlign} sx={sxText}>
      {children}
    </Typography>
  );
};

export default TextComponent;
