import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { auth } from '@/modules/config';
import Spinner from '@/components/_common/Spinner';

interface ProtectedRoutesProps {
  children: JSX.Element;
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ children }) => {
  const [initializing, setInitializing] = useState(true);
  const [listenUser, setListenUser] = useState(false);
  const location = useLocation();
  const [user, setUser] = useState<unknown>(null);
  const { pathname: authTarget, search } = location;

  useEffect(() => {
    const authListener = auth.onAuthStateChanged((result) => {
      setUser(result);
      if (initializing && !listenUser) {
        setInitializing(false);
        setListenUser(true);
      }
    });

    return () => {
      if (authListener) {
        authListener();
      }
    };
  }, [initializing, listenUser]);

  const to = `${ROUTES.LOGIN}?target=${`${authTarget}${search}`}`;

  if (initializing) {
    return <Spinner />;
  } else {
    return !user ? <Navigate to={to} replace /> : children;
  }
};

export default ProtectedRoutes;
