import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  sxButtonIcon,
  sxSearchAndSelectorBox,
  sxSearchButtonIcon,
  sxSearch,
  sxSearchTypeIcon,
  sxTableСontainer,
  sxTypeIcon,
  sxResultTableCell,
  sxSearchBox,
  sxSearchNotFound,
  sxSearchMobile,
  sxSearchButtonIconMobile,
  sxTableСell
} from './styles';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { getSearch, getSearchData, getSearchPhrase, setSearchPhrase } from '@/redux/slices/SearchSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { SearchType } from '@/redux/types/search';
import { Groups, Key, SupervisedUserCircle, Work } from '@mui/icons-material';
import Button from '../../_common/Button';
import { useMediaQuery } from 'react-responsive';

const Search: React.FC = () => {
  const [newSearchQuery, setNewSearchQuery] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchPhrase: string = useAppSelector(getSearchPhrase) || '';
  const searchArray = useAppSelector(getSearchData);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { searchError } = useAppSelector(state => state.search);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchQuery = event.target.value;
    setNewSearchQuery(newSearchQuery);
    dispatch(setSearchPhrase(newSearchQuery));
  };

  const handleSearchSubmit = () => {
    dispatch(getSearch(newSearchQuery));
    setSearchPerformed(true);
  };

  if (searchError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={sxSearchAndSelectorBox}>
      <Box sx={sxSearchBox}>
        <TextField
          inputRef={inputRef}
          value={searchPhrase}
          onChange={handleSearchChange}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              handleSearchSubmit();
            }
          }}
          sx={isMobile ? sxSearchMobile : sxSearch}
          placeholder='Введіть значення...'
        />
        <Button
          variant='contained'
          title='Пошук'
          startIcon={<SearchIcon sx={isMobile ? sxSearchButtonIconMobile : sxSearchButtonIcon} />}
          onClick={handleSearchSubmit}
          disabled={!searchPhrase}
        />
      </Box>
      
      {searchArray && searchArray.length > 0 ? (
        <TableContainer sx={sxTableСontainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={sxTableСell}>
                  Тип
                </TableCell>
                <TableCell sx={isMobile ? {} : sxResultTableCell}>
                  Результат
                </TableCell>
                <TableCell align='center' sx={sxTableСell}>
                  Перейти до
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchArray.map((item, index) => {
                const valuesArray = Object.values(JSON.parse(item.entityBody))
                  .filter((value) => typeof value === 'string' || typeof value === 'number');
              
                const groupSize = 3;
              
                const groups = [];
                for (let i = 0; i < valuesArray.length; i += groupSize) {
                  const group = valuesArray.slice(i, i + groupSize).join(', ');
                  groups.push(group);
                }

                console.log('item:', item)
              
                return (
                  <TableRow key={index}>
                    <TableCell sx={sxTableСell}>
                      <Box sx={sxSearchTypeIcon}>
                      {item.type === SearchType.Client && <Groups  sx={sxTypeIcon}/>}
                      {item.type === SearchType.CourtCase && <Work sx={sxTypeIcon}/>}
                      {item.type === SearchType.UnitUser && <SupervisedUserCircle sx={sxTypeIcon}/>}
                      {item.type === SearchType.UnitService && <Key sx={sxTypeIcon}/>}
                      </Box>
                    </TableCell>
                    <TableCell sx={isMobile ? { minWidth: '150px' } : sxResultTableCell}>
                      {groups.map((group, index) => (
                        <Box key={index}>
                          {group}
                        </Box>
                      ))}
                    </TableCell>
                    <TableCell sx={sxTableСell}>
                      <Button
                        variant='contained'
                        title='Перейти до'
                        startIcon={<ArrowForwardIcon sx={sxButtonIcon}/>}
                        onClick={() => {
                          localStorage.setItem(LOCAL_STORAGE.SEARCH_ENTITY_ID, item.entityId);
                          if (item.type === SearchType.Client) {
                            navigate(`${ROUTES.CLIENTS}/${item.entityId}`);
                          }
                          // if (item.type === SearchType.CourtCase) {
                          //   navigate(`${ROUTES.CASE}?caseNumber=${item.entityId}`);
                          // }
                          if (item.type === SearchType.UnitUser) {
                            navigate(`${ROUTES.USERS}/${item.entityId}`);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )              
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        searchPerformed && searchArray && searchArray.length === 0 ? (
          <Typography sx={sxSearchNotFound}>
            Дані не знайдено
          </Typography>
        ) : ''
      )}
    </Box>
  );
};

export default Search;
