import { Container } from '@mui/material';
import React from 'react';

import { sxCenterContainer } from '@/assets/globalStyles/styles';
import Title from '@/components/_common/Title';
import { authInitialValues } from '@/forms/Forms/constants/initialValues';
import { authSchema, authSchemaMobile } from '@/forms/Forms/constants/schema';
import RegistrationForm from '@/forms/Forms/RegistrationForm';
import { Desktop, Mobile } from '@/utils/mediaQuery';

const RegistrationPage: React.FC = () => {
  return (
    <Container sx={sxCenterContainer}>
      <Title title='Реєстрація' />
      <Desktop>
        <RegistrationForm initialValues={authInitialValues} schema={authSchema} />
      </Desktop>
      
      <Mobile>
        <RegistrationForm initialValues={authInitialValues} schema={authSchemaMobile} />
      </Mobile>
    </Container>
  );
};

export default RegistrationPage;
