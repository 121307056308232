import { Home, ReportGmailerrorred } from '@mui/icons-material';
import { Container } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  sxNotFoundBtn,
  sxNotFoundIcon,
  sxNotFoundWrapper,
} from '@/assets/_shared/styles';
import Button from '@/components/_common/Button';
import Title from '@/components/_common/Title';
import { ROUTES } from '@/constants';

const SelectOrganizationPage = () => {
  const navigate = useNavigate();

  return (
    <Container sx={sxNotFoundWrapper}>
      <Title title='Отримайте запрошення до організації' />
      <ReportGmailerrorred color='warning' sx={sxNotFoundIcon} />
      <Button
        sx={sxNotFoundBtn}
        startIcon={<Home />}
        variant='contained'
        onClick={() => navigate(ROUTES.LOGIN)}
        buttonTitle='Сторінка логіна'
      />
    </Container>
  );
};

export default SelectOrganizationPage;
