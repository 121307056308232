import shape from './svg/shape.svg';
import books from './svg/books.svg';
import doc from './svg/doc.svg';

const SVG_IMAGES = {
  SHAPE: shape,
  BOOKS: books,
  DOC: doc,
};

export default SVG_IMAGES;
