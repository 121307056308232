import { COLORS } from '@/assets/сolors/colors';

export const sxAppBarWrapper = {
  background: COLORS.MAIN,
};

export const sxTrackerWrapper = {
  maxWidth: '260px',
  background: COLORS.MAIN_SECONDARY,
  ml: '24px',
  cursor: 'pointer',
};

export const sxAppBarContent = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export const sxAddClientBtn = {
  minWidth: '240px',
  mb: '24px',
};

export const sxMenuIcon = {
  mr: '24px',
};

export const sxMenuIconMobile = {
  padding: '0px',
};

export const sxUser = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};