import React from 'react';
import { Avatar, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import IMG_IMAGES from '@/assets/images/img';
import { sxListUserIcon } from '../AppBarMenuListItems/styles';
import { useAuth } from '@/hooks/useAuth';
import useSockets from '@/hooks/useSockets';

const AppUserMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { logOut } = useAuth();
  const { handleTestApproveSockets, handleTestPauseSockets } = useSockets();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAllowNotifications = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Уведомления разрешены');
        } 
        else if (permission === 'denied') {
          console.log('Уведомления запрещены');
        }
        else {
          console.log('Дефолтное значение');
        }
      });
    }
  };

  return (
    <>
      <IconButton
        size='large'
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <Avatar src={IMG_IMAGES.AVATAR} sx={sxListUserIcon} />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        sx={{ marginTop: '40px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleAllowNotifications}>Дозволити демонстрацію сповіщення</MenuItem>
        <MenuItem onClick={handleTestApproveSockets}>Test sockets - APPROVE_TRACK</MenuItem>
        <MenuItem onClick={handleTestPauseSockets}>Test sockets - TRACK_STOPPED</MenuItem>
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary='Вихід' />
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppUserMenu;
