import {
  Done,
  DoNotDisturb,
  Share,
  WorkOffOutlined,
} from '@mui/icons-material';
import { Avatar, Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import IMG_IMAGES from '@/assets/images/img';
import { UnitUserData, UnitUserStatus } from '@/redux/types/unitUsers';
import { createData } from '@/utils/createData';
import { statusCheck } from '@/utils/renderUnitInfo';
import BasicTable from '../../../_common/BasicTable';
import EditButton from '../../../_common/EditButton';
import TextComponent from '../../../_common/TextComponent';
import {
  sxHeaderWrapper,
  sxImage,
  sxImageWrapper,
  sxStatusWrapper,
  sxWrapper,
} from '../UserCard/styles';
import ModalComponent from '../../../_common/ModalComponent';
import UserInvitaion from '../UserInvitaion';
import { useNavigate } from 'react-router-dom';
import { rootActions, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { editUnitUser, getUnitUser } from '../../../../redux/slices/unitUsersSlice';
import { ROUTES } from '../../../../constants';
import { postInvite } from '../../../../redux/slices/invitationSlice';
import Tooltip from '@/components/_common/Tooltip';
import { getModalComponentStatus } from '@/redux/slices/modalComponentSlice';
import useActionCreators from '@/hooks/useActionCreators';

interface UserInformationProps {
  unitUser: UnitUserData;
  isEdit: boolean;
  setEdit: (arg: boolean) => void;
}

const UserInformation: React.FC<UserInformationProps> = ({
  unitUser,
  isEdit,
  setEdit,
}) => {
  const [inviteLink, setInviteLink] = useState<string>('');
  const { phone, invite, email, specialization, status, id, category, image } = unitUser;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { unitUserError } = useAppSelector(state => state.unitUsers);
  const { invitationError } = useAppSelector(state => state.invitations);
  const actions = useActionCreators(rootActions);
  const modalComponentStatus = useAppSelector(getModalComponentStatus);

  const unitInformation = [
    createData('Телефон', phone),
    createData('Email', email),
    createData('Категорія', category),
    createData('Спеціалізація', specialization),
    createData('Статус', statusCheck(status) as JSX.Element),
  ];
  const avatar = image ? image : IMG_IMAGES.AVATAR;

  const changeStatusHandler = async (status: string) => {
    await dispatch(editUnitUser({ id, body: {
      ...unitUser,
      status,
      services: [],
    }}));
    await dispatch(getUnitUser(id));
  };

  const sendInviteHandler = async () => {
    actions.setModalComponentStatus(true);
    await dispatch(postInvite(id));
    await dispatch(getUnitUser(id));
  };

  useEffect(() => {
    if (invite) {
      setInviteLink(invite.link);
    }
  }, [invite]);

  if (unitUserError || invitationError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={sxWrapper} >
      <Box sx={sxHeaderWrapper}>
        <Box sx={sxStatusWrapper}>
          <TextComponent textAlign='center'>Дії з користувачем</TextComponent>
          <Box display='flex'>
            {unitUser.status === UnitUserStatus.Active && (
              <>
                <Tooltip title='Заблокувати'>
                  <IconButton onClick={() => changeStatusHandler('blocked')}>
                    <DoNotDisturb color='warning' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Звільнити'>
                  <IconButton onClick={() => changeStatusHandler('fired')}>
                    <WorkOffOutlined color='error' />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {unitUser.status === UnitUserStatus.Blocked && (
              <>
                <Tooltip title='Активувати'>
                  <IconButton onClick={() => changeStatusHandler('active')}>
                    <Done color='success' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Звільнити'>
                  <IconButton onClick={() => changeStatusHandler('fired')}>
                    <WorkOffOutlined color='error' />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {unitUser.status === UnitUserStatus.Fired && (
              <>
                <Tooltip title='Активувати'>
                  <IconButton onClick={() => changeStatusHandler('active')}>
                    <Done color='success' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Заблокувати'>
                  <IconButton onClick={() => changeStatusHandler('blocked')}>
                    <DoNotDisturb color='warning' />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title='Відправити запрошення'>
              <span>
                <IconButton
                  onClick={sendInviteHandler}
                  disabled={Boolean(unitUser.userId)}
                >  
                  <Share color='secondary' />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={sxImageWrapper}>
          <Avatar alt='#' src={avatar} sx={sxImage} />
          <EditButton
            editTitle='Редагувати'
            editHandler={() => setEdit(!isEdit)}
            editIsActive={isEdit}
          />
        </Box>
      </Box>
      <BasicTable content={unitInformation} />

      <ModalComponent
        isOpen={modalComponentStatus}
        setClose={() => actions.setModalComponentStatus(false)}
        title='Посилання для запрошення'
      >
        <UserInvitaion link={inviteLink} />
      </ModalComponent>
    </Box>
  );
};

export default UserInformation;
