import { Check, Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';

import { sxAgreeIcon, sxDeleteIcon, sxWrapper } from './styles';
import Button from '../Button';

interface AcceptBlockProps {
  handleAgree: () => void;
  handleDisagree: () => void;
  disabled: boolean;
  variant: 'outlined' | 'contained';
}

const AcceptBlock: React.FC<AcceptBlockProps> = ({
  handleAgree,
  handleDisagree,
  disabled,
  variant,
}) => {
  return (
    <Box sx={sxWrapper}>
      <Button
        variant={variant}
        sx={sxDeleteIcon}
        onClick={handleDisagree}
        startIcon={<Close color='info' />}
      />
      <Button
        variant={variant}
        disabled={disabled}
        sx={sxAgreeIcon}
        onClick={handleAgree}
        startIcon={<Check color='info' />}
      />
    </Box>
  );
};

export default AcceptBlock;
