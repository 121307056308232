import { COLORS } from '@/assets/сolors/colors';

export const sxListItemMenu = () => {
  return {
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.DARK,
      backgroundColor: COLORS.DISABLED,
    },
  };
};

export const sxActiveListItemMenu = () => {
  return {
    width: '100%',
    cursor: 'pointer',
    backgroundColor: COLORS.MAIN,
    color: COLORS.WHITE,
    svg: {
      color: COLORS.WHITE,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.MAIN,
      color: COLORS.WHITE,
    },
    ':hover': {
      backgroundColor: COLORS.MAIN,
      color: COLORS.WHITE,
    },
  };
};
