import { Delete, Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '../Button';
import {
  sxDeleteIcon,
  sxDeleteIconMobile,
  sxEditIcon,
  sxWrapper,
  sxWrapperMobile
} from './styles';

interface EditBlockProps {
  setEditVisible: (arg: boolean) => void;
  setOpenModal: (arg: boolean) => void;
  isActive: boolean;
}

const EditBlock: React.FC<EditBlockProps> = ({
  setEditVisible,
  setOpenModal,
  isActive,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box sx={isMobile ? sxWrapperMobile : sxWrapper}>
      <Button
        sx={sxEditIcon(isActive)}
        onClick={() => setEditVisible(true)}
        startIcon={<Edit />}
      />
      <Button
        sx={isMobile ? sxDeleteIconMobile : sxDeleteIcon}
        onClick={() => setOpenModal(true)}
        startIcon={<Delete />}
      />
    </Box>
  );
};

export default EditBlock;
