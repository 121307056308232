import { Box } from '@mui/material';
import React, { useState } from 'react';
import {
  sxDescription,
  sxListItem,
  sxListItemWrapper,
  sxPriceWrapper,
  sxWrapper,
  sxWrapperActive,
} from './styles';
import { useAppSelector } from '@/redux/store';
import { CategoryData } from '@/redux/types/category';
import { ServiceData } from '@/redux/types/service';
import EditBlock from '../../../_common/EditBlock';
import TextComponent from '../../../_common/TextComponent';
import WarningModal from '../../../_common/WarningModal';
import CategoryForm from '@/forms/Forms/CategoryForm';
import ServiceForm from '@/forms/Forms/ServiceForm';
import ModalComponent from '../../../_common/ModalComponent';
import Button from '../../../_common/Button';
import { getSelectedCategory } from '@/redux/slices/categoriesSlice';

interface ServiceItemProps {
  selectHandler: (arg: CategoryData | ServiceData) => void;
  category: CategoryData | ServiceData;
  deleteHandler?: (id: string) => void;
  description?: string;
  duration?: number;
  price?: number;
  selectable?: boolean;
  otherEditProps?: boolean;
}

const ServiceItem: React.FC<ServiceItemProps> = ({
  selectHandler,
  category,
  deleteHandler,
  duration,
  price,
  description,
  selectable,
  otherEditProps,
}) => {
  const selectedCategory = useAppSelector(getSelectedCategory);
  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const serviceDuration = `Тривалість: ${duration} хв`;
  const servicePrice = `Ціна: ${price} ₴`;
  const { id, name } = category;
  const selected = category.id === selectedCategory?.id;
  const renderEditForm = () => {
    if (otherEditProps) {
      return (
        <ModalComponent
          isOpen={editVisible}
          setClose={() => setEditVisible(false)}
          title='Введіть нову назву сервісу'
        >
          <ServiceForm
            setInputVisible={setEditVisible}
            isEditForm
            serviceId={id}
            category={selectedCategory}
          />
        </ModalComponent>
      );
    } else {
      return (
        <ModalComponent
          isOpen={editVisible}
          setClose={() => setEditVisible(false)}
          title='Введіть нову назву категорії'
        >
          <CategoryForm
            setInputVisible={setEditVisible}
            isEditForm
            categoryId={id}
          />
        </ModalComponent>
      );
    }
  };

  return (
    <Box sx={selected ? sxWrapperActive : sxWrapper}>
      <Box
        sx={sxListItemWrapper}
        onClick={() => selectable && selectHandler(category)}
      >
        {editVisible ? (
          <>
            {renderEditForm()}
            <Button
              sx={sxListItem(selected)}
              fullWidth
              disableRipple
              buttonTitle={name}
            />
          </>
        ) : (
          <Button
            sx={sxListItem(selected)}
            fullWidth
            disableRipple
            buttonTitle={name}
          />
        )}
        <EditBlock
          setEditVisible={setEditVisible}
          setOpenModal={setOpenWarningModal}
          isActive={selected}
        />
      </Box>
      <Box sx={sxDescription}>
        {description && <TextComponent>{description}</TextComponent>}
        <Box sx={sxPriceWrapper}>
          {duration && <TextComponent>{serviceDuration}</TextComponent>}
          {price && <TextComponent>{servicePrice}</TextComponent>}
        </Box>
      </Box>
      <WarningModal
        description={name}
        open={openWarningModal}
        handleAgree={() => deleteHandler && deleteHandler(id)}
        handleClose={() => setOpenWarningModal(false)}
      />
    </Box>
  );
};

export default ServiceItem;
