import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { rootActions, useAppSelector } from '@/redux/store';
import Button from '../../../_common/Button';
import { sxCopyLinkBtn, sxInvitationLinkBox, sxInviteBox } from './styles';
import Tooltip from '@/components/_common/Tooltip';
import useActionCreators from '@/hooks/useActionCreators';

interface UserInvitationProps {
  link: string;
}

const UserInvitation: React.FC<UserInvitationProps> = ({ link }) => {
  const [copied, setCopied] = useState(false);
  const [currentLink, setCurrentLink] = useState<string>('');
  const { unitUserStatus } = useAppSelector(state => state.unitUsers);
  const { invitationStatus } = useAppSelector(state => state.invitations);
  const actions = useActionCreators(rootActions);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500)
  };

  useEffect(() => {
    if (link) {
      setCurrentLink(link);
      actions.setModalComponentStatus(true);
    }
  }, [link]);

  return (
    <Box sx={sxInviteBox}>
      <TextField
        sx={sxInvitationLinkBox}
        value={unitUserStatus === 'loading' || invitationStatus === 'loading' ? '' : currentLink}
        InputProps={{
          readOnly: true,
        }}
        onClick={copyToClipboard}
      />
        <Tooltip title='Скопійовано' open={copied}>
        <Button
          variant='contained'
          sx={sxCopyLinkBtn}
          onClick={copyToClipboard}
          disabled={unitUserStatus === 'loading' || invitationStatus === 'loading'}
          buttonTitle='Скопіювати'
        />
        </Tooltip>
    </Box>
  );
};

export default UserInvitation;
