/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE } from '@/constants';
import { SelectedUser } from '../types/user';
import { MyStore } from '../store';

const initialState: SelectedUser = {
  user: null,
  selectedOrganization: [],
  selectedOrganizationUnit: null,
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSelectedUserOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setSelectedUserOrganizationUnit: (state, action) => {
      state.selectedOrganizationUnit = action.payload;
    },
    removeUser: () => {
      localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE.ACTIVE_MENU_ITEM);
      localStorage.removeItem(LOCAL_STORAGE.USER);
      localStorage.removeItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
      localStorage.removeItem(LOCAL_STORAGE.SEARCH_ENTITY_ID);
      localStorage.removeItem(LOCAL_STORAGE.RUNNING);

      return initialState;
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export const getCurrentUser = (state: MyStore) => state[userSlice.name];

export default userSlice;
