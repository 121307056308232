import { COLORS } from '@/assets/сolors/colors';

export const sxCloseIcon = {
  position: 'absolute' as const,
  top: '0px',
  right: '0px',
};

export const sxModalWrapper = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: COLORS.WHITE,
  borderRadius: '4px',
  minWidth: '720px',
  margin: '0 auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  boxShadow: 12,
  boxSizing: 'border-box',
  p: 3,
  outline: 'none',
};

export const sxModalWrapperMobile = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: COLORS.WHITE,
  width: '100%',
  height: '100vh',
  boxSizing: 'border-box',
  p: 3,
  outline: 'none',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
};
