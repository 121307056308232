/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { ClientServicePackage } from '../types/client';
import { ServiceRequest, Services } from '../types/service';
import { setSuccess } from './notificationSlice';

export const postServices = createAsyncThunk(
  'services/postServices',
  async ({ unitId, body }: { unitId: string, body: ServiceRequest }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.UNIT_SERVICES}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні сервіса!');
      }

      dispatch(setSuccess('Сервіс створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const postServicePackages = createAsyncThunk(
  'services/postServicePackages',
  async ({ unitId, clientId, body }: { unitId: string, clientId: string, body: ClientServicePackage }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}/clients/${clientId}${API_ENDPOINTS.SERVICE_PACKAGES}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні пакета послуг!');
      }

      dispatch(setSuccess('Послуги додано успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getServices = createAsyncThunk(
  'services/getServices',
  async (unitId: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.UNIT_SERVICES}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку сервісів!');
      }

      dispatch(setServices(res));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getServicePackages = createAsyncThunk(
  'services/getServicePackages',
  async ({ unitId, clientId }: { unitId: string, clientId: string }, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}/clients/${clientId}${API_ENDPOINTS.SERVICE_PACKAGES}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку пакета послуг!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const editService = createAsyncThunk(
  'services/editService',
  async ({ unitId, serviceId, body }: {     unitId: string, serviceId: string, body: ServiceRequest }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.UNIT_SERVICES}/${serviceId}`;
      const res = await Http.patch(url, body);

      if (res) {
        dispatch(setSuccess('Сервіс змінено'));
        return res;
      } else {
        throw new Error('Немає відповіді з сервера при редагуванні сервісу!');
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteService = createAsyncThunk(
  'services/deleteService',
  async ({ unitId, serviceId }: { unitId: string, serviceId: string }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.UNIT_SERVICES}/${serviceId}`;
      await Http.delete(url);

      dispatch(setSuccess('Сервіс видалено'));
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.serviceStatus = 'loading';
  state.serviceError = null;
}

const setResolved = (state: any) => {
  state.serviceStatus = 'resolved';
}

const setError = (state: any, action: any) => {
  state.serviceStatus = 'rejected';
  state.serviceError = action.payload;
}

const initialState: Services = {
  data: [],
  pagination: null,
  selectedService: null,
  serviceStatus: null,
  serviceError: null,
};
const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    setSelectedServices: (state, action) => {
      state.selectedService = action.payload;
    },
    setServiceError: (state, action) => {
      state.serviceError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postServices.pending, setLoading)
      .addCase(postServicePackages.pending, setLoading)
      .addCase(getServices.pending, setLoading)
      .addCase(getServicePackages.pending, setLoading)
      .addCase(editService.pending, setLoading)
      .addCase(deleteService.pending, setLoading)
      .addCase(postServices.fulfilled, setResolved)
      .addCase(postServicePackages.fulfilled, setResolved)
      .addCase(getServices.fulfilled, setResolved)
      .addCase(getServicePackages.fulfilled, setResolved)
      .addCase(editService.fulfilled, setResolved)
      .addCase(deleteService.fulfilled, setResolved)
      .addCase(postServices.rejected, setError)
      .addCase(postServicePackages.rejected, setError)
      .addCase(getServices.rejected, setError)
      .addCase(getServicePackages.rejected, setError)
      .addCase(editService.rejected, setError)
      .addCase(deleteService.rejected, setError);
  },
});

export const { setServices, setSelectedServices, setServiceError } = servicesSlice.actions;

export const getCurrentServices = (state: MyStore) => state[servicesSlice.name].data;
export const getSelectedServices = (state: MyStore) => state[servicesSlice.name].selectedService;
export const getServiceError = (state: MyStore) => state[servicesSlice.name].serviceError;

export default servicesSlice;
