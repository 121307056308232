import { COLORS } from '@/assets/сolors/colors';

export const sxLawyerSelectorBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent:'center',
  flexDirection: 'column',
};

export const sxConfirmBtn = {
  marginTop: '15px',
  width: '28%',
};

export const sxSelectLawyer = {
  width: '100%',
};

export const sxTextStyles = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
};
