import React, { useState } from 'react';
import {
  Box,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';

import { Add, Delete, Edit } from '@mui/icons-material';
import WarningModal from '../../../_common/WarningModal';
import { 
  sxButtonIcon,
  sxDeleteItem,
  sxRemoveLine,
} from '../../Bills/BillMenu/styles';
import moment from 'moment';
import { Tracker } from '@/redux/types/tracker';
import { sxEditItem } from '../TimeTrackerAccordionDetails.tsx/styles';
import { formatTime } from '@/utils/formatSecondsToTime';
import { sxEditDateTimePickerColumn, sxIntervalModal, sxTableScroll, sxTimeColumn } from './styles';
import TrackerIntervalEditForm from '@/forms/Forms/TrackerIntervalEditForm';
import TrackerIntervalCreateForm from '@/forms/Forms/TrackerIntervalCreateForm';
import Spinner from '../../../_common/Spinner';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import Button from '../../../_common/Button';
import { useNavigate } from 'react-router-dom';
import { deleteTrackerInterval, getTracker, getTrackers } from '@/redux/slices/trackerSlice';
import { ROUTES } from '@/constants';

interface Props {
  tracker: Tracker;
}

const TrackerIntervals: React.FC<Props> = ({ tracker }) => {
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [isNewIntervalAdded, setIsNewIntervalAdded] = useState<boolean>(false);
  const [isEditingInterval, setIsEditingInterval] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<string>('');
  const [editIntervalStates, setEditIntervalStates] = useState<{ [key: string]: boolean }>(
    tracker.intervals.reduce((acc, interval) => {
      acc[interval.id] = false;
      return acc;
    }, {} as { [key: string]: boolean })
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackerError, trackerStatus } = useAppSelector(state => state.tracker);

  const editIntervalHandler = (intervalId: string) => {
    setEditIntervalStates(prevStates => ({
      ...prevStates,
      [intervalId]: !prevStates[intervalId]
    }));

    setIsEditingInterval(!isEditingInterval);
  };

  const deleteIntervalHandler = async () => {
    await dispatch(deleteTrackerInterval({ id: tracker.id, intervalId }));
    await dispatch(getTracker(tracker.id));
    await dispatch(getTrackers({}));

    setIsWarningOpen(false);
  };

  const handleAddInterval = () => {
    setIsNewIntervalAdded(!isNewIntervalAdded);
  };

  if (trackerError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={trackerStatus === 'loading' ? {...sxIntervalModal, pointerEvents: 'none'} : {...sxIntervalModal}}>
      {trackerStatus === 'loading' && <Spinner />}
      <TableContainer sx={sxTableScroll}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Інтервал</TableCell>
              <TableCell>Час інтервалу</TableCell>
              <TableCell sx={sxEditDateTimePickerColumn}>Дії</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tracker.intervals.map((interval) => {
                const intervalStartDate = moment(interval.startedAt).format('L');
                const intervalEndDate = moment(interval.finishedAt).format('L');

                const intervalStartTime = moment(interval.startedAt).format('HH:mm');
                const intervalEndTime = moment(interval.finishedAt).format('HH:mm');

              return (
                <TableRow key={interval.id}>
                  {editIntervalStates[interval.id] ? (
                    <TableCell sx={sxEditDateTimePickerColumn} colSpan={2}>
                      <TrackerIntervalEditForm
                        setIsOpen={() => editIntervalHandler(interval.id)}
                        interval={interval}
                      />
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>
                        <>
                          {intervalStartDate} - {intervalEndDate}
                        </>
                      </TableCell>
                      <TableCell>{intervalStartTime} - {intervalEndTime}</TableCell>
                    </>
                  )}
                  <TableCell sx={sxTimeColumn}>{formatTime(interval.timeSeconds)}</TableCell>
                  <TableCell>
                    <Box display='flex'>
                      <Button
                        variant='contained'
                        title='Редагувати інтервал'
                        sx={sxEditItem}
                        onClick={() => editIntervalHandler(interval.id)}
                        startIcon={<Edit sx={sxButtonIcon} />}
                        disabled={isEditingInterval || isNewIntervalAdded}
                      />
                      <Button
                        variant='contained'
                        title='Видалити інтервал'
                        sx={sxDeleteItem}
                        startIcon={<Delete sx={sxButtonIcon}/>}
                        onClick={() => {
                          setIsWarningOpen(true)
                          setIntervalId(interval.id)
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )              
            })}
            {isNewIntervalAdded && (
              <TableRow>
              <TableCell sx={sxEditDateTimePickerColumn}  colSpan={2}>
                <TrackerIntervalCreateForm
                  setIsOpen={handleAddInterval}
                />
              </TableCell>
              <TableCell colSpan={3} />
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={2} sx={sxRemoveLine} />
              <TableCell sx={sxRemoveLine}>
                {formatTime(tracker.timeSeconds)}
              </TableCell>
              <TableCell sx={sxRemoveLine}>
                <Button
                  variant='contained'
                  title='Додати інтервал'
                  startIcon={<Add sx={sxButtonIcon} />}
                  onClick={handleAddInterval}
                  disabled={isEditingInterval || isNewIntervalAdded}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <WarningModal
        title='Дійсно видалити інтервал?'
        open={isWarningOpen}
        handleAgree={deleteIntervalHandler}
        handleClose={() => setIsWarningOpen(false)}
      />
    </Box>
  );
};

export default TrackerIntervals;
