import React, { useEffect } from 'react';

import Title from '@/components/_common/Title';
import Main from '@/components/screens/Main';
import { Container } from '@mui/material';
import useUser from '@/hooks/useUser';
import { useAppSelector } from '@/redux/store';
import { getCurrentUser } from '@/redux/slices/userSlice';

const MainPage = () => {
  const { getUser } = useUser();
  const { user } = useAppSelector(getCurrentUser);
  
  useEffect(() => {
    getUser();
  }, []);

  return (
    <Container>
      <Title title='Головна' />
      <Main user={user} />
    </Container>
  );
};

export default MainPage;
