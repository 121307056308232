import { Box } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { categoryFields } from './constants/defaultData';
import { categoryInitialValues } from './constants/initialValues';
import { categorySchema, categorySchemaMobile } from './constants/schema';
import { sxForm } from './styles';
import { CategoryFormValues } from './types';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import AcceptBlock from '@/components/_common/AcceptBlock';
import BasicTextField from '@/components/_common/BasicTextField';
import { sxInputWrapper } from '@/components/_common/BasicTextField/styles';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { editCategory, getCategories, getSelectedCategory, postCategory } from '@/redux/slices/categoriesSlice';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface CategoryFormProps {
  setInputVisible?: (arg: boolean) => void;
  orderPosition?: number;
  isEditForm?: boolean;
  categoryId?: string;
}

const CategoryForm: React.FC<CategoryFormProps> = ({
  setInputVisible,
  orderPosition,
  isEditForm = false,
  categoryId,
}) => {
  const selectedCategory = useAppSelector(getSelectedCategory);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const dispatch = useAppDispatch();
  const { categoryError } = useAppSelector(state => state.categories);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onSubmit = async (
    values: CategoryFormValues,
    helpers: FormikHelpers<CategoryFormValues>
  ) => {
    helpers.setTouched({});
    helpers.setSubmitting(false);

    if (unitId) {
      if (setInputVisible && !isEditForm) {
        await dispatch(postCategory({unitId, body: { ...values }}));
        await dispatch(getCategories(unitId));
        setInputVisible(false);
      } else {
        if (categoryId) {
          await dispatch(editCategory({ unitId, categoryId, body: {
            ...values,
            orderPosition,
          }}))
        }
        await dispatch(getCategories(unitId));
        (setInputVisible && setInputVisible(false));
      }
    }
  };

  if (categoryError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Formik
      validationSchema={isMobile ? categorySchemaMobile : categorySchema}
      initialValues={isEditForm ? selectedCategory || categoryInitialValues : categoryInitialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleBlur,
        values,
        handleChange,
        touched,
        errors,
        isValid,
      }) => {
        return (
          <Form style={sxForm} onSubmit={handleSubmit}>
            {categoryFields.map((item, index) => {
              return (
                <Box sx={sxInputWrapper} key={index}>
                  <BasicTextField
                    errors={errors}
                    touched={touched}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    {...item}
                  />
                </Box>
              );
            })}
            <AcceptBlock
              variant='contained'
              disabled={!isValid}
              handleAgree={handleSubmit}
              handleDisagree={() => setInputVisible && setInputVisible(false)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CategoryForm;
