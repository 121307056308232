import React, { useState } from 'react';
import {
  Box,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
} from '@mui/material';

import { Invoice } from '@/redux/types/invoice';
import { Delete, Add } from '@mui/icons-material';
import WarningModal from '../../../_common/WarningModal';
import {
  sxButtonIcon,
  sxClient,
  sxDeleteItem,
  sxInvoiceHeader,
  sxInvoiceModal,
  sxInvoiceTerm,
  sxRemoveLine,
  sxStatus,
} from './styles';
import moment from 'moment';
import { ROUTES } from '@/constants';
import BillPackageForm from '@/forms/Forms/BillPackageForm';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { sxTableScroll } from '../../TimeTracker/TrackerIntervals/styles';
import Button from '../../../_common/Button';
import { deleteInvoiceService, getInvoice, getInvoices } from '@/redux/slices/invoiceSlice';
import { useNavigate } from 'react-router-dom';
import { getAllCategories } from '@/redux/slices/categoriesSlice';
import { getAppPagination } from '@/redux/slices/PaginationSlice';
import { Desktop, Mobile } from '@/utils/mediaQuery';

interface Props {
  invoice: Invoice | null;
}

const BillMenu: React.FC<Props> = ({ invoice }) => {
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
  const [isNewItemAdded, setIsNewItemAdded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { invoiceError } = useAppSelector(state => state.invoice);
  const navigate = useNavigate();
  const categories = useAppSelector(getAllCategories) || [];
  const { page, limit } = useAppSelector(getAppPagination);

  if (!invoice) {
    return null;
  }

  const invoiceStartDate = moment(invoice.billingPeriodStartDate).format('L');
  const invoiceEndDate = moment(invoice.billingPeriodEndDate).format('L');

  const deleteHandler = async () => {
    if (selectedServiceId) {
      await dispatch(deleteInvoiceService({ invoiceId: invoice.id, serviceId: selectedServiceId }));
      await dispatch(getInvoice(invoice?.id));
      await dispatch(getInvoices({ page, limit }));

      setIsWarningOpen(false);
      setSelectedServiceId(null);
    }
  };

  const handleAddService = () => {
    setIsNewItemAdded(true);
  };

  if (invoiceError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <>
      <Desktop>
        <Box sx={sxInvoiceModal}>
          {invoice && (
            <Box sx={sxInvoiceHeader}>
              <TextField
                label='Клiєнт'
                value={invoice.client.name}
                fullWidth
                color='success'
                disabled
                sx={sxClient}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label='Статус'
                value={invoice.status}
                fullWidth
                disabled
                sx={sxStatus}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label='Рахунок'
                value={`від ${invoiceStartDate} до ${invoiceEndDate}`}
                fullWidth
                disabled
                sx={sxInvoiceTerm}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          )}
          <TableContainer sx={sxTableScroll}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Категорія права + назва послуги</TableCell>
                  <TableCell>Години</TableCell>
                  <TableCell>Вартіть години</TableCell>
                  <TableCell>Загальна вартість</TableCell>
                  <TableCell>Дії</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.items.map((item, index) => {
                  const serviceId = item.serviceId;

                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ minWidth: '150px' }}>{item.service.serviceCategory.name} - {item.service.name}</TableCell>
                      <TableCell>{item.total}</TableCell>
                      <TableCell>{item.service.price}</TableCell>
                      <TableCell>{item.sum}</TableCell>
                      <TableCell>
                        <Button
                          disabled={invoice.status !== 'new'}
                          variant='contained'
                          title='Видалити послугу'
                          sx={sxDeleteItem}
                          startIcon={<Delete sx={sxButtonIcon}/>}
                          onClick={() => {
                            setSelectedServiceId(serviceId);
                            setIsWarningOpen(true)
                          }} 
                        />
                      </TableCell>
                    </TableRow>
                  )              
                })}
                {isNewItemAdded && (
                  <TableRow>
                  <TableCell colSpan={5}>
                  <BillPackageForm
                    categories={categories}
                    setIsOpen={setIsNewItemAdded}
                  />
                  </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={3} sx={sxRemoveLine} />
                  <TableCell sx={{ borderBottom: 'none' }}>
                    {invoice.totalSum}
                  </TableCell>
                  <TableCell  align='right' sx={sxRemoveLine}>
                    <Button
                      variant='contained'
                      title='Додати послугу'
                      startIcon={<Add sx={sxButtonIcon} />}
                      disabled={isNewItemAdded || invoice.status !== 'new'}
                      onClick={handleAddService}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <WarningModal
            title='Дійсно видалити послугу?'
            open={isWarningOpen}
            handleAgree={deleteHandler}
            handleClose={() => setIsWarningOpen(false)}
          />
        </Box>
      </Desktop>

      <Mobile>
        <Box sx={sxInvoiceModal}>
          {invoice && (
            <Box sx={sxInvoiceHeader}>
              <TextField
                label='Клiєнт'
                value={invoice.client.name}
                fullWidth
                color='success'
                disabled
                sx={sxClient}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label='Статус'
                value={invoice.status}
                fullWidth
                disabled
                sx={sxStatus}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label='Рахунок'
                value={`від ${invoiceStartDate} до ${invoiceEndDate}`}
                fullWidth
                disabled
                sx={sxInvoiceTerm}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          )}
          <TableContainer sx={sxTableScroll}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Категорія права + назва послуги</TableCell>
                  <TableCell>Години</TableCell>
                  <TableCell>Вартіть години</TableCell>
                  <TableCell>Загальна вартість</TableCell>
                  <TableCell>Дії</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.items.map((item, index) => {
                  const serviceId = item.serviceId;

                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ minWidth: '150px' }}>{item.service.serviceCategory.name} - {item.service.name}</TableCell>
                      <TableCell>{item.total}</TableCell>
                      <TableCell>{item.service.price}</TableCell>
                      <TableCell>{item.sum}</TableCell>
                      <TableCell>
                        <Button
                          disabled={invoice.status !== 'new'}
                          variant='contained'
                          title='Видалити послугу'
                          sx={sxDeleteItem}
                          startIcon={<Delete sx={sxButtonIcon}/>}
                          onClick={() => {
                            setSelectedServiceId(serviceId);
                            setIsWarningOpen(true)
                          }} 
                        />
                      </TableCell>
                    </TableRow>
                  )              
                })}
                {isNewItemAdded ? (
                  <>
                    <TableRow>
                    <TableCell colSpan={2}>
                    <BillPackageForm
                      categories={categories}
                      setIsOpen={setIsNewItemAdded}
                    />
                    </TableCell>
                    <TableCell colSpan={3}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3} align='center' sx={sxRemoveLine} />
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {invoice.totalSum}
                      </TableCell>
                      <TableCell sx={sxRemoveLine} />
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align='center' sx={sxRemoveLine}>
                      <Button
                        variant='contained'
                        title='Додати послугу'
                        startIcon={<Add sx={sxButtonIcon} />}
                        disabled={isNewItemAdded || invoice.status !== 'new'}
                        onClick={handleAddService}
                        buttonTitle='Додати послугу'
                      />
                    </TableCell>
                    <TableCell sx={sxRemoveLine} />
                    <TableCell sx={{ borderBottom: 'none' }}>
                      {invoice.totalSum}
                    </TableCell>
                    <TableCell sx={sxRemoveLine} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <WarningModal
            title='Дійсно видалити послугу?'
            open={isWarningOpen}
            handleAgree={deleteHandler}
            handleClose={() => setIsWarningOpen(false)}
          />
        </Box>
      </Mobile>
    </>
  );
};

export default BillMenu;
