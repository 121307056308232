import { COLORS } from '@/assets/сolors/colors';

export const sxAccordionSummary = {
  display: 'flex',
  padding: '12px 24px',
  width: '100%',
  minHeight: '56px',
};

export const sxAccordionSummaryMobile = {
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  width: '100%',
  minHeight: '56px',
};

export const sxAccordionContacts = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  ml: '32px',
  color: COLORS.GRAY,
};

export const sxAccordionContactsMobile = {
  marginLeft: '5px',
  color: COLORS.GRAY,
};

export const sxWarning = {
  color: COLORS.DELETE_LIGHT,
};

export const sxLawyerButton = {
  display: 'inline-flex',
  alignItems: 'center',
  background: 'none',
  width: 'auto',
  boxShadow: 'none',
  padding: '3px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}

export const sxLawyerButtonMobile = {
  display: 'inline-flex',
  alignItems: 'center',
  background: 'none',
  width: 'auto',
  boxShadow: 'none',
  padding: '0 0 3px 0',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}
