import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { sxLayoutWrapper, sxLayoutWrapperMobile } from './styles';
import AppBar from '../../_common/AppBar';
import { socket } from '@/services/socket';
import { useAppDispatch } from '@/redux/store';
import { setActiveTrackers } from '@/redux/slices/trackerSlice';
import { useMediaQuery } from 'react-responsive';

const Layout = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    function onActiveTracker(data: unknown) {
      console.log('ON_ACTIVE_TRACKER', data);
      dispatch(setActiveTrackers(data))
    }
    function onTimeTrackerApprove(data: unknown) {
      console.log('ON_APPROVE_TRACK', data);
    }

    function onTimeTrackerPaused(data: unknown) {
      console.log('ON_TRACK_PAUSED', data);
    }

    socket.on('ON_ACTIVE_TRACKER', onActiveTracker);
    socket.on('ON_APPROVE_TRACK', onTimeTrackerApprove);
    socket.on('ON_TRACK_PAUSED', onTimeTrackerPaused);

    return () => {
      socket.off('ON_ACTIVE_TRACKER', onActiveTracker);
      socket.off('ON_APPROVE_TRACK', onTimeTrackerApprove);
      socket.off('ON_TRACK_PAUSED', onTimeTrackerPaused);
    };
  }, []);

  return (
    <main style={isMobile ? sxLayoutWrapperMobile : sxLayoutWrapper}>
      <AppBar />
      <Outlet />
    </main>
  );
};

export default Layout;
