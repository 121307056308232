import { COLORS } from '@/assets/сolors/colors';

export const sxSpinnerWrapper = {
  position: 'absolute',
  top: '50%',
  bottom: '50%',
  right: '50%',
  left: '50%',
};

export const sxSpinner = {
  color: COLORS.MAIN,
};
