/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import IMG_IMAGES from '@/assets/images/img';
import { ROUTES } from '@/constants';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import { getActiveTrackers } from '@/redux/slices/trackerSlice';
import { useAppSelector } from '@/redux/store';
import { TrackerEvents } from '@/redux/types/tracker';
import Http from '@/services/http';


const useSockets = () => {
  const activeTrackers = useAppSelector(getActiveTrackers);
  const navigate = useNavigate();

  const postTrackersSocketEvents = async (id: string, body: TrackerEvents) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.TRACKERS}/${id}${API_ENDPOINTS.SOCKETS}${API_ENDPOINTS.EVENTS}`;
      const res = await Http.post(url, body);

      if (res) {
        console.log('trackereventsocket: ', res);
      }
    } catch (e: any) {
      console.error('getTrackersSocketEvents request:', e.message);
    }
  };

  const handleTestApproveSockets = () => {
    postTrackersSocketEvents('64b152c8118f035e2dd90e3e', { eventName: 'APPROVE_TRACK' })
    if ('Notification' in window) {
      const activeTracker = activeTrackers[0];
      const notificationTitle = 'Підтвердіть актуальність поточного треку';
      const trackerInfo = [];
      if (activeTracker && (activeTracker?.clientName || activeTracker?.courtCase?.caseNumber || activeTracker?.service?.name)) {
        trackerInfo.push('Інфо:');
      }
      if (activeTracker && activeTracker.clientName) {
        trackerInfo.push(`Клієнт: ${activeTracker.clientName}`);
      }
      if (activeTracker && activeTracker.courtCase) {
        trackerInfo.push(`Cправа: ${activeTracker.courtCase.caseNumber}`);
      }
      if (activeTracker && activeTracker.service && activeTracker.service.name) {
        trackerInfo.push(`Сервіс: ${activeTracker.service.name}`);
      }

      const notificationBody = `Перейдіть до активного трекера\n${trackerInfo.join('\n')}`;

      const notificationOptions = {
        body: notificationBody,
        icon: IMG_IMAGES.AVATAR,
      };

      const notification = new Notification(notificationTitle, notificationOptions);

      notification.addEventListener('click', () => {
        const trackerUrl = `${ROUTES.TRACKER}/${activeTrackers[0].id}`;
        if (typeof window !== 'undefined') {
          if (window.location.href !== trackerUrl) {
            if (window.focus) {
              window.focus();
              navigate(trackerUrl);
            }
          } else {
            navigate(trackerUrl);
          }
        }
      });
    }
  };

  const handleTestPauseSockets = () => {
    postTrackersSocketEvents('64b152c8118f035e2dd90e3e', { eventName: 'TRACK_PAUSED' })
    if ('Notification' in window) {
      const activeTracker = activeTrackers[0];
      const notificationTitle = 'Поточний трек зупинено';
      const trackerInfo = [];
      if (activeTracker && (activeTracker?.clientName || activeTracker?.courtCase?.caseNumber || activeTracker?.service?.name)) {
        trackerInfo.push('Інфо:');
      }
      if (activeTracker && activeTracker.clientName) {
        trackerInfo.push(`Клієнт: ${activeTracker.clientName}`);
      }
      if (activeTracker && activeTracker.courtCase) {
        trackerInfo.push(`Cправа: ${activeTracker.courtCase.caseNumber}`);
      }
      if (activeTracker && activeTracker.service && activeTracker.service.name) {
        trackerInfo.push(`Сервіс: ${activeTracker.service.name}`);
      }

      const notificationBody = `Поточний трек зупенено, так як його актуальність не була підтверджена протягом 30 хвилин\n${trackerInfo.join('\n')}`;

      const notificationOptions = {
        body: notificationBody,
        icon: IMG_IMAGES.AVATAR,
      };

      new Notification(notificationTitle, notificationOptions);
    }
  };

  return {
    handleTestApproveSockets,
    handleTestPauseSockets,
  };
};

export default useSockets;
