/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { Invitation } from '../types/invitation';
import { setSuccess } from './notificationSlice';

export const postInvite = createAsyncThunk(
  'invitations/postInvite',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNIT_USERS}/${id}${API_ENDPOINTS.INVITE}`;
      const res = await Http.post(url, { type: 'web' });

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні запрошення!');
      }

      dispatch(setSuccess('Запрошення створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const postInvitation = createAsyncThunk(
  'invitations/postInvitation',
  async ({ id, body }: { id: string, body: { approve: boolean } }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.INVITATIONS}/${id}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Неможливо прийняти запрошення так як користувач вже доданий до цього юніта або Немає відповіді з сервера при відповіді на запрошення!');
      }

      if (res && body.approve) {
        dispatch(setSuccess('Запрошення прийнято'));
        return res;
      }
      if (res && !body.approve) {
        dispatch(setSuccess('Запрошення відхилено'));
        return res;
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getInvitation = createAsyncThunk(
  'invitations/getInvitation',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.INVITATIONS}/${id}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі запрошення!');
      }

      dispatch(setInvitation(res));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.invitationStatus = 'loading';
  state.invitationError = null;
}

const setResolved = (state: any) => {
  state.invitationStatus = 'resolved';
}

const setErrorState = (state: any, action: any) => {
  state.invitationStatus = 'rejected';
  state.invitationError = action.payload;
}

const initialState: Invitation = {
  expires: null,
  role: null,
  unit: null,
  organization: null,
  invitationStatus: null,
  invitationError: null,
};

const invitationSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    setInvitation: (state, { payload }) => ({
      ...payload,
    }),
    setInvitationError: (state, action) => {
      state.invitationError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postInvite.pending, setLoading)
      .addCase(postInvitation.pending, setLoading)
      .addCase(getInvitation.pending, setLoading)
      .addCase(postInvite.fulfilled, setResolved)
      .addCase(postInvitation.fulfilled, setResolved)
      .addCase(getInvitation.fulfilled, setResolved)
      .addCase(postInvite.rejected, setErrorState)
      .addCase(postInvitation.rejected, setErrorState)
      .addCase(getInvitation.rejected, setErrorState);
  },
});

export const { setInvitation, setInvitationError } = invitationSlice.actions;

export const getCurrentInvitation = (state: MyStore) => state[invitationSlice.name];
export const getInvitationError = (state: MyStore) => state[invitationSlice.name].invitationError;

export default invitationSlice;
