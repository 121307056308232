import { COLORS } from '@/assets/сolors/colors';

export const sxAccordionSummary = {
  display: 'flex',
  padding: '12px 24px',
  width: '100%',
  minHeight: '56px',
};

export const sxAccordionSummaryMobile = {
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  width: '100%',
  minHeight: '56px',
};

export const sxAccordionContacts = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  ml: '32px',
  color: COLORS.GRAY,
};

export const sxAccordionContactsMobile = {
  color: COLORS.GRAY,
};

export const sxPhoneAndEmailMobile = {
  marginLeft: 1,
  maxWidth: '220px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};
