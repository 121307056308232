import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import User from '@/components/screens/Users/User';
import Spinner from '@/components/_common/Spinner';
import Title from '@/components/_common/Title';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import useUser from '@/hooks/useUser';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { getSelectedUnitUser, getUnitRoles, getUnitUser } from '@/redux/slices/unitUsersSlice';
import { useNavigate } from 'react-router-dom';

const UserPage: React.FC = () => {
  const { getUser } = useUser();
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const userId = localStorage.getItem(LOCAL_STORAGE.SEARCH_ENTITY_ID);
  const user = useAppSelector(getSelectedUnitUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { unitUserError, unitUserStatus } = useAppSelector(state => state.unitUsers);

  useEffect(() => {
    if (unitId && userId) {
      Promise.all([
        getUser(),
        dispatch(getUnitUser(userId)),
        dispatch(getUnitRoles(unitId)),
      ])
    }
  }, [unitId, userId]);

  const userData = useMemo(() => {
    return user;
  }, [user]);

  if (unitUserError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Користувач' />
      {unitUserStatus === 'loading' && <Spinner />}
      {userData && user && <User user={user} />}
    </Container>
  );
};

export default UserPage;
