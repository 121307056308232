import React from 'react';
import { Avatar, Chip, Stack } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { sxAvatar, sxAvatarImg, sxStack, sxStackMobile } from './styles';

interface ChipAvatarProps {
  name: string;
  img: string;
}

const TrackerAvatar: React.FC<ChipAvatarProps> = ({ name, img }) => {
  const avatarImg = img !== '' ? img : 'https://advocado.codeframeio.net/static/media/advocado.8555bd58d623d1abe308.jpg';
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={isMobile ? sxStackMobile : sxStack}
    >
      <Chip
        sx={{ fontSize: '16px' }}
        avatar={
          <Avatar sx={sxAvatar} alt={name}>
            <img src={avatarImg} alt={name} style={sxAvatarImg} />
          </Avatar>
        }
        label={name}
        variant='filled'
      />
    </Stack>
  );
};

export default TrackerAvatar;
