import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import Spinner from '@/components/_common/Spinner';
import Title from '@/components/_common/Title';
import Services from '@/components/screens/Services';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import useUser from '@/hooks/useUser';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { getAllCategories, getCategories, getSelectedCategory } from '@/redux/slices/categoriesSlice';
import { getCurrentServices, getServices } from '@/redux/slices/servicesSlice';
import { useNavigate } from 'react-router-dom';

const ServicesPage: React.FC = () => {
  const { getUser } = useUser();
  const categories = useAppSelector(getAllCategories);
  const servicesList = useAppSelector(getCurrentServices) || [];
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const selectedCategory = useAppSelector(getSelectedCategory);
  const dispatch = useAppDispatch();
  const { serviceError } = useAppSelector(state => state.services);
  const { categoryError, categoryStatus } = useAppSelector(state => state.categories);
  const navigate = useNavigate();

  useEffect(() => {
    if (unitId) {
      Promise.all([
        getUser(),
        dispatch(getCategories(unitId)),
        dispatch(getServices(unitId)),
      ])
    }
  }, [unitId]);

  const categoriesData = useMemo(() => {
    return categories;
  }, [categories]);

  const services = servicesList.filter(
    ({ serviceCategory }) => serviceCategory.id === selectedCategory?.id
  );

  if (serviceError || categoryError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Послуги' />
      {categoryStatus === 'loading' && <Spinner />}
      {categoriesData && <Services categories={categoriesData} services={services} />}
    </Container>
  );
};

export default ServicesPage;
