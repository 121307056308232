import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import UserItems from './UserItems';
import { useAppSelector } from '@/redux/store';
import { UnitUserData } from '@/redux/types/unitUsers';
import Column from '../../_common/Column';
import UserCard from './UserCard';
import Spinner from '../../_common/Spinner';
import { getSelectedUnitUser } from '@/redux/slices/unitUsersSlice';
import { Desktop, Mobile } from '@/utils/mediaQuery';
import ModalComponent from '@/components/_common/ModalComponent';

interface UsersProps {
  users: UnitUserData[];
}

const Users: React.FC<UsersProps> = ({ users }) => {
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
  const selectedUnitUser = useAppSelector(getSelectedUnitUser);
  const [unitInputVisible, setUnitInputVisible] = useState<boolean>(false);
  const { unitUserStatus } = useAppSelector(state => state.unitUsers);

  return (
    <>
      <Desktop>
        <Grid paddingBottom='60px' container spacing={2} sx={unitUserStatus === 'loading' ? { pointerEvents: 'none'} : {}}>
          {unitUserStatus === 'loading' && <Spinner />}
          <Column title='Користувач'>
            <UserItems
              users={users}
              selectable={true}
              btnTitle='Додати користувача'
              isInputVisible={unitInputVisible}
              setInputVisible={setUnitInputVisible}
            />
          </Column>
          <Column title='Дані про користувача'>
          {selectedUnitUser ? (
            <UserCard unitUser={selectedUnitUser} />
          ) : <></>}
          </Column>
        </Grid>
      </Desktop>

      <Mobile>
        <>
          {userModalOpen && (
            <ModalComponent
              isOpen={userModalOpen}
              setClose={() => setUserModalOpen(false)}
              title='Дані про користувача'
            >
              {selectedUnitUser && unitUserStatus !== 'loading' ? (
                <UserCard unitUser={selectedUnitUser} />
              ) : <Spinner />}
            </ModalComponent>
          )}
          <Box sx={unitUserStatus === 'loading' ? { marginBottom: '15px', pointerEvents: 'none'} : {marginBottom: '15px'}}>
            {unitUserStatus === 'loading' && <Spinner />}
            <UserItems
              users={users}
              selectable={true}
              btnTitle='Додати користувача'
              isInputVisible={unitInputVisible}
              setInputVisible={setUnitInputVisible}
              userModalOpen={() => setUserModalOpen(true)}
            />
          </Box>
        </>
      </Mobile>
    </>
  );
};

export default Users;
