import { Box } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { serviceFields } from './constants/defaultData';
import { serviceInitialValues } from './constants/initialValues';
import { servicesSchema, servicesSchemaMobile } from './constants/schema';
import { sxForm } from './styles';
import { ServiceFormValues } from './types';
import SVG_IMAGES from '@/assets/images/svg';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import AcceptBlock from '@/components/_common/AcceptBlock';
import BasicTextField from '@/components/_common/BasicTextField';
import { sxInputWrapper } from '@/components/_common/BasicTextField/styles';
import { ServiceRequest } from '@/redux/types/service';
import { CategoryData } from '@/redux/types/category';
import { getCategories, getSelectedCategory, setSelectedCategory } from '@/redux/slices/categoriesSlice';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { editService, getServices, postServices } from '@/redux/slices/servicesSlice';
import { useMediaQuery } from 'react-responsive';

interface ServiceFormProps {
  category?: CategoryData | null;
  setInputVisible?: (arg: boolean) => void;
  serviceId?: string;
  isEditForm?: boolean;
}

const ServiceForm: React.FC<ServiceFormProps> = ({
  setInputVisible,
  isEditForm = false,
  serviceId,
}) => {
  const dispatch = useAppDispatch();
  const selectedCategory = useAppSelector(getSelectedCategory);
  const id = selectedCategory?.id;
  const services = selectedCategory?.services;
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const selectedService = services?.find(({ id }) => id === serviceId) as ServiceRequest;
  const { serviceError } = useAppSelector(state => state.services);
  const { categoryError } = useAppSelector(state => state.categories);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onSubmit = async (
    values: ServiceFormValues,
    helpers: FormikHelpers<ServiceFormValues>
  ) => {
    helpers.setTouched({});
    helpers.setSubmitting(false);
    if (setInputVisible && !isEditForm && unitId && id) {
      await dispatch(postServices({ unitId, body: {
        ...values,
        serviceCategoryId: id,
        image: `${SVG_IMAGES.DOC}`,
        duration: Number(values.duration),
        price: Number(values.price),
      }}));
      await dispatch(getCategories(unitId));
      await dispatch(getServices(unitId));
      setInputVisible(false);
      dispatch(setSelectedCategory(null));
    }
    if (setInputVisible && isEditForm && serviceId && unitId && id) {
      await dispatch(editService({ unitId, serviceId, body: {
        ...values,
        serviceCategoryId: id,
        image: `${SVG_IMAGES.DOC}`,
        duration: Number(values.duration),
        price: Number(values.price),
      }}));
      (setInputVisible && setInputVisible(false));
      await dispatch(getCategories(unitId));
      await dispatch(getServices(unitId));
    }
  };

  if (serviceError || categoryError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Formik
      validationSchema={isMobile ? servicesSchemaMobile : servicesSchema}
      initialValues={isEditForm ? selectedService : serviceInitialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleBlur,
        values,
        handleChange,
        touched,
        errors,
        isValid,
      }) => {
        return (
          <Form style={sxForm} onSubmit={handleSubmit}>
            {serviceFields.map((item, index) => {
              return (
                <Box sx={sxInputWrapper} key={index}>
                  <BasicTextField
                    {...item}
                    errors={errors}
                    touched={touched}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Box>
              );
            })}
            <AcceptBlock
              variant='contained'
              disabled={!isValid}
              handleAgree={handleSubmit}
              handleDisagree={() => setInputVisible && setInputVisible(false)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ServiceForm;
