import { SxProps } from '@mui/material';
import { COLORS } from '@/assets/сolors/colors';

export const sxCenterContent = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  overflowX: 'hidden',
  boxShadow: '0',
  mb: '24px',
};

export const sxUserHover = {
  cursor: 'pointer', 
}

export const sxStatusBtn: { [key: string]: SxProps } = {
  new: {
    paddingLeft: '11px',
    minWidth: '180px',
  },

  paid: {
    background: COLORS.SECONDARY,
    paddingLeft: '11px',
    minWidth: '180px',
  },

  approved: {
    background: COLORS.SUCCESS_SECONDARY,
    paddingLeft: '11px',
    minWidth: '180px',
  },

  cancelled: {
    background: COLORS.ERROR,
    paddingLeft: '11px',
    minWidth: '180px',
  },
};

export const sxStatusBtnMobile: { [key: string]: SxProps } = {
  new: {
    paddingLeft: '11px',
    minWidth: '100%',
  },

  paid: {
    background: COLORS.SECONDARY,
    paddingLeft: '11px',
    minWidth: '100%',
  },

  approved: {
    background: COLORS.SUCCESS_SECONDARY,
    paddingLeft: '11px',
    minWidth: '100%',
  },

  cancelled: {
    background: COLORS.ERROR,
    paddingLeft: '11px',
    minWidth: '100%',
  },
};
