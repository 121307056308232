import React from 'react';
import { invoicePackagesSchema, invoicePackagesSchemaMobile } from './constants/schema';
import { invoicePackagesInitialValues } from './constants/initialValues';
import { Field, Form, Formik } from 'formik';
import FormSelect from '@/components/ui/FormSelect/FormSelect';
import BasicTextField from '@/components/_common/BasicTextField';
import AcceptBlock from '@/components/_common/AcceptBlock';
import { OptionsData } from '@/components/ui/SelectComponent/SelectComponent';
import { CategoryData } from '@/redux/types/category';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { getSelectedCategory, setSelectedCategory } from '@/redux/slices/categoriesSlice';
import { InvoiceServicePackage } from '@/redux/types/invoice';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { Box } from '@mui/material';
import { editInvoice, getInvoice, getInvoices, getSelectedInvoice } from '@/redux/slices/invoiceSlice';
import { useNavigate } from 'react-router-dom';
import { getAppPagination } from '@/redux/slices/PaginationSlice';
import { useMediaQuery } from 'react-responsive';

interface BillPackageFormProps {
  categories: CategoryData[];
  setIsOpen: (arg: boolean) => void;
}

const BillPackageForm: React.FC<BillPackageFormProps> = ({
  categories,
  setIsOpen,
}) => {
  const dispatch = useAppDispatch();
  const selectedCategory = useAppSelector(getSelectedCategory);
  const invoice = useAppSelector(getSelectedInvoice);
  const id = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const { invoiceError } = useAppSelector(state => state.invoice);
  const navigate = useNavigate();
  const { page, limit } = useAppSelector(getAppPagination);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onSubmit = async(values: InvoiceServicePackage) => {
    if (id && invoice) {
      await dispatch(editInvoice({
        id: invoice.id,
        items: [{
          serviceId: values.serviceIds,
          total: values.total,
          overwriteTotal: true,
        }],
        unitId: id,
      }));
      dispatch(setSelectedCategory(null));
      await dispatch(getInvoice(invoice?.id));
      await dispatch(getInvoices({ page, limit }));
      setIsOpen(false);
    }
  };

  const categoryOptions: OptionsData[] = categories.map(item => ({
    label: item.name,
    value: item.id,
    services: item.services,
  }));
  
  const serviceOptions: OptionsData[] = selectedCategory?.services?.map(item => ({
    label: item.name,
    value: item.id,
  })) || [];

  if (invoiceError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Formik
      validationSchema={isMobile ? invoicePackagesSchemaMobile : invoicePackagesSchema}
      initialValues={invoicePackagesInitialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        touched,
        handleBlur,
        isValid,
        values,
      }) => {
        
        return (
          <Form onSubmit={handleSubmit}>
            <Box>
              <Field
                name='category'
                placeholder='Оберіть категорію'
                component={FormSelect}
                options={categoryOptions}
              />
              {values.category && (
                <Field
                  name='serviceIds'
                  placeholder='Оберіть послугу'
                  component={FormSelect}
                  options={serviceOptions}
                />
              )}
              <Box position='relative' marginBottom = '10px'>
                <BasicTextField
                  errors={errors}
                  touched={touched}
                  values={values}
                  name='total'
                  maxLength={7}
                  label='Години'
                  placeholder='Години'
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Box>
            </Box>
              <AcceptBlock
                variant='contained'
                disabled={!isValid}
                handleAgree={handleSubmit}
                handleDisagree={() => setIsOpen(false)}
              />
          </Form>
        );
      }}
    </Formik>
  );
};

export default BillPackageForm;
