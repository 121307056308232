import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { requisitesSchema, requisitesSchemaMobile } from './constants/schema';
import { requisitesInitialValues } from './constants/initialValues';
import { Box } from '@mui/material';
import { sxServicePackages } from './styles';
import AcceptBlock from '@/components/_common/AcceptBlock';
import { Requisites } from '@/redux/types/agreement';
import { requisitesFields } from './constants/defaultData';
import { sxInputWrapper } from '@/components/_common/BasicTextField/styles';
import BasicTextField from '@/components/_common/BasicTextField';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { SelectedClient } from '@/redux/types/client';
import { getClient } from '@/redux/slices/clientsSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { editRequisites, postRequisites } from '@/redux/slices/agreementSlice';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface RequisitesFormProps {
  setIsOpen: (arg: boolean) => void;
  client: SelectedClient;
}

const RequisitesForm: React.FC<RequisitesFormProps> = ({
  client,
  setIsOpen,
}) => {
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { agreementError } = useAppSelector(state => state.agreement);
  const { clientError } = useAppSelector(state => state.clients);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onSubmit = async (values: Requisites, helpers: FormikHelpers<Requisites> ) => {
    helpers.setTouched({});
    helpers.setSubmitting(false);

    if (clientError || agreementError) {
      navigate(ROUTES.ERROR);
    }

    if (unitId) {
      if (client.requisites) {
        await dispatch(editRequisites({ clientId: client.id, body: { ...values } }));
        await dispatch(getClient({ unitId, clientId: client.id }));
        setIsOpen(false);
      } else {
        await dispatch(postRequisites({ clientId: client.id, body: { ...values } }));
        await dispatch(getClient({ unitId, clientId: client.id }));
        setIsOpen(false);
      }
    }
  };

  return (
    <Formik
      validationSchema={isMobile ? requisitesSchemaMobile : requisitesSchema}
      initialValues={client.requisites ? client.requisites : requisitesInitialValues}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        isValid,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box sx={sxServicePackages}>
              {requisitesFields.map((item, index) => {
                return (
                  <Box sx={sxInputWrapper} key={index}>
                    <BasicTextField
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      {...item}
                    />
                  </Box>
                );
              })}
              <AcceptBlock
                variant='contained'
                disabled={!isValid}
                handleAgree={handleSubmit}
                handleDisagree={() => setIsOpen(false)}
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequisitesForm;
