import { PaginationData } from './category';

export interface UnitUsers {
  data: UnitUserData[];
  pagination: PaginationData | null;
  selectedUser: UnitUserData | null;
  role: Role | null;
  unitUserStatus: string | null;
  unitUserError: string | null;
}

export interface UnitUserData {
  blockedAt: string;
  category: string;
  createdAt: string;
  deletedAt: string;
  description: string;
  email: string;
  firedAt: string;
  hasSchedule: boolean;
  id: string;
  image: string;
  includedToCalendar: boolean;
  includedToSchedule: boolean;
  name: string;
  onlineAvailable: boolean;
  orderInCalendar: number;
  organizationId: string;
  phone: string;
  registeredAt: string;
  roleId: string;
  specialization: string;
  status: UnitUserStatus;
  unitId: string;
  updatedAt: string;
  userId: string;
  user: User;
  invite: Invite;
  role: Role;
  settings: Settings;
  services: Service;
}

export enum UnitUserStatus {
  Active = 'active',
  Blocked = 'blocked',
  Fired = 'fired',
}

interface Invite {
  link: string;
  expires: string;
}

export interface Role {
  custom: boolean;
  description: string;
  id: string;
  name: string;
  permissions: Permissions[];
}

interface Settings {
  absences: boolean;
  cancellations: boolean;
  enabled: boolean;
  newSale: boolean;
  reschedulings: boolean;
  visitCreation: boolean;
  visitStatusChange: boolean;
}

interface Permissions {
  permission: string;
  condition: object;
}

export interface User {
  phone: string;
  email: string;
  fullName: string;
}

interface Service {
  phone: string;
  id: string;
  duration: number;
  price: number;
  description: string;
  image: string;
  unitService: UnitService;
}

interface UnitService {
  id: string;
  name: string;
  serviceCategory: ServiceCategory;
}

interface ServiceCategory {
  id: string;
  name: string;
  image: string;
}

export interface UnitUserRequest {
  status: string;
  roleId: string;
  phone: string;
  email: string;
  image: string;
  name: string;
  specialization: string;
  category: string;
  services: UnitUserServiceRequest[];
}

interface UnitUserServiceRequest {
  unitServiceId: string;
  description: string;
  image: string;
  price: number;
  duration: number;
}
