import React, { createContext, FC } from 'react';

import { TimeTrackerContextProps, TimeTrackerProviderProps } from './types';
import useTime from '@/hooks/useTime';

export const TimeTrackerContext = createContext<TimeTrackerContextProps>({
  running: false,
  time: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRunning: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTime: () => {},
});

const TimeTrackerProvider: FC<TimeTrackerProviderProps> = ({ children }) => {
  const { time, setTime, running, setRunning } = useTime();
  return (
    <TimeTrackerContext.Provider
      value={{
        setRunning,
        setTime,
        running,
        time,
      }}
    >
      {children}
    </TimeTrackerContext.Provider>
  );
};

export default TimeTrackerProvider;
