import { createSlice } from '@reduxjs/toolkit';
import { MyStore } from '../store';
import { ModalComponentStatus } from '../types/common';

const initialState: ModalComponentStatus = {
  modalComponentStatus: false,
};

const modalComponentSlice = createSlice({
  name: 'modalComponent',
  initialState,
  reducers: {
    setModalComponentStatus: (state, action) => {
      state.modalComponentStatus = action.payload;
    },
  },
});

export const { setModalComponentStatus } = modalComponentSlice.actions;

export const getModalComponentStatus = (state: MyStore) => state[modalComponentSlice.name].modalComponentStatus;

export default modalComponentSlice;
