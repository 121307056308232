/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { Invoices } from '../types/invoice';
import { setSuccess } from './notificationSlice';

export const postInvoice = createAsyncThunk(
  'invoice/postInvoice',
  async (clientId: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${clientId}${API_ENDPOINTS.INVOICES}`;
      const res = await Http.post(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні інвойса!');
      }

      dispatch(setSuccess('Інвойс створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getInvoices = createAsyncThunk(
  'invoice/getInvoices',
  async ({ page = 1, limit = 50 }: { page?: number, limit?: number }, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.CLIENTS}${API_ENDPOINTS.INVOICES}?page=${page}&limit=${limit}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку інвойсів!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getInvoice = createAsyncThunk(
  'invoice/getInvoice',
  async (invoiceId: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.INVOICES}/${invoiceId}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі інвойса!');
      }

      dispatch(setSelectedInvoice(res));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const editInvoice = createAsyncThunk(
  'invoice/editInvoice',
  async (body: any, { rejectWithValue, dispatch }) => {
    try {
      const { id } = body;
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.INVOICES}/${id}`;
      const res = await Http.patch(url, body);

      if (res) {
        dispatch(setSelectedInvoice(res));
        dispatch(setSuccess('Інформацію змінено успішно'));
  
        return res;
      } else {
        throw new Error('Немає відповіді з сервера при редагуванні інвойса!');
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteInvoiceService = createAsyncThunk(
  'invoice/deleteInvoiceService',
  async ({ invoiceId, serviceId }: { invoiceId: string, serviceId: string }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.INVOICES}/${invoiceId}/services/${serviceId}`;
      await Http.delete(url);

      dispatch(setSuccess('Сервіс видалено успішно'));
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.invoiceStatus = 'loading';
  state.invoiceError = null;
}

const setInvoicesResolved = (state: any, action: any) => {
  state.invoiceStatus = 'resolved';
  state.invoices = action.payload;
}

const setResolved = (state: any) => {
  state.invoiceStatus = 'resolved';
}

const setError = (state: any, action: any) => {
  state.invoiceStatus = 'rejected';
  state.invoiceError = action.payload;
}

const initialState: Invoices = {
  invoices: null,
  selectedInvoice: null,
  invoiceStatus: null,
  invoiceError: null,
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setSelectedInvoice: (state, action) => {
      state.selectedInvoice = action.payload;
    },
    setInvoiceError: (state, action) => {
      state.invoiceError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postInvoice.pending, setLoading)
      .addCase(getInvoices.pending, setLoading)
      .addCase(getInvoice.pending, setLoading)
      .addCase(editInvoice.pending, setLoading)
      .addCase(deleteInvoiceService.pending, setLoading)
      .addCase(postInvoice.fulfilled, setResolved)
      .addCase(getInvoices.fulfilled, setInvoicesResolved)
      .addCase(getInvoice.fulfilled, setResolved)
      .addCase(editInvoice.fulfilled, setResolved)
      .addCase(deleteInvoiceService.fulfilled, setResolved)
      .addCase(postInvoice.rejected, setError)
      .addCase(getInvoices.rejected, setError)
      .addCase(getInvoice.rejected, setError)
      .addCase(editInvoice.rejected, setError)
      .addCase(deleteInvoiceService.rejected, setError);
  },
});

export const { setSelectedInvoice, setInvoiceError } = invoiceSlice.actions;

export const getAllInvoices = (state: MyStore) => state[invoiceSlice.name];
export const getSelectedInvoice = (state: MyStore) => state[invoiceSlice.name].selectedInvoice;
export const getInvoiceError = (state: MyStore) => state[invoiceSlice.name].invoiceError;

export default invoiceSlice;
