import { ActionCreatorsMapObject, bindActionCreators } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useAppDispatch } from '@/redux/store';

export const useActionCreators = <Action extends ActionCreatorsMapObject>(actions: Action) => {
  const dispatch = useAppDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), []);
};

export default useActionCreators;
