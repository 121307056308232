import { FieldProps } from 'formik';
import React from 'react';
import SelectComponent, { OptionsData } from '../SelectComponent/SelectComponent';
import selectStyles from '../SelectComponent/styles';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSelectedCategories, setSelectedCategory } from '@/redux/slices/categoriesSlice';
import { setSelectedServices } from '@/redux/slices/servicesSlice';

interface SelectProps {
  name: string;
  placeholder: string;
  isMulti?: boolean;
  options: OptionsData[];
}

const FormSelect: React.FC<SelectProps & FieldProps> = ({
  options,
  isMulti = false,
  field,
  form,
  ...props
}) => {
  const dispatch = useDispatch();

  const onChange = (option: OptionsData | OptionsData[]) => {
    if (field.name === 'categories') {
      dispatch(setSelectedCategories(option));
    }
    if (field.name === 'category') {
      dispatch(setSelectedCategory(option));
    }
    else {
      dispatch(setSelectedServices(option));
    }
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as OptionsData[]).map(({ value }: OptionsData) => value)
        : (option as OptionsData).value
    );
  };

  return (
    <Box className='mb-6'>
      <SelectComponent
        isMulti={isMulti}
        styles={selectStyles}
        options={options}
        onChange={onChange}
        {...props}
      />
    </Box>
  );
};

export default FormSelect;
