import {
  StopCircle,
  PlayCircle,
  ReplayCircleFilled,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { TimeTrackerContext } from '../TimeTrackerProvider/TimeTrackerProvider';

import {
  sxBtnImage,
  sxTimeTrackerBox,
  sxTimeTrackerBtn,
  sxTimeTrackerBtnWrapper,
} from '../styles';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { Tracker } from '@/redux/types/tracker';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import Button from '../../../_common/Button';
import { getAppPagination } from '@/redux/slices/PaginationSlice';
import { useNavigate } from 'react-router-dom';
import { editTracker, getActiveTrackers, getTracker, getTrackers } from '@/redux/slices/trackerSlice';

interface TimeTrackerProps {
  classNameBtn?: object;
  className?: object;
  tracker: Tracker | null;
  trackerDisabled?: boolean;
}

const TrackerButtons: React.FC<TimeTrackerProps> = ({
  classNameBtn = sxTimeTrackerBtn,
  className = sxTimeTrackerBox('25%'),
  tracker,
  trackerDisabled,
}) => {
  const { running, setRunning, setTime } = useContext(TimeTrackerContext);
  const { page, limit } = useAppSelector(getAppPagination);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const activeTrackers = useAppSelector(getActiveTrackers);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackerError } = useAppSelector(state => state.tracker);

  const handleStart = async () => {
    if (unitId && tracker) {
      if (running && activeTrackers && activeTrackers.length > 0 && (activeTrackers[0].id !== tracker.id)) {
        await dispatch(editTracker({ unitId, body: {id: activeTrackers[0].id, status: 'stopped' }}));
        await dispatch(editTracker({ unitId, body: {id: tracker.id, status: 'tracking' }}));
        await dispatch(getTrackers({ page, limit }));
        await dispatch(getTracker(tracker.id));
        setTime(0);
      } else {
        await dispatch(editTracker({unitId, body: {id: tracker.id, status: 'tracking' }}))
        await dispatch(getTrackers({ page, limit }));
        await dispatch(getTracker(tracker.id));
      }
    }
    setRunning(true);
    localStorage.setItem(LOCAL_STORAGE.RUNNING, 'true');
  };

  const handleStop = async () => {
    if (unitId && tracker) {
      await dispatch(editTracker({unitId, body: {id: tracker.id, status: 'stopped' }}));
      await dispatch(getTrackers({ page, limit }));
      await dispatch(getTracker(tracker.id));
    }

    setRunning(false);
    localStorage.removeItem(LOCAL_STORAGE.SAVED_TRACKER_TIME);
    localStorage.removeItem(LOCAL_STORAGE.RUNNING);
    setTime(0);
  };

  const handleReset = () => {
    setTime(0);
    localStorage.removeItem(LOCAL_STORAGE.SAVED_TRACKER_TIME);
    localStorage.removeItem(LOCAL_STORAGE.RUNNING);
  };

  if (trackerError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={className} >
      <Box sx={sxTimeTrackerBtnWrapper}>
        <Button
          title='Оновити'
          sx={classNameBtn}
          onClick={handleReset}
          startIcon={<ReplayCircleFilled sx={sxBtnImage} />}
          disabled={trackerDisabled || (running && activeTrackers && activeTrackers.length > 0 && activeTrackers[0].id !== tracker?.id)}
        />
        <Button
          title='Старт'
          sx={classNameBtn}
          onClick={handleStart}
          startIcon={<PlayCircle sx={sxBtnImage} />}
          disabled={trackerDisabled}
        />
        <Button
          title='Стоп'
          sx={classNameBtn}
          onClick={handleStop}
          startIcon={<StopCircle sx={sxBtnImage} />}
          disabled={trackerDisabled || (running && activeTrackers && activeTrackers.length > 0 && activeTrackers[0].id !== tracker?.id)}
        />
      </Box>
    </Box>
  );
};

export default TrackerButtons;
