import React from 'react';
import { Avatar, Chip, Stack } from '@mui/material';
import { sxAvatar, sxStack, sxStackMobile } from './styles';
import { useMediaQuery } from 'react-responsive';

interface ChipAvatarProps {
  name: string;
}

const ChipAvatar: React.FC<ChipAvatarProps> = ({ name }) => {
  const username = name.slice(0, 1);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={isMobile ? sxStackMobile : sxStack}
    >
      <Chip
        sx={{ fontSize: '16px' }}
        avatar={
          <Avatar sx={sxAvatar} alt={name}>
            {username}
          </Avatar>
        }
        label={name}
        variant='filled'
      />
    </Stack>
  );
};

export default ChipAvatar;
