import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import Title from '@/components/_common/Title';
import Invitations from '@/components/screens/Invitations';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import useUser from '@/hooks/useUser';
import { getCurrentInvitation, getInvitation } from '@/redux/slices/invitationSlice';
import Spinner from '@/components/_common/Spinner';
import { ROUTES } from '@/constants';
import { getCurrentUser } from '@/redux/slices/userSlice';

const InvitationsPage: React.FC = () => {
  const { getUser } = useUser();
  const { id } = useParams();
  const { user } = useAppSelector(getCurrentUser);
  const invitation = useAppSelector(getCurrentInvitation);
  const isEmptyObject = Object.keys(invitation).length === 0;
  const dispatch = useAppDispatch();
  const { invitationStatus, invitationError } = useAppSelector(state => state.invitations);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      Promise.all([
        getUser(),
        dispatch(getInvitation(id)),
      ])
    }
  }, [id]);

  if (invitationError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Вітаємо' />
      {invitationStatus === 'loading' && <Spinner />}
      {user && id && !isEmptyObject && <Invitations id={id} invitation={invitation} />}
    </Container>
  );
};

export default InvitationsPage;
