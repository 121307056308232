export const ROUTES = {
  MAIN: '/',
  CLIENTS: '/clients',
  CLIENT: '/clients/:id',
  CASES: '/cases',
  CASE: '/case',
  TRACKER: '/tracker',
  ACTIVE_TRACKER: '/tracker/:id',
  BILLS: '/bills',
  SEARCH: '/search',
  USERS: '/users',
  USER: '/users/:id',
  REGISTRATION: '/registration',
  INVITATIONS: '/invitations/:id',
  LOGIN: '/login',
  SERVICES: '/services',
  NOT_FOUND: '/*',
  SELECT_ORGANIZATION: '/select-organization',
  ERROR: '/error',
};
export const LOCAL_STORAGE = {
  ACTIVE_MENU_ITEM: 'activeMenuItem',
  ACCESS_TOKEN: 'accessToken',
  USER: 'user',
  ORGANIZATION_UNIT_ID: 'organizationUnitId',
  CLIENT_ID: 'clientId',
  SEARCH_ENTITY_ID: 'searchEntityId',
  RUNNING: 'running',
  SAVED_TRACKER_TIME: 'savedTrackerTime',
};
