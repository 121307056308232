/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { ClientRequest, SelectedClient } from '../types/client';
import { setSuccess } from './notificationSlice';

export const postClient = createAsyncThunk(
  'clients/postClient',
  async ({ unitId, body }: { unitId: string, body: SelectedClient }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.CLIENTS}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні клієнта!');
      }

      dispatch(setSuccess('Клієнта створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getClients = createAsyncThunk(
  'clients/getClients',
  async ({ unitId, page = 1, limit = 50 }: { unitId: string, page?: number, limit?: number }, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.CLIENTS}?page=${page}&limit=${limit}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку клієнтів!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getClient = createAsyncThunk(
  'clients/getClient',
  async ({ unitId, clientId }: { unitId: string, clientId: string }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.CLIENTS}/${clientId}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі клієнта!');
      }

      dispatch(setSelectedClient(res));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const editClient = createAsyncThunk(
  'clients/editClient',
  async ({ unitId, body }: { unitId: string, body: SelectedClient }, { rejectWithValue, dispatch }) => {
    try {
      const { id } = body;
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.CLIENTS}/${id}`;
      const res = await Http.patch(url, body);

      if (res) {
        dispatch(setSelectedClient(res));
        dispatch(setSuccess('Інформацію змінено успішно'));
  
        return res;
      } else {
        throw new Error('Немає відповіді з сервера при редагуванні клієнта!');
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteClient = createAsyncThunk(
  'clients/deleteClient',
  async ({ unitId, clientId }: { unitId: string, clientId: string }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.CLIENTS}/${clientId}`;
      await Http.delete(url);

      dispatch(setSuccess('Клієнта видалено успішно'));
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.clientStatus = 'loading';
  state.clientError = null;
}

const setClientsResolved = (state: any, action: any) => {
  state.clientStatus = 'resolved';
  state.clients = action.payload;
}

const setResolved = (state: any) => {
  state.clientStatus = 'resolved';
}

const setError = (state: any, action: any) => {
  state.clientStatus = 'rejected';
  state.clientError = action.payload;
}

const initialState: ClientRequest = {
  clients: null,
  selectedClient: null,
  clientStatus: null,
  clientError: null,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setClientError: (state, action) => {
      state.clientError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postClient.pending, setLoading)
      .addCase(getClients.pending, setLoading)
      .addCase(getClient.pending, setLoading)
      .addCase(editClient.pending, setLoading)
      .addCase(deleteClient.pending, setLoading)
      .addCase(postClient.fulfilled, setResolved)
      .addCase(getClients.fulfilled, setClientsResolved)
      .addCase(getClient.fulfilled, setResolved)
      .addCase(editClient.fulfilled, setResolved)
      .addCase(deleteClient.fulfilled, setResolved)
      .addCase(postClient.rejected, setError)
      .addCase(getClients.rejected, setError)
      .addCase(getClient.rejected, setError)
      .addCase(editClient.rejected, setError)
      .addCase(deleteClient.rejected, setError);
  },
});

export const { setSelectedClient, setClientError } = clientsSlice.actions;

export const getAllClients = (state: MyStore) => state[clientsSlice.name];
export const getSelectedClient = (state: MyStore) => state[clientsSlice.name].selectedClient;
export const getClientError = (state: MyStore) => state[clientsSlice.name].clientError;

export default clientsSlice;
