import { Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import Button from '../Button';

import { sxEditBtn } from './styles';

interface EditButtonProps {
  editTitle?: string;
  editHandler?: () => void;
  editIsActive?: boolean;
}

const EditButton: React.FC<EditButtonProps> = ({
  editTitle = 'Редагувати дані',
  editHandler,
  editIsActive,
}) => {
  return (
    <Box width='100%'>
      {!editIsActive && (
        <Button
          sx={sxEditBtn}
          onClick={editHandler}
          endIcon={<Edit />}
          buttonTitle={editTitle}
        />
      )}
    </Box>
  );
};

export default EditButton;
