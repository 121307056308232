import { createSlice } from '@reduxjs/toolkit';
import { MyStore } from '../store';
import { PagePagination } from '../types/pagination';

const initialState: PagePagination = {
    page: 1,
    limit: 50,
    pagesTotal: 1,
    totalCount: 0,
};

const paginationSlice = createSlice({
  name: 'appPagination',
  initialState,
  reducers: {
    setAppPagination: ( state, { payload }) => ({
      ...state,
      ...payload,
    })
  },
});

export const { setAppPagination } = paginationSlice.actions;

export const getAppPagination = (state: MyStore) => state[paginationSlice.name];

export default paginationSlice;
