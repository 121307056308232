import React, { ReactNode } from 'react';
import { Button as MUIButton } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from '@/assets/сolors/colors';


interface ButtonProps {
  title?: string;
  buttonTitle?: string | ReactNode;
  disabled?: boolean;
  sx?: object | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (data: any) => void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  variant?: 'outlined' | 'contained' | 'text';
  disableRipple?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
}

const StyledButton = styled(MUIButton)(() => ({
  background: COLORS.MAIN,
  color: COLORS.WHITE,
  '&:hover': {
    background: COLORS.SUCCESS,
  },
}));

const Button: React.FC<ButtonProps> = ({
  title,
  disabled,
  sx,
  onClick,
  startIcon,
  fullWidth,
  variant,
  buttonTitle,
  endIcon,
  disableRipple,
  className,
  type,
  color,
}) => {
  return (
      <StyledButton
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        sx={sx}
        startIcon={startIcon}
        endIcon={endIcon}
        fullWidth={fullWidth}
        title={title}
        disableRipple={disableRipple}
        className={className}
        type={type}
        color={color}
      >
        {buttonTitle}
      </StyledButton>
  );
};

export default Button;
