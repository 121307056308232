import { AccountBox, Delete } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import {
  sxAccordionBtn,
  sxAccordionBtnMobile,
  sxAccordionDeleteBtn,
  sxAccordionDeleteBtnMobile,
  sxAccordionWrapper,
} from './styles';
import WarningModal from '../../../_common/WarningModal';
import { useAppSelector } from '@/redux/store';
import Spinner from '../../../_common/Spinner';
import Button from '../../../_common/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { useMediaQuery } from 'react-responsive';

interface CustomAccordionDetailsProps {
  modalClientOpen: () => void;
  deleteClient: () => void;
}

const ClientAccordionDetails: React.FC<CustomAccordionDetailsProps> = ({
  modalClientOpen,
  deleteClient,
}) => {
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const { clientStatus, clientError } = useAppSelector(state => state.clients);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const deleteHandler = () => {
    setIsWarningOpen(false);
    deleteClient();
  };

  if (clientError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={sxAccordionWrapper}>
      {clientStatus === 'loading' && <Spinner />}
      <Button
        variant='contained'
        title='Переглянути клієнта'
        sx={isMobile ? sxAccordionBtnMobile : sxAccordionBtn}
        startIcon={<AccountBox />}
        onClick={modalClientOpen}
        buttonTitle='Переглянути клієнта'
      />
      <Button
        variant='contained'
        title='Видалити клієнта'
        sx={isMobile ? sxAccordionDeleteBtnMobile : sxAccordionDeleteBtn}
        startIcon={<Delete />}
        onClick={() => setIsWarningOpen(true)}
        buttonTitle='Видалити клієнта'
      />
      <WarningModal
        title='Дійсно видалити клієнта?'
        open={isWarningOpen}
        handleAgree={deleteHandler}
        handleClose={() => setIsWarningOpen(false)}
      />
    </Box>
  );
};

export default ClientAccordionDetails;
