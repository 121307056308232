export const sxTableContainer = {
  boxShadow: '0',
};

export const sxTableRow = {
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  'th, td': {
    padding: '16px',
  },
};

export const sxTableRowMobile = {
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  'th, td': {
    padding: '16px 5px 16px 5px',
  },
};
