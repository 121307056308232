import { AccountCircle, Close } from '@mui/icons-material';
import { Box, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';

import { sxCloseMenu, sxCloseMenuIcon, sxListItemsWrapper, sxListUser, sxListUserIcon } from './styles';
import { AppMenuItemData } from './types';
import { LOCAL_STORAGE } from '@/constants';
import AppBarMenuItem from '../AppBarMenuItem';
import { useMediaQuery } from 'react-responsive';

interface AppMenuListItemsProps {
  appManuListItemsData: AppMenuItemData[];
}

const AppBarMenuListItems = ({
  appManuListItemsData,
}: AppMenuListItemsProps) => {
  const email = localStorage.getItem(LOCAL_STORAGE.USER);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box sx={sxListItemsWrapper}>
      {isMobile && (
        <ListItem sx={sxCloseMenu}>
          <ListItemIcon>{<Close sx={sxCloseMenuIcon} />}</ListItemIcon>
        </ListItem>
      )}
      <ListItem sx={sxListUser}>
        <ListItemIcon>{<AccountCircle sx={sxListUserIcon} />}</ListItemIcon>
        <ListItemText primary={email} />
      </ListItem>
      {appManuListItemsData.map(({ icon, target, title, disabled }, index) => {
        return (
          <AppBarMenuItem
            disabled={disabled}
            icon={icon}
            title={title}
            target={target}
            key={index}
          />
        );
      })}
    </Box>
  );
};

export default AppBarMenuListItems;
