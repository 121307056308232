import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';

import middlewares from './middlewares';
import trackerSlice from './slices/trackerSlice';
import categoriesSlice from './slices/categoriesSlice';
import clientsSlice from './slices/clientsSlice';
import notificationSlice from './slices/notificationSlice';
import servicesSlice from './slices/servicesSlice';
import unitUsersSlice from './slices/unitUsersSlice';
import userSlice from './slices/userSlice';
import { CaseNoteData, Cases } from './types/case';
import { Category } from './types/category';
import { ClientRequest } from './types/client';
import { Notification } from './types/notification';
import { Services } from './types/service';
import { UnitUsers } from './types/unitUsers';
import { SelectedUser } from './types/user';
import invitationSlice from './slices/invitationSlice';
import { Invitation } from './types/invitation';
import invoiceSlice from './slices/invoiceSlice';
import { Invoices } from './types/invoice';
import { Trackers } from './types/tracker';
import agreementSlice from './slices/agreementSlice';
import { Agreements } from './types/agreement';
import { SearchData } from './types/search';
import searchSlice from './slices/SearchSlice';
import casesSlice from './slices/casesSlice';
import caseNoteSlice from './slices/caseNoteSlice';
import modalComponentSlice from './slices/modalComponentSlice';
import { ModalComponentStatus } from './types/common';
import { PagePagination } from './types/pagination';
import paginationSlice from './slices/PaginationSlice';

const config = {
  key: 'root',
  storage,
  whitelist: [],
};

export interface MyStore {
  [trackerSlice.name]: Trackers;
  [paginationSlice.name]: PagePagination;
  [userSlice.name]: SelectedUser;
  [notificationSlice.name]: Notification;
  [clientsSlice.name]: ClientRequest;
  [agreementSlice.name]: Agreements;
  [categoriesSlice.name]: Category;
  [servicesSlice.name]: Services;
  [unitUsersSlice.name]: UnitUsers;
  [invitationSlice.name]: Invitation;
  [invoiceSlice.name]: Invoices;
  [searchSlice.name]: SearchData;
  [casesSlice.name]: Cases;
  [caseNoteSlice.name]: CaseNoteData;
  [modalComponentSlice.name]: ModalComponentStatus;
}

const rootReducer = combineReducers({
  [trackerSlice.name]: trackerSlice.reducer,
  [paginationSlice.name]: paginationSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [notificationSlice.name]: notificationSlice.reducer,
  [clientsSlice.name]: clientsSlice.reducer,
  [agreementSlice.name]: agreementSlice.reducer,
  [categoriesSlice.name]: categoriesSlice.reducer,
  [servicesSlice.name]: servicesSlice.reducer,
  [unitUsersSlice.name]: unitUsersSlice.reducer,
  [invitationSlice.name]: invitationSlice.reducer,
  [invoiceSlice.name]: invoiceSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [casesSlice.name]: casesSlice.reducer,
  [caseNoteSlice.name]: caseNoteSlice.reducer,
  [modalComponentSlice.name]: modalComponentSlice.reducer,
});

export const rootActions = {
  ...modalComponentSlice.actions,
  ...invoiceSlice.actions,
  ...paginationSlice.actions,
  ...trackerSlice.actions,
};

export const store = configureStore({
  reducer: persistReducer(config, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof store.getState>;
//
// export type CustomThunk<T = void> = ThunkAction<
//   T,
//   RootState,
//   unknown,
//   AnyAction
// >;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<MyStore> = useSelector;
