import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CaseView from '@/components/screens/Cases/CaseView';
import Spinner from '@/components/_common/Spinner';
import Title from '@/components/_common/Title';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import useUser from '@/hooks/useUser';
import { getCaseNotes } from '@/redux/slices/caseNoteSlice';
import { getCase, getSelectedCase } from '@/redux/slices/casesSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';

const CasePage: React.FC = () => {
  const selectedCase = useAppSelector(getSelectedCase);
  const { getUser } = useUser();
  const { caseStatus, caseError } = useAppSelector(state => state.cases);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const caseNumber = new URLSearchParams(window.location.search).get('caseNumber');

  useEffect(() => {
    if (caseNumber && unitId) {
      Promise.all([
        getUser(),
        dispatch(getCase({ unitId, caseNumber })),
        dispatch(getCaseNotes({ unitId,caseNumber })),
      ]);
    }
  }, [caseNumber, unitId]);

  const caseData = useMemo(() => {
    return selectedCase;
  }, [selectedCase]);

  if (caseError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Справа' />
      {caseStatus === 'loading' && <Spinner />}
      {caseData && <CaseView selectedCase={caseData} />}
    </Container>
  );
};

export default CasePage;
