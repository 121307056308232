import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { getCases } from '@/redux/slices/casesSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { CaseData, CasesPagination } from '@/redux/types/case';
import Pagination from '../../_common/Pagination';
import { sxCaseHover, sxCaseRow } from './styles';
import Tooltip from '@/components/_common/Tooltip';
import { useMediaQuery } from 'react-responsive';

interface CasesProps {
  cases: CaseData[];
  pagination: CasesPagination;
}

const Cases: React.FC<CasesProps> = ({ cases, pagination }) => {
  const dispatch = useAppDispatch();
  const { caseError } = useAppSelector(state => state.cases);
  const { page, limit, pagesTotal, totalCount } = pagination || {
    page: 1,
    limit: 50,
    pagesTotal: 1,
    totalCount: 0,
  };
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handlePaginationChange = (newPage: number) => {
    if (unitId) {
      dispatch(getCases({ unitId, page: newPage, limit }));
    }
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    if (unitId) {
      dispatch(getCases({ unitId, page: 1, limit: newLimit }));
    }
  };

  if (caseError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {cases.slice(0, limit).map((caseData) => {
            const handleCaseClick = () => {
              navigate(`${ROUTES.CASE}?caseNumber=${caseData.case_number}`);
            }

            return (
              <TableRow
                key={caseData.id}
                onClick={handleCaseClick}
                sx={isMobile ? {} : [sxCaseHover, sxCaseRow]}
              >
                <TableCell sx={{ width: '20%' }}>
                  <Tooltip title={caseData.description}>
                    <span>
                      {caseData.case_number}
                    </span>
                  </Tooltip>
                </TableCell> 
                <TableCell sx={{ width: '40%' }}>
                  {caseData.judgment}
                </TableCell>
                <TableCell sx={{ width: '10%'}}>
                  <Tooltip title={caseData.judgment_tooltip}>
                    <span>
                      {caseData.judgment_symbol}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: '30%' }}>
                  Суддя: {caseData.judge}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Pagination
        page={page}
        limit={limit}
        pagesTotal={pagesTotal}
        totalItems={totalCount}
        onPageChange={handlePaginationChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </TableContainer>
  )
};

export default Cases;
