export const sxRadioWrapper = {
  flexDirection: 'row',
};

export const sxRadioBtn = {
  mr: '64px',
  '&:last-child': {
    mr: '0px',
  },
  span: {
    fontSize: '14px',
  },
};

export const sxLabel = {
  fontSize: '12px',
  fontWeight: 700,
};
