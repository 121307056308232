import { Box } from '@mui/material';
import React from 'react';

import { sxSidePanelWrapper } from './style';

interface ModalSidePanelProps {
  children: JSX.Element | JSX.Element[];
}

const ModalSidePanel: React.FC<ModalSidePanelProps> = ({ children }) => {
  return <Box sx={sxSidePanelWrapper}>{children}</Box>;
};

export default ModalSidePanel;
