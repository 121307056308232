import * as yup from 'yup';

const reg = /^\d+$/; // regular expression for numbers
const phoneReg = /^\+[1-9]{1}[0-9]{3,14}$/; // regular expression for phone

export const clientSchema = yup.object({
  name: yup
    .string()
    .min(3, 'Мінімум 3 символи')
    .max(50, 'Максимум 50 символів')
    .required('Ім’я обов’язкове для заповнення')
    .uppercase(),
  phone: yup
    .string()
    .matches(phoneReg, 'Вкажіть правильний формат телефону')
    .min(13, 'Вкажіть правильний номер телефону')
    .max(13)
    .required('Заповніть телефон'),
  email: yup
    .string()
    .email('Вкажіть правильну адресу електронної пошти')
    .required('Пошта обов’язкова для заповнення'),
  idCode: yup.string().required('Введіть ваш ІПН'),
  personType: yup.string().required(),
  birthday: yup.string().required('Введіть дату народження'),
  comment: yup.string(),
});

export const clientSchemaMobile = yup.object({
  name: yup
    .string()
    .min(3, '!')
    .max(50, '!')
    .required('!')
    .uppercase(),
  phone: yup
    .string()
    .matches(phoneReg, '!')
    .min(13, '!')
    .max(13)
    .required('!'),
  email: yup
    .string()
    .email('!')
    .required('!'),
  idCode: yup.string().required('!'),
  personType: yup.string().required(),
  birthday: yup.string().required('!'),
  comment: yup.string(),
});

export const authSchema = yup.object({
  email: yup
    .string()
    .email('Вкажіть правильну адресу електронної пошти')
    .required('Пошта обов’язкова для заповнення'),
  password: yup
    .string()
    .required('Пароль обов’язковий для заповнення')
    .min(8, 'Мінімум 8 символів'),
});

export const authSchemaMobile = yup.object({
  email: yup
    .string()
    .email('!')
    .required('!'),
  password: yup
    .string()
    .required('!')
    .min(8, '!'),
});

export const categorySchema = yup.object({
  name: yup
    .string()
    .required('Введіть назву категорії')
    .min(3, 'Мінімум 3 символи'),
});

export const categorySchemaMobile = yup.object({
  name: yup
    .string()
    .required('!')
    .min(3, '!'),
});

export const servicesSchema = yup.object({
  name: yup
    .string()
    .required('Введіть назву послуги')
    .min(3, 'Мінімум 3 символи'),
  description: yup
    .string()
    .required('Опишіть послугу')
    .min(5, 'Мінімум 5 символів'),
  duration: yup
    .string()
    .required('Введіть тривалість послуги')
    .min(1, 'Мінімум 1 символ'),
  price: yup
    .string()
    .matches(reg, 'Лише цифри')
    .required('Введіть ціну послуги')
    .min(1, 'Мінімум 1 символ'),
});

export const servicesSchemaMobile = yup.object({
  name: yup
    .string()
    .required('!')
    .min(3, '!'),
  description: yup
    .string()
    .required('!')
    .min(5, '!'),
  duration: yup
    .string()
    .required('!')
    .min(1, '!'),
  price: yup
    .string()
    .matches(reg, '!')
    .required('!')
    .min(1, '!'),
});

export const unitSchema = yup.object({
  name: yup
    .string()
    .required("Введіть ім'я або назву")
    .min(3, 'Мінімум 3 символи'),
  phone: yup
    .string()
    .matches(phoneReg, 'Вкажіть правильний формат телефону')
    .min(13, 'Вкажіть правильний номер телефону')
    .max(13)
    .required('Заповніть телефон'),
  email: yup
    .string()
    .email('Вкажіть правильну адресу електронної пошти')
    .required('Пошта обов’язкова для заповнення'),
  specialization: yup
    .string()
    .required('Введіть назву посади')
    .min(3, 'Мінімум 3 символи'),
  category: yup
    .string()
    .required('Введіть назву категорії')
    .min(3, 'Мінімум 3 символи'),
  // temporarily hidden  
  // roleId: yup.string().required(),
});

export const unitSchemaMobile = yup.object({
  name: yup
    .string()
    .required('!')
    .min(3, '!'),
  phone: yup
    .string()
    .matches(phoneReg, '!')
    .min(13, '!')
    .max(13)
    .required('!'),
  email: yup
    .string()
    .email('!')
    .required('!'),
  specialization: yup
    .string()
    .required('!')
    .min(3, '!'),
  category: yup
    .string()
    .required('!')
    .min(3, '!'),
  // temporarily hidden  
  // roleId: yup.string().required(),
});

export const servicePackagesSchema = yup.object({
  serviceIds: yup.array().min(1).required('Оберіть послугу'),
  totalPrice: yup
    .string()
    .min(3, 'Мінімум 3 символи')
    .required('Введіть сумму'),
});

export const servicePackagesSchemaMobile = yup.object({
  serviceIds: yup.array().min(1).required('Оберіть послугу'),
  totalPrice: yup
    .string()
    .min(3, '!')
    .required('!'),
});

export const invoicePackagesSchema = yup.object({
  category: yup.string().required('Оберіть категорію'),
  serviceIds: yup.string().required('Оберіть послугу'),
  total: yup
    .string()
    .required('Введіть години')
    .matches(reg, 'Введіть цифри'),
});

export const invoicePackagesSchemaMobile = yup.object({
  category: yup.string().required('Оберіть категорію'),
  serviceIds: yup.string().required('Оберіть послугу'),
  total: yup
    .string()
    .required('!')
    .matches(reg, '!'),
});

export const trackerPackagesSchema = yup.object({
  category: yup.string().required('Оберіть категорію'),
  serviceIds: yup.string().required('Оберіть послугу'),
});

export const requisitesSchema = yup.object({
  organization: yup
    .string()
    .min(3, 'Мінімум 3 символи')
    .max(50, 'Максимум 50 символів')
    .required('Організація обов’язкова для заповнення'),
  edrpou: yup
    .string()
    .min(8, 'Введіть валідний код ЄДРПОУ')
    .max(8)
    .matches(reg, 'Лише цифри')
    .required('Код ЄДРПОУ обов’язковий для заповнення'),
  iban: yup
    .string()
    .min(3, 'Мінімум 3 символи')
    .max(50, 'Максимум 50 символів')
    .required('Код IBAN обов’язковий для заповнення'),
  rnokpp: yup
    .string()
    .min(3, 'Мінімум 3 символи')
    .max(50, 'Максимум 50 символів')
    .matches(reg, 'Лише цифри')
    .required('Код РНОКПП обов’язковий для заповнення'),
  bank: yup
  .string()
  .min(3, 'Мінімум 3 символи')
  .max(50, 'Максимум 50 символів')
  .required('Банк обов’язковий для заповнення'),
});

export const requisitesSchemaMobile = yup.object({
  organization: yup
    .string()
    .min(3, '!')
    .max(50, '!')
    .required('!'),
  edrpou: yup
    .string()
    .min(8, '!')
    .max(8)
    .matches(reg, '!')
    .required('!'),
  iban: yup
    .string()
    .min(3, '!')
    .max(50, '!')
    .required('!'),
  rnokpp: yup
    .string()
    .min(3, '!')
    .max(50, '!')
    .matches(reg, '!')
    .required('!'),
  bank: yup
  .string()
  .min(3, '!')
  .max(50, '!')
  .required('!'),
});

export const trackerIntervalSchema = yup.object().shape({
  startTime: yup
    .date()
    .max(yup.ref('endTime'), 'Початок інтервалу повинен бути менше кінця інтервалу')
    .required("Початок інтервалу обов'язковий"),
  endTime: yup
    .date()
    .min(yup.ref('startTime'), 'Кінець інтервалу повинен бути більше початку інтервалу')
    .required("Кінець інтервалу обов'язковий"),
});

export const trackerIntervalSchemaMobile = yup.object().shape({
  startTime: yup
    .date()
    .max(yup.ref('endTime'), '!')
    .required('!'),
  endTime: yup
    .date()
    .min(yup.ref('startTime'), '!')
    .required('!'),
});
