import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { LOCAL_STORAGE } from '@/constants';
import { connectSocket } from '@/services/socket';

// HOLD
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
//   storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEID,
//   appId: process.env.REACT_APP_FIREBASE_APPID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
// };

// const firebaseConfig = {
//   apiKey: 'AIzaSyDOZSYQAAWaNCx283x8H6I28H7OHpqMFj8',
//   authDomain: 'lunka-booker-stage.firebaseapp.com',
//   projectId: 'lunka-booker-stage',
//   storageBucket: 'lunka-booker-stage.appspot.com',
//   messagingSenderId: '139239764033',
//   appId: '1:139239764033:web:c5bc8013f2cf3a5490d6c0',
//   measurementId: 'G-3HJK22Y3JH'
// };

// const jsonString = JSON.stringify(firebaseConfig);
// const base64String = btoa(unescape(encodeURIComponent(jsonString)));

// console.log("base64String: ", base64String);

const firebaseConfigBase64 = "eyJhcGlLZXkiOiJBSXphU3lET1pTWVFBQVdhTkN4MjgzeDhINkkyOEg3T0hwcU1GajgiLCJhdXR" +
  "oRG9tYWluIjoibHVua2EtYm9va2VyLXN0YWdlLmZpcmViYXNlYXBwLmNvbSIsInByb2plY3RJZCI6Imx1bmthLWJvb2tlci1zdGFnZS" +
  "IsInN0b3JhZ2VCdWNrZXQiOiJsdW5rYS1ib29rZXItc3RhZ2UuYXBwc3BvdC5jb20iLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjEzOTIzO" +
  "Tc2NDAzMyIsImFwcElkIjoiMToxMzkyMzk3NjQwMzM6d2ViOmM1YmM4MDEzZjJjZjNhNTQ5MGQ2YzAiLCJtZWFzdXJlbWVudElkIjoiRy0zSEpLMjJZM0pIIn0=";

const decodedString = decodeURIComponent(escape(atob(firebaseConfigBase64)));

const decodedConfig = JSON.parse(decodedString);

export const app = initializeApp(decodedConfig);
export const auth = getAuth(app);
// export const analytics = getAnalytics(app);

onAuthStateChanged(auth, (user) => {
  if (user) {
    onIdTokenChanged(auth, async (user) => {
      if (user) {
        try {
          const refreshedAccessToken = await user.getIdToken();
        
          localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, refreshedAccessToken);
          connectSocket();      
        } catch (e) {
          window.location.reload();
        }
      }
    });
  } else {
    localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
  }
});
