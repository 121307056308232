import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import { sxAccordionContacts, sxAccordionContactsMobile, sxAccordionSummary, sxAccordionSummaryMobile, sxPhoneAndEmailMobile } from './styles';
import ChipAvatar from '../../../_common/ChipAvatar';
import { Email, LocalPhone } from '@mui/icons-material';
import { Desktop, Mobile } from '@/utils/mediaQuery';

interface CustomAccordionSummaryProps {
  name: string;
  phone: string;
  email: string;
}

const ClientAccordionSummary: React.FC<CustomAccordionSummaryProps> = ({
  name,
  phone,
  email,
}) => {
  return (
    <>
      <Desktop>
        <Box sx={sxAccordionSummary}>
          <ChipAvatar name={name} />
          <Box sx={sxAccordionContacts}>
            <Box display='flex' alignItems='center'>
              <LocalPhone />
              <Typography marginLeft={1}>{phone}</Typography>
            </Box>
            {email && (
              <Box display='flex' alignItems='center' marginTop={1}>
                <Email />
                <Typography marginLeft={1}>{email}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Desktop>

      <Mobile>
        <Box sx={sxAccordionSummaryMobile}>
          <Box sx={sxAccordionContactsMobile}>
            <ChipAvatar name={name} />
            <Box display='flex' alignItems='center' marginTop={1}>
              <LocalPhone />
              <Typography sx={sxPhoneAndEmailMobile}>
                {phone}
              </Typography>
            </Box>
            {email && (
              <Box display='flex' alignItems='center' marginTop={1}>
                <Email />
                <Typography sx={sxPhoneAndEmailMobile}>
                  {email}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Mobile>
    </>
  );
};

export default ClientAccordionSummary;
