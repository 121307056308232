import React from 'react';
import {
  sxAgreeBtn,
  sxDisagreeBtn,
} from '../../../_common/WarningModal/styles';
import DialogActions from '@mui/material/DialogActions';
import Button from '../../../_common/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { postInvitation } from '@/redux/slices/invitationSlice';
import { ROUTES } from '@/constants';

interface InvitationsButtonsProps {
  id: string;
}

const InvitationsButtons: React.FC<InvitationsButtonsProps> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { invitationError } = useAppSelector(state => state.invitations);
  const navigate = useNavigate();
  
  const handleDisagree = () => {
    dispatch(postInvitation({ id, body: { approve: false }}));
    navigate(ROUTES.LOGIN);
  };

  const handleAgree = () => {
    dispatch(postInvitation({ id, body: { approve: true }}));
    navigate(ROUTES.LOGIN);
  };

  if (invitationError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <DialogActions>
      <Button
        sx={sxAgreeBtn}
        onClick={handleDisagree}
        buttonTitle='Відхилити'
      />
      <Button
        sx={sxDisagreeBtn}
        onClick={handleAgree}
        buttonTitle='Прийняти'
      />
    </DialogActions>
  );
};

export default InvitationsButtons;
