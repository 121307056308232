import { useMediaQuery } from 'react-responsive';

export const Mobile = ({ children }: { children: JSX.Element }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
}

export const Desktop = ({ children }: { children: JSX.Element }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
}
