export const COLORS = {
  MAIN: '#455a64',
  SELECTED: '#d4dfe3',
  MAIN_SECONDARY: '#ffffff26',
  MAIN_LIGHT: '#eceff1',
  WHITE: '#ffffff',
  DISABLED: '#c4c4c4',
  LIGHT_GRAY: '#e8e8e8',
  GRAY: '#585858B2',
  DARK: '#161616',
  SUCCESS: '#388e3c',
  SUCCESS_SECONDARY: '#81c784',
  ERROR: '#f44336',
  DELETE: '#e57373',
  DELETE_LIGHT: '#ef9a9a',
  SECONDARY: '#1976d2',
};
