import { ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';

import { persistor, store } from './store';
import { theme } from '@/assets/theme/theme';
import Notification from '@/components/_common/Notification';
import Spinner from '@/components/_common/Spinner';
import TimeTrackerProvider from '@/components/screens/TimeTracker/TimeTrackerProvider';

interface ReduxStoragePersistProps {
  children: JSX.Element[] | JSX.Element;
}

const ReduxStoragePersist: React.FC<ReduxStoragePersistProps> = ({
  children,
}) => {
  return (
    <Provider store={store}>
      <TimeTrackerProvider>
        <ThemeProvider theme={theme}>
          <PersistGate loading={<Spinner />} persistor={persistor}>
            {children}
          </PersistGate>
        </ThemeProvider>
      </TimeTrackerProvider>
      <Notification />
    </Provider>
  );
};

export default ReduxStoragePersist;
