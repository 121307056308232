import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import Button from '../Button';

import { sxAgreeBtn, sxDisagreeBtn } from './styles';

interface WarningModalProps {
  title?: string;
  description?: string;
  open: boolean;
  handleAgree: () => void;
  handleClose: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({
  title = 'Дійсно бажаєте видалити?',
  description,
  handleClose,
  handleAgree,
  open,
}) => {
  return (
    <Box>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle align='center'>{title}</DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant='contained'
            sx={sxAgreeBtn}
            onClick={handleAgree}
            buttonTitle='Так'
          />
          <Button
            variant='contained'
            sx={sxDisagreeBtn}
            onClick={handleClose}
            buttonTitle='Ні'
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WarningModal;
