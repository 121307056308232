import React from 'react';
import { Done, DoNotDisturb, WorkOffOutlined } from '@mui/icons-material';

import { sxActiveStatusBtn, sxBlockedStatusBtn, sxFiredStatusBtn } from '@/components/_common/EditButton/styles';
import Button from '@/components/_common/Button';

export const statusCheck = (status: string) => {
  if (status === 'active') {
    return (
      <Button
        sx={sxActiveStatusBtn}
        endIcon={<Done />}
        disableRipple
        buttonTitle='Активний'
      />
    );
  }

  if (status === 'blocked') {
    return (
      <Button
        sx={sxBlockedStatusBtn}
        endIcon={<DoNotDisturb />}
        color='warning'
        disableRipple
        buttonTitle='Заблокований'
      />
    );
  } else {
    return (
      <Button
        sx={sxFiredStatusBtn}
        endIcon={<WorkOffOutlined />}
        color='error'
        disableRipple
        buttonTitle='Звільнений'
      />
    );
  }
};
