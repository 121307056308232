export const sxInvitationInfo = {
  border: '1px solid #e8e8e8',
  borderRadius: '6px',
  height: '100%',
  mb: 2,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
