import { COLORS } from '@/assets/сolors/colors';

export const sxCaseNote = {
  width: '30%',
  maxHeight: '800px',
  overflowY: 'auto',
  marginRight: '10px',
  border: `1px solid ${COLORS.DISABLED}`
};

export const sxCaseNoteMobile = {
  width: '100%',
  maxHeight: '800px',
  overflowY: 'auto',
  marginRight: '10px',
  border: `1px solid ${COLORS.DISABLED}`
};

export const sxComment = {
  width: '100%',
  boxShadow: '0 4px 12px 0 #e8e8e8',
  padding: '10px',
  marginRight: '1px',
  marginTop: '5px',
  marginLeft: '3px'
};

export const sxEditComment = {
  minWidth: 'calc(100% - 30px)',
  boxShadow: '0 4px 12px 0 #e8e8e8',
  padding: '10px',
};

export const sxCommentBox = {
  padding: '1px',
  display: 'flex',
  marginBottom: '15px',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 1,
};

export const sxCommentSendBtn = {
  background: COLORS.WHITE,
  '&:hover': {
    color: COLORS.SUCCESS,
    background: COLORS.WHITE,
  },
  marginTop: '7px',
};

export const sxCommentEditBtn = {
  background: COLORS.WHITE,
  '&:hover': {
    color: COLORS.SUCCESS,
    background: COLORS.WHITE,
  },
  borderRadius: '50%',
  padding: '0px',
};

export const sxCommentEditDisabledBtn = {
  background: COLORS.WHITE,
  borderRadius: '50%',
  padding: '0px',
  pointerEvents: 'none',
  visibility: 'hidden',
};

export const sxSendCommentBtnIcon = {
  marginLeft: '15px',
  minWidth: '25px',
  minHeight: '25px',
  color: COLORS.MAIN,
  '&:hover': {
    color: COLORS.SUCCESS,
  },
};

export const sxEditCommentBtnIcon = {
  marginLeft: '15px',
  minWidth: '15px',
  minHeight: '15px',
  color: COLORS.MAIN,
  '&:hover': {
    color: COLORS.SUCCESS,
  },
};

export const sxCommentButtonIconDisabled = {
  marginLeft: '15px',
  minWidth: '25px',
  minHeight: '25px',
  color: COLORS.DISABLED,
};

export const sxCard = {
  marginBottom: '15px',
  display: 'flex',
  alignItems: 'center',
  marginRight: '15px',
  marginLeft: '5px'
};

export const sxAvatarBox = {
  display: 'flex',
  justifyContent: 'center',
};

export const sxCommentCreatedBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const sxCommentDateAndName = {
  fontSize: '12px',
  color: COLORS.DISABLED
};

export const sxDeleteBtn = {
  background: COLORS.WHITE,
  ml: '4px',
  padding: '8px',
  span: {
    margin: '0px',
  },
  '&:hover': {
    background: COLORS.WHITE,
  },
  minHeight: '15px',
  marginTop: '5px',
  marginBottom: '5px',
};

export const sxAgreeBtn = {
  padding: '8px',
  background: COLORS.WHITE,
  '&:hover': {
    background: COLORS.WHITE,
  },
  minHeight: '15px',
  marginTop: '5px',
  marginBottom: '5px',
};


export const sxAgreeIcon = {
  color: COLORS.SUCCESS,
};

export const sxDeleteIcon = {
  color: COLORS.ERROR,
};

export const sxAlignButtons = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
