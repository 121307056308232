import { Grid, Typography } from '@mui/material';
import React from 'react';

import { sxText } from './styles';

interface ColumnProps {
  title: string;
  children: JSX.Element;
}

const Column: React.FC<ColumnProps> = ({ title, children }) => {
  return (
    <Grid item xs={6}>
      <Typography sx={sxText}>{title}</Typography>
      {children}
    </Grid>
  );
};

export default Column;
