import Typography from '@mui/material/Typography';
import React from 'react';

interface TitleProps {
  title: string;
}

const Title: React.FC<TitleProps> = ({ title }) => {
  return (
    <Typography
      width='100%'
      mb='32px'
      variant='h4'
      component='h2'
      align='center'
    >
      {title}
    </Typography>
  );
};

export default Title;
