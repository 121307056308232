import { COLORS } from '@/assets/сolors/colors';

export const sxCenterContent = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  overflowX: 'hidden',
  boxShadow: '0',
};

export const sxTable = {
  width: '100%',
  tableLayout: 'fixed',
  '& th:nth-of-type(3), & td:nth-of-type(3)': {
    width: '30%',
  },
};

export const sxRow = {
  'th, td': {
    padding: '16px',
  },
};

export const sxRemoveLine = {
  borderBottom: 'none',
};

export const sxButtonIcon = {
  marginLeft: '10px',
};

export const sxDeleteItem = {
  '&:hover': {
    background: COLORS.DELETE,
  },
};

export const sxEditItem = {
  marginRight: '10px',
};

export const sxClockIcon = {
  color: COLORS.GRAY
}

export const sxDate = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
};
