import React, { useEffect, useState } from 'react';
import { 
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
} from '@mui/material';
import { 
  sxUserHover,
  sxStatusBtn,
  sxStatusBtnMobile,
} from './styles';
import { rootActions, useAppDispatch, useAppSelector } from '@/redux/store';
import Pagination from '../../_common/Pagination';
import { Invoice } from '@/redux/types/invoice';
import moment from 'moment';
import ModalComponent from '../../_common/ModalComponent';
import BillMenu from './BillMenu';
import { ArrowDropDown } from '@mui/icons-material';
import BillStatusSelector from './BillStatusSelector';
import useActionCreators from '@/hooks/useActionCreators';
import Button from '../../_common/Button';
import { getInvoices, getSelectedInvoice } from '@/redux/slices/invoiceSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { PagePagination } from '@/redux/types/pagination';
import { getModalComponentStatus } from '@/redux/slices/modalComponentSlice';
import { useMediaQuery } from 'react-responsive';

type Props = {
  invoices: Invoice[];
  pagination?: PagePagination;
}

const Bills: React.FC<Props> = ({ invoices, pagination }) => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const actions = useActionCreators(rootActions);
  const modalComponentStatus = useAppSelector(getModalComponentStatus);
  const selectedInvoice = useAppSelector(getSelectedInvoice);
  const dispatch = useAppDispatch();
  const { invoiceError } = useAppSelector(state => state.invoice);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { page, limit, pagesTotal, totalCount } = pagination || {
    page: 1,
    limit: 50,
    pagesTotal: 1,
    totalCount: 0,
  };

  useEffect(() => {
    actions.setAppPagination({ page, limit, pagesTotal, totalCount });
  }, [page, limit, pagesTotal, totalCount]);

  const statusTranslations: { [key: string]: string } = {
    new: 'Новий',
    paid: 'Сплачений',
    approved: 'Підтверджений',
    cancelled: 'Відмінений',
  };

  const handlePaginationChange = (newPage: number) => {
      dispatch(getInvoices({ page: newPage, limit }));
  };

  const handleRowsPerPageChange = (newLimit: number) => {
      dispatch(getInvoices({ page: 1, limit: newLimit }));
  };

  const handleInvoiceClick = (invoice: Invoice) => {
    actions.setSelectedInvoice(invoice);
    actions.setModalComponentStatus(true);
  };

  if (invoiceError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Номер</TableCell>
            <TableCell>Дата</TableCell>
            <TableCell>Клієнти</TableCell>
            <TableCell>Сума</TableCell>
            <TableCell>Статус</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices
            .slice(0, limit)
            .map((invoice, index) => {
              const invoiceNumber = (page - 1) * limit + index + 1;
              const invoiceCreatedAt = moment(invoice.createdAt).format('L');
              return (
                <TableRow
                  key={invoice.id}
                  onClick={() => handleInvoiceClick(invoice)}
                  sx={isMobile ? {} : sxUserHover}
                >
                  <TableCell>
                    {invoiceNumber}
                  </TableCell>
                  <TableCell>
                    {invoiceCreatedAt}
                  </TableCell>
                  <TableCell>
                    {invoice.client.name}
                  </TableCell>
                  <TableCell>
                    {invoice.totalSum}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      title='Статус'
                      sx={isMobile ? sxStatusBtnMobile[invoice.status] : sxStatusBtn[invoice.status]}
                      startIcon={<ArrowDropDown />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsStatusModalOpen(true);
                        actions.setSelectedInvoice(invoice);
                      }}
                      buttonTitle={statusTranslations[invoice.status]}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <Pagination
        page={page}
        limit={limit}
        pagesTotal={pagesTotal}
        totalItems={totalCount}
        onPageChange={handlePaginationChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      {selectedInvoice && (
          <ModalComponent
          isOpen={isStatusModalOpen}
          setClose={() => setIsStatusModalOpen(false)}
          title='Оберіть статус'
        >
          <BillStatusSelector
            invoice={selectedInvoice}
            onSubmit={() => setIsStatusModalOpen(false)}
          />
        </ModalComponent>
      )}
      
      <ModalComponent
        isOpen={modalComponentStatus}
        setClose={() => actions.setModalComponentStatus(false)}
        title='Інвойс'
      >
        <BillMenu invoice={selectedInvoice} />
      </ModalComponent>
    </TableContainer>
  );
}

export default Bills;
