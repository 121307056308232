import { Tooltip as MUITooltip } from '@mui/material';
import React, { ReactNode } from 'react';

interface TooltipProps {
  title: ReactNode;
  children: JSX.Element;
  open?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ title, children, open }) => {
  return (
      <MUITooltip title={title} open={open}>
        {children}
      </MUITooltip>
  );
};

export default Tooltip;
