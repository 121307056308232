import { Typography } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import React from 'react';

import { sxErrorMessage } from './styles';


interface FormikErrorMessageProps {
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>; // fix this any type [marker]
  name: string;
}

const ErrorMessage: React.FC<FormikErrorMessageProps> = ({
  errors,
  touched,
  name,
}) => {
  return (
    errors[name] && touched[name] ? (
      <Typography sx={sxErrorMessage}>{`${errors[name]}`}</Typography>
    ) : null
  );
};

export default ErrorMessage;
