export const sxAppBarMenuWrapper = {
  minWidth: '280px',
  height: '100%',
  paddingTop: '0px',
  paddingBottom: '16px',
};

export const sxAppBarMenuWrapperMobile = {
  width: '100vh',
  height: '100%',
  paddingTop: '0px',
  paddingBottom: '16px',
};

export const sxAppBarMenuContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};
