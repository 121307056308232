import React from 'react';
import { OrganizationUnit } from '@/redux/types/user';
import Title from '../../../../_common/Title';

const InvitationOrganization: React.FC<OrganizationUnit> = ({ name }) => {
  const companyName = `Компанія ${name}`;
  return <Title title={companyName} />;
};

export default InvitationOrganization;
