import React from 'react';
import { servicePackagesSchema, servicePackagesSchemaMobile } from './constants/schema';
import { servicePackagesInitialValues } from './constants/initialValues';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Box } from '@mui/material';
import FormSelect from '@/components/ui/FormSelect/FormSelect';
import BasicTextField from '@/components/_common/BasicTextField';
import AcceptBlock from '@/components/_common/AcceptBlock';
import { CategoryData } from '@/redux/types/category';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { sxServicePackages } from './styles';
import { ClientServicePackage, SelectedClient } from '@/redux/types/client';
import {
  createSelectCategoryOptions,
  createServiceOptions,
} from '@/utils/createSelectorOptions';
import { getSelectedCategories, setSelectedCategories } from '@/redux/slices/categoriesSlice';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { getClient } from '@/redux/slices/clientsSlice';
import { useNavigate } from 'react-router-dom';
import { postServicePackages } from '@/redux/slices/servicesSlice';
import { useMediaQuery } from 'react-responsive';

interface ServicePackageFormProps {
  categories: CategoryData[];
  setIsOpen: (arg: boolean) => void;
  client: SelectedClient;
}

const ServicePackageForm: React.FC<ServicePackageFormProps> = ({
  categories,
  setIsOpen,
  client,
}) => {
  const dispatch = useAppDispatch();
  const selectedCategories = useAppSelector(getSelectedCategories) || [];
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const { clientError } = useAppSelector(state => state.clients);
  const { serviceError } = useAppSelector(state => state.services);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const servicePackageObject: ClientServicePackage = {
    serviceIds: client.servicePackages.length > 0 ? [...client.servicePackages[client.servicePackages.length - 1].services.map(service => service.id)] : [],
    totalPrice: client.servicePackages.length > 0 ? client.servicePackages[client.servicePackages.length - 1].totalPrice : 0,
  }

  const onSubmit = async (values: ClientServicePackage, helpers: FormikHelpers<ClientServicePackage> ) => {
    helpers.setTouched({});
    helpers.setSubmitting(false);
    if (unitId) {
      await dispatch(postServicePackages({unitId, clientId: client.id, body: {
        serviceIds: values.serviceIds,
        totalPrice: Number(values.totalPrice),
      }}));
      await dispatch(getClient({ unitId, clientId: client.id }));
      dispatch(setSelectedCategories(null));
      setIsOpen(false);
    }
  };

  if (clientError || serviceError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Formik
      validationSchema={isMobile ? servicePackagesSchemaMobile : servicePackagesSchema}
      initialValues={client.servicePackages.length > 0 ? servicePackageObject : servicePackagesInitialValues }
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        isValid,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box sx={sxServicePackages}>
              <Box>
                <Field
                  isMulti
                  name='categories'
                  placeholder='Оберіть категорії'
                  component={FormSelect}
                  options={createSelectCategoryOptions(categories)}
                />
                {selectedCategories.length > 0 && (
                  <Field
                    isMulti
                    name='serviceIds'
                    placeholder='Оберіть послуги'
                    component={FormSelect}
                    options={createServiceOptions(selectedCategories)}
                  />
                )}
                <Box position='relative'>
                  <BasicTextField
                    errors={errors}
                    touched={touched}
                    values={values}
                    name='totalPrice'
                    maxLength={7}
                    label='Сумма'
                    placeholder='Сумма'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Box>
              </Box>
              <AcceptBlock
                variant='contained'
                disabled={!isValid}
                handleAgree={handleSubmit}
                handleDisagree={() => setIsOpen(false)}
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ServicePackageForm;
