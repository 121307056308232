import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import ServiceItems from './ServiceItems';
import { CategoryData } from '@/redux/types/category';
import { ServiceData } from '@/redux/types/service';
import Column from '../../_common/Column';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { deleteCategory, getCategories, setSelectedCategory } from '@/redux/slices/categoriesSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { deleteService, getServices } from '@/redux/slices/servicesSlice';
import { useNavigate } from 'react-router-dom';
import { Desktop, Mobile } from '@/utils/mediaQuery';

interface ServicesProps {
  categories: CategoryData[];
  services: ServiceData[];
}

const Services: React.FC<ServicesProps> = ({
  categories,
  services,
}) => {
  const dispatch = useAppDispatch();
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const navigate = useNavigate();
  const { categoryError } = useAppSelector(state => state.categories);
  const { serviceError } = useAppSelector(state => state.services);

  const [categoryInputVisible, setCategoryInputVisible] =
    useState<boolean>(false);
  const [serviceInputVisible, setServiceInputVisible] =
    useState<boolean>(false);

  const deleteCategoryHandler = async (categoryId: string) => {
    if (unitId) {
      await dispatch(deleteCategory({ unitId, categoryId }));
      await dispatch(getCategories(unitId));
      dispatch(setSelectedCategory(null));
    }
  };

  const deleteServiceHandler = async (serviceId: string) => {
    if (unitId) {
      await dispatch(deleteService({ unitId, serviceId }));
      await dispatch(getCategories(unitId));
      await dispatch(getServices(unitId));
    }
  };

  if (categoryError || serviceError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <>
      <Desktop>
        <Grid paddingBottom='60px' container spacing={2}>
          <Column title='Категорія'>
            <ServiceItems
              btnTitle='Додати категорію'
              data={categories}
              isInputVisible={categoryInputVisible}
              setInputVisible={setCategoryInputVisible}
              selectable={true}
              deleteHandler={deleteCategoryHandler}
            />
          </Column>
          <Column title='Сервіси'>
            <ServiceItems
              btnTitle='Додати сервіс'
              data={services}
              isInputVisible={serviceInputVisible}
              setInputVisible={setServiceInputVisible}
              otherEditProps={true}
              deleteHandler={deleteServiceHandler}
            />
          </Column>
        </Grid>
      </Desktop>

      <Mobile>
        <Box sx={{ marginBottom: '15px' }}>
          <Typography
            width='100%'
            mb='20px'
            variant='h5'
            component='h3'
            align='center'
          >
            Категорія
          </Typography>
          <ServiceItems
            btnTitle='Додати категорію'
            data={categories}
            isInputVisible={categoryInputVisible}
            setInputVisible={setCategoryInputVisible}
            selectable={true}
            deleteHandler={deleteCategoryHandler}
          />
          <Typography
            width='100%'
            mb='20px'
            mt='20px'
            variant='h5'
            component='h3'
            align='center'
          >
            Сервіси
          </Typography>
          <ServiceItems
            btnTitle='Додати сервіс'
            data={services}
            isInputVisible={serviceInputVisible}
            setInputVisible={setServiceInputVisible}
            otherEditProps={true}
            deleteHandler={deleteServiceHandler}
          />
        </Box>
      </Mobile>
    </>
  );
};

export default Services;
