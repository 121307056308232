import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import Spinner from '@/components/_common/Spinner';
import Title from '@/components/_common/Title';
import Bills from '@/components/screens/Bills';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { getAllInvoices, getInvoices } from '@/redux/slices/invoiceSlice';
import { getCategories } from '@/redux/slices/categoriesSlice';
import { getServices } from '@/redux/slices/servicesSlice';

const BillsPage: React.FC = () => {
  const { invoices } = useAppSelector(getAllInvoices);
  const navigate = useNavigate();
  const { invoiceStatus, invoiceError } = useAppSelector(state => state.invoice);
  const { categoryError } = useAppSelector(state => state.categories);
  const { serviceError } = useAppSelector(state => state.services);
  const dispatch = useAppDispatch();
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);

  useEffect(() => {
    if (unitId) {
      Promise.all([
        dispatch(getCategories(unitId)),
        dispatch(getServices(unitId)),
        dispatch(getInvoices({})),
      ])
    }
  }, [unitId]);

  const invoicesData = useMemo(() => {
    return invoices;
  }, [invoices]);

  if (invoiceError || categoryError || serviceError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Рахунки' />
      {invoiceStatus === 'loading' && <Spinner />}
      {invoicesData && invoicesData.data.length > 0 && invoicesData.pagination && 
        <Bills invoices={invoicesData.data} pagination={invoicesData.pagination} />}
    </Container>
  );
};

export default BillsPage;
