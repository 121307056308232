export const clientFields = [
  {
    label: 'ПІБ',
    placeholder: 'Іванов Іван Іванович',
    name: 'name',
    maxLength: 50,
  },
  {
    label: 'Телефон',
    placeholder: '+380xx-xxx-xx-xx',
    name: 'phone',
    maxLength: 13,
  },
  {
    label: 'Email',
    placeholder: 'mail@mail.com',
    name: 'email',
    maxLength: 50,
  },
  {
    label: 'ІПН',
    placeholder: '10 цифр',
    name: 'idCode',
    maxLength: 10,
  },
  {
    label: 'Дата народження',
    placeholder: '1990-01-01',
    name: 'birthday',
    maxLength: 10,
  },
  {
    label: 'Примітка',
    placeholder: '',
    name: 'comment',
    maxLength: 50,
  },
];

export const businessFields = [
  {
    label: 'Юридична особа',
    value: 'juridical',
  },
  {
    label: 'Приватна особа',
    value: 'private',
  },
];
