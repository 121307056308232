import { io } from 'socket.io-client';
import { WS_BASE_URL } from '@/constants/api';

export const socket = io(WS_BASE_URL, {
  transports: ['websocket'],
  auth: {
    token: localStorage.getItem('accessToken'),
  },
});

socket.on('connect', () => {
  console.info('Socket connected');
});

socket.on('disconnect', () => {
  console.info('Socket disconnected');
});

socket.on('error', (err: Error) => {
  console.log(`Socker errror`, err);
});

export const connectSocket = () => {
  socket.disconnect();
  socket.auth = {
    token: localStorage.getItem('accessToken'),
  };

  socket.connect();
}; 
