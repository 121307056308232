import React, { useEffect, useState } from 'react';
import { rootActions, useAppDispatch, useAppSelector } from '@/redux/store';
import AccordionComponent from '../../_common/AccordionComponent/AccordionComponent';
import TimeTrackerAccordionDetails from './TimeTrackerAccordionDetails.tsx';
import TimeTrackerAccordionSummary from './TimeTrackerAccordionSummary';
import { Tracker } from '@/redux/types/tracker';
import ModalComponent from '../../_common/ModalComponent';
import TimeTrackerLawyerSelector from './TimeTrackerLawyerSelector';
import useActionCreators from '@/hooks/useActionCreators';
import { PagePagination } from '@/redux/types/pagination';
import Pagination from '../../_common/Pagination';
import { deleteTracker, getSelectedTracker, getTrackers } from '@/redux/slices/trackerSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { getModalComponentStatus } from '@/redux/slices/modalComponentSlice';
import { Box } from '@mui/material';
import { sxTimeTrackerWrapper, sxTimeTrackerWrapperMobile } from './styles';
import { useMediaQuery } from 'react-responsive';

interface TimeTrackerProps {
  accordionData: Tracker[];
  pagination?: PagePagination;
}

const TimeTracker: React.FC<TimeTrackerProps> = ({
  accordionData,
  pagination,
}) => {
  const { page, limit, pagesTotal, totalCount } = pagination || {
    page: 1,
    limit: 50,
    pagesTotal: 1,
    totalCount: 0,
  };
  const tracker = useAppSelector(getSelectedTracker);
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const actions = useActionCreators(rootActions);
  const modalComponentStatus = useAppSelector(getModalComponentStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackerError } = useAppSelector(state => state.tracker);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    actions.setAppPagination({ page, limit, pagesTotal, totalCount });
  }, [page, limit, pagesTotal, totalCount]);

  const accordionOpen =
    (panel: string, tracker: Tracker) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (!modalComponentStatus) {
        setExpanded(isExpanded ? panel : false);
        actions.setSelectedTracker(tracker);
      } else {
        setExpanded(false)
      }
  };

  const handleModalOpenChange = (isOpen: boolean) => {
    actions.setModalComponentStatus(isOpen);
  };

  const deleteTrackerHandler = async () => {
    if (tracker) {
      await dispatch(deleteTracker(tracker.id));
      await dispatch(getTrackers({ page, limit }));
    }
  };

  const handlePaginationChange = (newPage: number) => {
    dispatch(getTrackers({ page: newPage, limit }));
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    dispatch(getTrackers({ page: 1, limit: newLimit }));
  };

  if (trackerError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={isMobile ? sxTimeTrackerWrapperMobile : sxTimeTrackerWrapper}>
    {accordionData
      .slice(0, limit)
      .map((tracker, index) => {
      const id = tracker?.id;

      return (
        <AccordionComponent
          sx={{ width: '100%' }}
          id={id}
          expanded={expanded === id}
          handleAccordionExpandChange={accordionOpen(id, tracker)}
          accordionSummaryChildren={
            <TimeTrackerAccordionSummary
              trackerSummary={tracker}
              onModalOpenChange={handleModalOpenChange}
            />
          }
          ariaControls={id}
          key={index}
          accordionDetailsChildren={
            <TimeTrackerAccordionDetails
              deleteTrackerAccordion={deleteTrackerHandler}
              trackerAccordion={tracker}
            />
          }
        />
      );
    })}
      {tracker && (
        <ModalComponent
        isOpen={modalComponentStatus}
        setClose={() => handleModalOpenChange(false)}
        title='Змінити адвоката'
      >
        <TimeTrackerLawyerSelector
          tracker={tracker}
          onSubmit={() => handleModalOpenChange(false)}
        />
      </ModalComponent>
    )}
    <Pagination
      page={page}
      limit={limit}
      pagesTotal={pagesTotal}
      totalItems={totalCount}
      onPageChange={handlePaginationChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  </Box>
  );
};

export default TimeTracker;
