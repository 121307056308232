import { Container } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Search from '@/components/screens/Search';
import Spinner from '@/components/_common/Spinner';

import Title from '@/components/_common/Title';
import { ROUTES } from '@/constants';
import { useAppSelector } from '@/redux/store';

const SearchPage: React.FC = () => {
  const { searchStatus, searchError } = useAppSelector(state => state.search);
  const navigate = useNavigate();

  if (searchError) {
    navigate(ROUTES.ERROR);
  }
  
  return (
    <Container>
      <Title title='Пошук' />
      {searchStatus === 'loading' && <Spinner />}
      <Search />
    </Container>
  );
};

export default SearchPage;
