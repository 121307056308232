export const sxInvitationCard = {
  boxShadow: '0 4px 20px 0 #e8e8e8',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '8px',
  maxWidth: '720px',
  margin: '0 auto',
  minHeight: '280px',
  p: 4,
};
