export const sxConfirmBtn = {
  marginTop: '15px',
  minWidth: '240px',
};

export const sxSelectStatus = {
  width: '100%',
};

export const sxSelectDate = {
  minWidth: '240px',
  marginTop: '10px',
};

export const sxSelectDateMobile = {
  minWidth: '100%',
  marginTop: '10px',
};
