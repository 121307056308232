import React, { useState } from 'react';
import { rootActions, useAppDispatch, useAppSelector } from '@/redux/store';
import AccordionComponent from '../../_common/AccordionComponent/AccordionComponent';
import TimeTrackerAccordionDetails from './TimeTrackerAccordionDetails.tsx';
import TimeTrackerAccordionSummary from './TimeTrackerAccordionSummary';
import { Tracker } from '@/redux/types/tracker';
import ModalComponent from '../../_common/ModalComponent';
import TimeTrackerLawyerSelector from './TimeTrackerLawyerSelector';
import useActionCreators from '@/hooks/useActionCreators';
import { deleteTracker, getSelectedTracker, getTrackers } from '@/redux/slices/trackerSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { getModalComponentStatus } from '@/redux/slices/modalComponentSlice';
import { Box } from '@mui/material';
import { sxTimeTrackerWrapper, sxTimeTrackerWrapperMobile } from './styles';
import { useMediaQuery } from 'react-responsive';

interface ActiveTimeTrackerProps {
  accordionData: Tracker[];
}

const ActiveTimeTracker: React.FC<ActiveTimeTrackerProps> = ({
  accordionData,
}) => {
  const tracker = useAppSelector(getSelectedTracker);
  const [expanded, setExpanded] = useState<boolean>(true);
  const actions = useActionCreators(rootActions);
  const modalComponentStatus = useAppSelector(getModalComponentStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackerError } = useAppSelector(state => state.tracker);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const accordionOpen =
    (panel: string, tracker: Tracker) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (modalComponentStatus) {
        setExpanded(true)
      } else {
        setExpanded(isExpanded);
        actions.setSelectedTracker(tracker);
      }
  };

  const handleModalOpenChange = (isOpen: boolean) => {
    actions.setModalComponentStatus(isOpen);
  };

  const deleteTrackerHandler = async () => {
    if (tracker) {
      await dispatch(deleteTracker(tracker.id));
      await dispatch(getTrackers({}));
    }
  };

  if (trackerError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={isMobile ? sxTimeTrackerWrapperMobile : sxTimeTrackerWrapper}>
      {accordionData
        .map((tracker, index) => {
        const id = tracker?.id;
        
        return (
          <AccordionComponent
            sx={{ width: '100%' }}
            id={id}
            expanded={expanded}
            handleAccordionExpandChange={accordionOpen(id, tracker)}
            accordionSummaryChildren={
              <TimeTrackerAccordionSummary
                trackerSummary={tracker}
                onModalOpenChange={handleModalOpenChange}
              />
            }
            ariaControls={id}
            key={index}
            accordionDetailsChildren={
              <TimeTrackerAccordionDetails
                deleteTrackerAccordion={deleteTrackerHandler}
                trackerAccordion={tracker}
              />
            }
          />
        );
      })}
        {tracker && (
          <ModalComponent
          isOpen={modalComponentStatus}
          setClose={() => handleModalOpenChange(false)}
          title='Змінити адвоката'
        >
          <TimeTrackerLawyerSelector
            tracker={tracker}
            onSubmit={() => handleModalOpenChange(false)}
          />
        </ModalComponent>
      )}
    </Box>
  );
};

export default ActiveTimeTracker;
