import { COLORS } from '@/assets/сolors/colors';

export const sxInvitationLinkBox = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
  width: '100%'
};

export const sxCopyLinkBtn = {
  marginTop: '15px',
  minWidth: '240px',
};

export const sxInviteBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};
