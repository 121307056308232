import { COLORS } from '@/assets/сolors/colors';

export const sxForm: object = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '12px',
  boxSizing: 'border-box',
};

export const sxClientForm: object = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

export const sxAuthForm: object = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  margin: '0px auto',
  maxWidth: '640px',
  boxShadow: '0 4px 12px 0 #e8e8e8',
  padding: '32px',
  boxSizing: 'border-box',
  borderRadius: '8px',
};

export const sxFieldWrapper = {
  mb: '24px',
  width: '93%'
};

export const sxBtnWrapper = {
  button: {
    mt: '24px',
  },
  a: {
    ml: '12px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
};

export const sxServicePackages = {
  minHeight: '460px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const sxShowPasswordIcon = {
  color: COLORS.MAIN,
  '&:hover': {
    color: COLORS.SUCCESS,
    background: COLORS.WHITE,
  },
  marginTop: '75px',
};

export const sxBasicTextFieldBox = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};
