import { Box, List, ListItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { SelectedClient } from '@/redux/types/client';
import SearchIcon from '@mui/icons-material/Search';
import { sxCaseBox, sxCaseNotFound, sxListItem, sxSearch, sxSearchBox, sxSearchButtonIcon, sxSearchButtonIconMobile, sxSearchMobile } from './style';
import { getCase, getSelectedCase, postCase, setSelectedCase } from '@/redux/slices/casesSlice';
import AcceptBlock from '../../../_common/AcceptBlock';
import { CasePerson } from '@/redux/types/case';
import Button from '../../../_common/Button';
import { useNavigate } from 'react-router-dom';
import { getClient } from '@/redux/slices/clientsSlice';
import { useMediaQuery } from 'react-responsive';

interface AddCaseProps {
  setIsOpen: (arg: boolean) => void;
  client: SelectedClient;
}

const AddCase: React.FC<AddCaseProps> = ({ setIsOpen, client }) => {
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const selectedCase = useAppSelector(getSelectedCase);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const dispatch = useAppDispatch();
  const { caseError } = useAppSelector(state => state.cases);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleAddCase = async () => {
    if (unitId && selectedCase) {
      await dispatch(postCase({unitId, body: {
        clientId: client.id,
        caseNumber: selectedCase.caseNumber,
        judgmentCode: selectedCase.opendata.judgment_code,
      }}));
      setIsOpen(false);
      await dispatch(getClient({ unitId, clientId: client.id }))
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };

  const handleSearchClick = async () => {
    if (searchPhrase === '') {
      dispatch(setSelectedCase(null));
      setSearchPerformed(false);
      return;
    }
    if (unitId) {
      await dispatch(getCase({ unitId, caseNumber: searchPhrase }));
      setSearchPerformed(true);
    }
  }

  if (caseError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box>
      <Box sx={sxSearchBox}>
        <TextField
          value={searchPhrase}
          onChange={handleSearchChange}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              handleSearchClick();
            }
          }}
          sx={isMobile ? sxSearchMobile : sxSearch}
          placeholder='Введіть значення...'
        />
        <Button
          variant='contained'
          title='Пошук'
          startIcon={<SearchIcon sx={isMobile ? sxSearchButtonIconMobile : sxSearchButtonIcon} />}
          onClick={handleSearchClick}
        />
      </Box>
      {selectedCase ? (
        <Box sx={sxCaseBox}>
          <Typography mb='10px'>
            Номер справи: {selectedCase.opendata.number}
          </Typography>
          <Typography>
            Опис: {selectedCase.opendata.description}
          </Typography>
          <Typography mb='10px'>
            Суд: {selectedCase.opendata.court_name}
          </Typography>
          <Typography mb='10px'>
            Останній статус: {selectedCase.opendata.last_status}
          </Typography>
          <Box>
            <List>
              Позивачі: {selectedCase.opendata.plaintiffs && selectedCase.opendata.plaintiffs.map((plaintiff: CasePerson, index: number) => (
              <ListItem key={index} sx={sxListItem}>
                {index + 1}.&nbsp;{plaintiff.name}
              </ListItem>
            ))}
            </List>
          </Box>
          <Box>
            <List>
              Відповідачі: {selectedCase.opendata.defendants && selectedCase.opendata.defendants.map((defendant: CasePerson, index: number) => (
                <ListItem key={index} sx={sxListItem}>
                  {index + 1}.&nbsp;{defendant.name}
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      ) : (
        searchPerformed ? (
          <Typography sx={sxCaseNotFound}>
            Справу не знайдено
          </Typography>
        ) : ''
      )}
      <AcceptBlock
        variant='contained'
        disabled={!selectedCase}
        handleAgree={handleAddCase}
        handleDisagree={() => setIsOpen(false)}
      />
    </Box>
  )
};

export default AddCase;
