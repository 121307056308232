import { COLORS } from '@/assets/сolors/colors';

export const sxClientInformationBtnWrapper = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const sxModalClientWrapper = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
};

export const sxEditField = {
  input: {
    padding: '8px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.LIGHT_GRAY,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
};

export const sxCasesScroll = {
  maxHeight: '260px',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
};

export const sxRemoveLine = {
  borderBottom: 'none',
  padding: '10px',
};

export const sxButtonItem = {
  borderBottom: 'none',
  textAlign: 'right',
};

export const sxCaseBox = {
  border: `1px solid ${COLORS.MAIN}`,
};

export const sxCaseButtonIcon = {
  marginLeft: '10px',
};
