export const sxCaseRow = {
  'th, td': {
    padding: '24px',
  },
};

export const sxCaseCell = {
  padding: '0px',
};

export const sxCaseHover = {
  cursor: 'pointer', 
};

export const sxClientBtn = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mb: '12px',
};

export const sxCaseBox = {
  display: 'flex',
  mb: '30px',
};

export const sxCaseBoxMobile = {
  display: 'flex',
  mb: '30px',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '100%'
};
