import { Container } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import Title from '@/components/_common/Title';
import Clients from '@/components/screens/Clients';
import useUser from '@/hooks/useUser';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { getAllClients, getClients } from '@/redux/slices/clientsSlice';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '@/redux/slices/categoriesSlice';
import Spinner from '@/components/_common/Spinner';

const ClientsPage: React.FC = () => {
  const { getUser } = useUser();
  const { clients } = useAppSelector(getAllClients);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const dispatch = useAppDispatch();
  const { clientStatus, clientError } = useAppSelector(state => state.clients);
  const { categoryError } = useAppSelector(state => state.categories);
  const navigate = useNavigate();

  useEffect(() => {
      if (unitId) {
        Promise.all([
          getUser(),
          dispatch(getClients({ unitId })),
          dispatch(getCategories(unitId)),
        ])
      }
  }, [unitId]);

  const clientsData = useMemo(() => {
    return clients;
  }, [clients]);

  if (clientError || categoryError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Container>
      <Title title='Клієнти' />
      {clientStatus === 'loading' && <Spinner />}
      {clientsData && clientsData.data.length > 0 && clientsData.pagination && (
        <Clients accordionData={clientsData.data} pagination={clientsData.pagination} />
      )}
    </Container>
  );
};

export default ClientsPage;
