import React from 'react';
import Button from '@/components/_common/Button';

import { sxBtn } from '@/components/_common/Column/styles';
import { Add } from '@mui/icons-material';

interface FormWrapperProps {
  isInputVisible?: boolean;
  setInputVisible?: (arg: boolean) => void;
  children: JSX.Element;
  btnTitle: string;
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  setInputVisible,
  isInputVisible,
  btnTitle,
  children,
}) => {
  const showInputHandler = () => {
    if (setInputVisible) {
      setInputVisible(true);
    }
  };

  return (
    <>
      {isInputVisible && children}
      {!isInputVisible && (
        <Button
          onClick={showInputHandler}
          sx={sxBtn}
          buttonTitle={btnTitle}
          startIcon={<Add />}
        />
      )}
    </>
  );
};

export default FormWrapper;
