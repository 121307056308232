import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { sxConfirmBtn, sxLawyerSelectorBox, sxSelectLawyer, sxTextStyles } from './styles';
import { Tracker } from '@/redux/types/tracker';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { UnitUserData } from '@/redux/types/unitUsers';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import Button from '../../../_common/Button';
import { getAppPagination } from '@/redux/slices/PaginationSlice';
import { useNavigate } from 'react-router-dom';
import { editTracker, getTracker, getTrackers } from '@/redux/slices/trackerSlice';
import { getAllUnitUsers } from '@/redux/slices/unitUsersSlice';

type Props = {
  tracker: Tracker;
  onSubmit: () => void;
};

const TimeTrackerLawyerSelector: React.FC<Props> = ({ tracker, onSubmit }) => {
  const [selectedLawyer, setSelectedLawyer] = useState<UnitUserData | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { page, limit } = useAppSelector(getAppPagination);
  const listofLawyers = useAppSelector(getAllUnitUsers);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackerError } = useAppSelector(state => state.tracker);

  const handleLawyerChange = (e: React.ChangeEvent<unknown>, value: UnitUserData | null) => {
    setSelectedLawyer(value);
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredLawyers = listofLawyers && listofLawyers.filter((lawyer) =>
    lawyer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = async () => {
    if (selectedLawyer) {
      try {
        if (unitId) {
          await dispatch(editTracker({ unitId, body: {
            id: tracker.id,
            unitUserId: selectedLawyer.id,
            courtCase: null,
          }}));
        }
        await dispatch(getTrackers({ page, limit }));
        await dispatch(getTracker(tracker.id));
      } catch (error) {
        console.error('Failed to update lawyer: ', error);
      }
      onSubmit();
    }
  };

  if (trackerError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={sxLawyerSelectorBox}>
      <Autocomplete
        sx={sxSelectLawyer}
        value={selectedLawyer}
        onChange={handleLawyerChange}
        options={filteredLawyers}
        getOptionLabel={(lawyer) => lawyer?.name || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Оберіть адвоката'
            variant='outlined'
            onChange={handleSearchTermChange}
            color='success'
            sx={sxTextStyles}
          />
        )}
      />
      <Button
        variant='contained'
        onClick={handleSubmit}
        sx={sxConfirmBtn}
        buttonTitle='Пiдтвердити вибір'
      />
    </Box>
  );
};

export default TimeTrackerLawyerSelector;
