import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Select, TextField, SelectChangeEvent } from '@mui/material';
import { Invoice } from '@/redux/types/invoice';
import { sxConfirmBtn, sxSelectDate, sxSelectDateMobile, sxSelectStatus } from './styles';
import Button from '../../../_common/Button';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { editInvoice, getInvoices } from '@/redux/slices/invoiceSlice';
import { ROUTES } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { getAppPagination } from '@/redux/slices/PaginationSlice';
import { useMediaQuery } from 'react-responsive';

interface BillStatusSelectorProps {
  invoice: Invoice;
  onSubmit: () => void;
}

const BillStatusSelector: React.FC<BillStatusSelectorProps> = ({ invoice, onSubmit }) => {
  const [status, setStatus] = useState< unknown >(invoice.status);
  const [paidAt, setPaidAt] = useState('');
  const dispatch = useAppDispatch();
  const { invoiceError } = useAppSelector(state => state.invoice);
  const navigate = useNavigate();
  const { page, limit } = useAppSelector(getAppPagination);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (status === 'paid' && !paidAt) {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];
      
      setPaidAt(formattedDate);
    }
  }, [status, paidAt]);

  const handleStatusChange = (e: SelectChangeEvent< unknown >) => {
    const selectedStatus = e.target.value as string;
    setStatus(selectedStatus);

    if (selectedStatus !== 'paid') {
      setPaidAt('');
    }
  };

  const handlePaidAtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaidAt(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await dispatch(editInvoice({ id: invoice.id, status, paidAt }));
      await dispatch(getInvoices({ page, limit }));
    } catch (error) {
      console.error('Failed to update invoice status:', error);
    }
    onSubmit();
  };

  if (invoiceError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
      <Select
        value={status}
        onChange={(e) => handleStatusChange(e)}
        sx={sxSelectStatus}
      >
        <MenuItem value='new'>Новий</MenuItem>
        <MenuItem value='paid'>Сплачений</MenuItem>
        <MenuItem value='approved'>Підтверджений</MenuItem>
        <MenuItem value='cancelled'>Відмінений</MenuItem>
      </Select>
      {status === 'paid' && (
        <>
          <TextField
            label='Дата оплати'
            value={paidAt}
            onChange={handlePaidAtChange}
            type='date'
            sx={isMobile ? sxSelectDateMobile : sxSelectDate}
            InputLabelProps={{ shrink: true }}
          />
        </>
      )}
      <Button
        variant='contained'
        onClick={handleSubmit}
        sx={sxConfirmBtn}
        buttonTitle='Підтвердити вибір'
      />
    </Box>
  );
};

export default BillStatusSelector;
