/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { CaseNoteData } from '../types/case';
import { setSuccess } from './notificationSlice';

export const postCaseNote = createAsyncThunk(
  'caseNote/postCaseNote',
  async ({ unitId, caseNumber, body }: { unitId: string, caseNumber: string, body: any }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.COURT_CASES}/${encodeURIComponent(caseNumber)}${API_ENDPOINTS.NOTES}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні коментаря для справи!');
      }

      dispatch(setSuccess('Коментар додано успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getCaseNotes = createAsyncThunk(
  'caseNote/getCaseNotes',
  async ({ unitId, caseNumber }: { unitId: string, caseNumber: string }, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.COURT_CASES}/${encodeURIComponent(caseNumber)}${API_ENDPOINTS.NOTES}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку коментарів для справи!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const putCaseNote = createAsyncThunk(
  'caseNote/putCaseNote',
  async ({ unitId, caseNumber, noteId, body }: { unitId: string, caseNumber: string, noteId: string, body: any }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.COURT_CASES}/${encodeURIComponent(caseNumber)}${API_ENDPOINTS.NOTES}/${noteId}`;
      const res = await Http.put(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при редагуванні коментаря справи!');
      }

      dispatch(setSuccess('Коментар оновлено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.caseNoteStatus = 'loading';
  state.caseNoteError = null;
}

const setCaseNotesResolved = (state: any, action: any) => {
  state.caseNoteStatus = 'resolved';
  state.caseNotes = action.payload;
}

const setResolved = (state: any) => {
  state.caseNoteStatus = 'resolved';
}

const ErrorCase = (state: any, action: any) => {
  state.caseNoteStatus = 'rejected';
  state.caseNoteError = action.payload;
}

const initialState: CaseNoteData = {
  caseNotes: [],
  caseNoteStatus: null,
  caseNoteError: null,
};

const caseNoteSlice = createSlice({
  name: 'caseNote',
  initialState,
  reducers: {
    setCaseNotes: (state, action) => {
      state.caseNotes = action.payload;
    },
    setCaseNoteError: (state, action) => {
      state.caseNoteError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCaseNote.pending, setLoading)
      .addCase(getCaseNotes.pending, setLoading)
      .addCase(putCaseNote.pending, setLoading)
      .addCase(postCaseNote.fulfilled, setResolved)
      .addCase(getCaseNotes.fulfilled, setCaseNotesResolved)
      .addCase(putCaseNote.fulfilled, setResolved)
      .addCase(postCaseNote.rejected, ErrorCase)
      .addCase(getCaseNotes.rejected, ErrorCase)
      .addCase(putCaseNote.rejected, ErrorCase);
  },
});

export const { setCaseNotes, setCaseNoteError } = caseNoteSlice.actions;

export const getAllCaseNotes = (state: MyStore) => state[caseNoteSlice.name];
export const getCaseNoteError = (state: MyStore) => state[caseNoteSlice.name].caseNoteError;

export default caseNoteSlice;
