/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { Agreements, Requisites } from '../types/agreement';
import { setSuccess } from './notificationSlice';

export const postAgreement = createAsyncThunk(
  'agreement/postAgreement',
  async ({ clientId, unitId }: { clientId: string, unitId: string }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.CLIENTS}/${clientId}${API_ENDPOINTS.AGREEMENT}`;
      const res = await Http.post(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні договору!');
      }

      dispatch(setSuccess('Договір створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAgreement = createAsyncThunk(
  'agreement/getAgreement',
  async (agreementId: string, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.CLIENTS}${API_ENDPOINTS.AGREEMENT}/${agreementId}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при отриманні договору!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAgreementByClient = createAsyncThunk(
  'agreement/getAgreementByClient',
  async (clientId: string, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.CLIENTS}/${clientId}${API_ENDPOINTS.AGREEMENT}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при отриманні договору!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const printAgreement = createAsyncThunk(
  'agreement/printAgreement',
  async (clientId: string, { rejectWithValue }) => {
    try {
      Http.defaults.responseType = 'blob';
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.CLIENTS}/${clientId}${API_ENDPOINTS.AGREEMENT}${API_ENDPOINTS.PDF}`;
      const blobData: Blob = await Http.get(url);

      if (blobData) {
        const fileUrl = URL.createObjectURL(blobData);
        window.open(fileUrl, '_blank');
      } else {
        throw new Error('Немає відповіді з сервера при загрузці договору!');
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const postRequisites = createAsyncThunk(
  'agreement/postRequisites',
  async ({ clientId, body }: { clientId: string, body: Requisites }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.CLIENTS}/${clientId}${API_ENDPOINTS.REQUISITES}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні реквізитів!');
      }

      dispatch(setSuccess('Реквізити створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const editRequisites = createAsyncThunk(
  'agreement/editRequisites',
  async ({ clientId, body }: { clientId: string, body: Requisites }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.CLIENTS}/${clientId}${API_ENDPOINTS.REQUISITES}`;
      const res = await Http.patch(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при редагуванні реквізитів!');
      }

      dispatch(setSuccess('Реквізити оновлено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.agreementStatus = 'loading';
  state.agreementError = null;
}

const setResolved = (state: any) => {
  state.agreementStatus = 'resolved';
}

const setError = (state: any, action: any) => {
  state.agreementStatus = 'rejected';
  state.agreementError = action.payload;
}

const initialState: Agreements = {
  agreementStatus: null,
  agreementError: null,
};

const agreementSlice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    setAgreementError: (state, action) => {
      state.agreementError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAgreement.pending, setLoading)
      .addCase(printAgreement.pending, setLoading)
      .addCase(postRequisites.pending, setLoading)
      .addCase(editRequisites.pending, setLoading)
      .addCase(postAgreement.fulfilled, setResolved)
      .addCase(printAgreement.fulfilled, setResolved)
      .addCase(postRequisites.fulfilled, setResolved)
      .addCase(editRequisites.fulfilled, setResolved)
      .addCase(postAgreement.rejected, setError)
      .addCase(printAgreement.rejected, setError)
      .addCase(postRequisites.rejected, setError)
      .addCase(editRequisites.rejected, setError);
  },
});

export const { setAgreementError } = agreementSlice.actions;

export const getAgreementError = (state: MyStore) => state[agreementSlice.name].agreementError;

export default agreementSlice;
