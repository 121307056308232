import { Box } from '@mui/material';
import React from 'react';
import { UnitUserData } from '@/redux/types/unitUsers';
import UnitUserForm from '@/forms/Forms/UnitUserForm';
import FormWrapper from '@/forms/FormWrapper';
import { sxWrapper } from '../../Services/ServiceItems/styles';
import UserItem from '../UserItem';
import ModalComponent from '../../../_common/ModalComponent';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { getUnitUser } from '@/redux/slices/unitUsersSlice';
import { ROUTES } from '@/constants';

interface UserItemsProps {
  users: UnitUserData[];
  selectable?: boolean;
  isInputVisible?: boolean;
  setInputVisible?: (arg: boolean) => void;
  btnTitle?: string;
  otherInputs?: boolean | undefined;
  userModalOpen?: () => void;
}

const UserItems: React.FC<UserItemsProps> = ({
  users,
  selectable,
  setInputVisible,
  isInputVisible,
  btnTitle,
  otherInputs,
  userModalOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { unitUserError } = useAppSelector(state => state.unitUsers);

  const unitSelectHandler = (unitUser: UnitUserData) => {
    dispatch(getUnitUser(unitUser.id));
  };

  if (unitUserError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box>
      <Box sx={sxWrapper}>
        {users.map((user, index) => {
          return (
            <UserItem
              userModalOpen={userModalOpen}
              key={index}
              selectable={selectable}
              userData={user}
              selectHandler={unitSelectHandler}
              {...user}
            />
          );
        })}
      </Box>
      {btnTitle && (
        <FormWrapper
          btnTitle={btnTitle}
          isInputVisible={isInputVisible}
          setInputVisible={setInputVisible}
        >
          {otherInputs ? (
            <></>
          ) : (
            <ModalComponent
              title='Введіть дані користувача'
              isOpen={isInputVisible !== undefined && isInputVisible}
              setClose={() => setInputVisible && setInputVisible(false)}
            >
              <UnitUserForm setInputVisible={setInputVisible} />
            </ModalComponent>
          )}
        </FormWrapper>
      )}
    </Box>
  );
};

export default UserItems;
