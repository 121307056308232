import { COLORS } from '@/assets/сolors/colors';

export const sxSearch = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
  width: '100%',
  marginRight: '10px',
  boxShadow: '0 4px 12px 0 #e8e8e8',
};

export const sxSearchMobile = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
  width: '100%',
  marginRight: '5px',
  boxShadow: '0 4px 12px 0 #e8e8e8',
};

export const sxSearchBox = {
  display: 'flex',
  width: '100%',
  marginBottom: '20px',
};

export const sxSearchButtonIcon = {
  marginLeft: '10px',
  minWidth: '60px',
  minHeight: '35px',
};

export const sxSearchButtonIconMobile = {
  marginLeft: '10px',
  minWidth: '30px',
  minHeight: '35px',
};

export const sxCaseBox = {
  maxHeight: '600px',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginBottom: '20px',
};

export const sxCaseNotFound = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '20px',
};

export const sxListItem = {
  pb: '2px',
  pt: '2px',
};
