import React, { useState } from 'react';
import { Avatar, Box, Card, TextareaAutosize, Typography } from '@mui/material';
import {
  sxComment,
  sxCommentBox,
  sxCommentSendBtn,
  sxSendCommentBtnIcon,
  sxCommentButtonIconDisabled,
  sxEditCommentBtnIcon,
  sxCommentEditBtn,
  sxCommentEditDisabledBtn,
  sxCard,
  sxAvatarBox,
  sxCommentCreatedBox,
  sxCommentDateAndName,
  sxAgreeIcon,
  sxDeleteIcon,
  sxAgreeBtn,
  sxDeleteBtn,
  sxEditComment,
  sxAlignButtons,
  sxCaseNote,
  sxCaseNoteMobile
} from './styles';
import IMG_IMAGES from '../../../../assets/images/img';
import { truncateName } from '../../../../utils/truncateName';
import { Case, CaseNote } from '../../../../redux/types/case';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { LOCAL_STORAGE, ROUTES } from '../../../../constants';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import './CaseNotes.css';
import moment from 'moment';
import { Check, Close } from '@mui/icons-material';
import Button from '../../../_common/Button';
import { getAllCaseNotes, getCaseNotes, postCaseNote, putCaseNote } from '@/redux/slices/caseNoteSlice';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@/redux/slices/userSlice';
import { useMediaQuery } from 'react-responsive';

interface CaseNotesProps {
  selectedCase: Case;
}

const CaseNotes: React.FC<CaseNotesProps> = ({ selectedCase }) => {
  const [commentText, setCommentText] = useState<string>('');
  const { caseNotes } = useAppSelector(getAllCaseNotes);
  const { user } = useAppSelector(getCurrentUser);
  const unitId = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION_UNIT_ID);
  const [editingStates, setEditingStates] = useState<boolean[]>(caseNotes ? caseNotes.map(() => false) : []);
  const [editedComments, setEditedComments] = useState<string[]>(caseNotes ? caseNotes.map(() => '') : []);
  const [isEditingAnyComment, setIsEditingAnyComment] = useState(false);
  const dispatch = useAppDispatch();
  const { caseNoteError } = useAppSelector(state => state.caseNote);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleEditComment = (index: number, commentText: string) => {
    const newEditingStates = [...editingStates];
    newEditingStates[index] = true;
    setEditingStates(newEditingStates);

    const newEditedComments = [...editedComments];
    newEditedComments[index] = commentText;
    setEditedComments(newEditedComments);
    setIsEditingAnyComment(true);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(event.target.value);
  };

  const handleCommentSubmit = async () => {
    if (unitId) {
      await dispatch(postCaseNote({ unitId, caseNumber: selectedCase.caseNumber, body: { text: commentText }}));
      await dispatch(getCaseNotes({ unitId, caseNumber: selectedCase.caseNumber }));
      setCommentText('');
    }
  };

  if (caseNoteError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <Box sx={isMobile ? sxCaseNoteMobile : sxCaseNote}>
      <Box sx={sxCommentBox}>
        <TextareaAutosize
          minRows={1}
          maxRows={10}
          value={commentText}
          onChange={handleCommentChange}
          style={sxComment}
          placeholder='Введіть коментар...'
        />
        <Box>
          <Button
            title='Залишити коментар'
            sx={sxCommentSendBtn}
            startIcon={<SendIcon sx={commentText ? sxSendCommentBtnIcon : sxCommentButtonIconDisabled} />}
            onClick={handleCommentSubmit}
            disabled={!commentText}
          />
        </Box>
      </Box>
      {caseNotes && caseNotes.slice().reverse().map((caseNote: CaseNote, index: number) => {
        const commentCreatedAt = moment(caseNote.publishedDate).format('L HH:mm');
        const commentEditedAt = moment(caseNote.lastEditedAt).format('L HH:mm');

        const handleSaveComment = async (index: number, noteId: string) => {
          if (unitId) {
            await dispatch(putCaseNote({
              unitId,
              caseNumber: selectedCase.caseNumber,
              noteId, 
              body: { text: editedComments[index] }
            }));
            await dispatch(getCaseNotes({ unitId, caseNumber: selectedCase.caseNumber }));
      
            const newEditingStates = [...editingStates];
            newEditingStates[index] = false;
            setEditingStates(newEditingStates);
            setIsEditingAnyComment(false);
          }
        };

        const handleCancelEdit = (index: number) => {
          const newEditingStates = [...editingStates];
          newEditingStates[index] = false;
          setEditingStates(newEditingStates);
      
          const newEditedComments = [...editedComments];
          newEditedComments[index] = '';
          setEditedComments(newEditedComments);

          const editingAnyComment = newEditingStates.some((editing) => editing);
          setIsEditingAnyComment(editingAnyComment);
        };

        return (
          <Card key={caseNote.id} sx={sxCard}>
            <Box width='25%'>
              <Box sx={sxAvatarBox}>
                <Avatar src={caseNote.author.profilePicture ? caseNote.author.profilePicture : IMG_IMAGES.AVATAR} />
              </Box>
              <Box textAlign='center'>
                <Typography sx={sxCommentDateAndName} >
                  {truncateName(caseNote.author.name)}
                </Typography>
              </Box>
            </Box>
            <Box width='75%'>
              <Box sx={sxCommentCreatedBox}>
              <Typography sx={sxCommentDateAndName}>
                {commentCreatedAt}
              </Typography>
              <Button
                title='Редагувати коментар'
                sx={user?.id !== caseNote.author.userId || isEditingAnyComment ? sxCommentEditDisabledBtn : sxCommentEditBtn}
                startIcon={<EditIcon sx={sxEditCommentBtnIcon} />}
                disabled={user?.id !== caseNote.author.userId || isEditingAnyComment}
                onClick={() => handleEditComment(index, caseNote.text)}
              />
              </Box>
              {editingStates[index] ? (
                <Box>
                  <TextareaAutosize
                    minRows={1}
                    maxRows={10}
                    value={editedComments[index]}
                    onChange={(e) => {
                      const newEditedComments = [...editedComments];
                      newEditedComments[index] = e.target.value;
                      setEditedComments(newEditedComments);
                    }}
                    style={sxEditComment}
                  />
                  <Box sx={sxAlignButtons}>
                    <Button
                      onClick={() => handleSaveComment(index, caseNote.id)}
                      startIcon={<Check sx={sxAgreeIcon}/>}
                      sx={sxAgreeBtn}
                    />
                    <Button
                      onClick={() => handleCancelEdit(index)}
                      startIcon={<Close sx={sxDeleteIcon} />}
                      sx={sxDeleteBtn}
                    />
                  </Box>
                </Box>
              ) : (
                <>
                  <Typography sx={{ wordWrap: 'break-word' }}>
                    {caseNote.text}
                  </Typography>
                </>
              )}
              <Typography sx={sxCommentDateAndName}>
                {caseNote.publishedDate !== caseNote.lastEditedAt ? `Редаговано: ${commentEditedAt}` : ''}
              </Typography>
            </Box>
          </Card>
        )
      })}
    </Box>
  )
};

export default CaseNotes;
