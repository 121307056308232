import LogoutIcon from '@mui/icons-material/Logout';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';

import { useAuth } from '@/hooks/useAuth';
import { sxListItemMenu } from '../AppBar/AppBarMenuItem/styles';

const Logout = () => {
  const { logOut } = useAuth();

  return (
    <ListItem onClick={logOut} sx={sxListItemMenu}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary='Вихід' />
    </ListItem>
  );
};

export default Logout;
