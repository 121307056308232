import { COLORS } from '@/assets/сolors/colors';

export const sxCaseTextBox = {
  width: '50%',
  marginRight: '15px',
};

export const sxCaseTextBoxMobile = {
  width: '100%',
};

export const sxLinkIcon = {
  color: COLORS.MAIN,
  marginRight: '5px',
};

export const sxLinkBox = {
  display: 'flex',
  alignItems: 'center',
  mb: '10px',
};

export const sxLink = {
  color: COLORS.MAIN,
  textDecoration: 'none',
  '&:hover': {
    color: COLORS.SUCCESS,
    background: COLORS.WHITE,
    textDecoration: 'underline',
  },
}

export const sxDecisionsButton = {
  background: COLORS.WHITE,
  color: COLORS.MAIN,
  '&:hover': {
    color: COLORS.SUCCESS,
    background: COLORS.WHITE,
  },
};

export const sxListItem = {
  pb: '2px',
  pt: '2px',
};
