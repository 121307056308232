import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { createData } from '@/utils/createData';
import BasicTable from '../../../_common/BasicTable';
import { PersonType, SelectedClient } from '@/redux/types/client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { sxButtonItem, sxCaseBox, sxCaseButtonIcon, sxCasesScroll, sxRemoveLine } from './styles';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants';
import Button from '../../../_common/Button';
import Tooltip from '@/components/_common/Tooltip';
import { useMediaQuery } from 'react-responsive';

const ClientInformation: React.FC<SelectedClient> = ({
  name,
  phone,
  email,
  createdAt,
  comment,
  idCode,
  agreementNumber,
  personType,
  courtCases,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>({});
  const clientFrom = moment(createdAt).format('L');
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  let personTypeText = '';
  if (personType === PersonType.Juridical) {
    personTypeText = 'Юридична';
  } 
  if (personType === PersonType.Private) {
    personTypeText = 'Приватна';
  }

  const clientInformation = [
    createData('ПІБ', name),
    createData('Особа', personTypeText),
    createData('Телефон', phone),
    createData('Email', email),
    createData('ІПН', idCode),
    createData('Номер договору', agreementNumber),
    createData('Клієнт з', clientFrom),
    createData('Примітка', comment),
  ];

  const handleScroll = () => {
      setTooltipOpen({});
  };

  return (
    <Box sx={isMobile ? {} : { minWidth: '360px' }}>
      <BasicTable content={clientInformation} />
      {courtCases && courtCases.length > 0 && (
        <Box sx={sxCaseBox}>
          <TableContainer sx={sxCasesScroll} onScroll={handleScroll}>
            <Table>
              <TableBody>
                {courtCases.map((courtCase) => {
                  const handleCaseButton = () => {
                    navigate(`${ROUTES.CASE}?caseNumber=${courtCase.caseNumber}`);
                  }

                  return (
                    <TableRow key={courtCase.caseNumber}>
                        <TableCell sx={sxRemoveLine}>
                            <Box>
                              <Tooltip title={courtCase.description} open={tooltipOpen[courtCase.caseNumber] || false}>
                                <span
                                  onMouseEnter={() => setTooltipOpen({ ...tooltipOpen, [courtCase.caseNumber]: true })}
                                  onMouseLeave={() => setTooltipOpen({ ...tooltipOpen, [courtCase.caseNumber]: false })}
                                >
                                  Справа: {courtCase.caseNumber}
                                </span>
                              </Tooltip>
                            </Box>
                          <Box>
                            {courtCase.courtName}
                          </Box>
                          <Box>
                            Статус: {courtCase.lastStatus}
                          </Box>
                        </TableCell>
                      <TableCell sx={sxButtonItem}>
                        <Button
                          variant='contained'
                          title='Перейти до справи'
                          startIcon={<ArrowForwardIcon sx={sxCaseButtonIcon}/>}
                          onClick={handleCaseButton}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default ClientInformation;
