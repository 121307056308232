import { LoadingButton } from '@mui/lab';
import React from 'react';

import { sxFormBtn } from './styles';

interface BasicLoadingButtonProps {
  loading: boolean;
  isValid: boolean;
  title?: string;
}

const BasicLoadingButton: React.FC<BasicLoadingButtonProps> = ({
  loading,
  isValid,
  title = 'Створити',
}) => {
  return (
    <LoadingButton
      loading={loading}
      type='submit'
      sx={sxFormBtn}
      variant='contained'
      fullWidth
      disabled={!isValid}
    >
      {title}
    </LoadingButton>
  );
};

export default BasicLoadingButton;
