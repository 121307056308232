import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import * as React from 'react';
import { SxProps } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionComponentProps {
  id: string;
  sx: SxProps;
  accordionSummaryChildren: JSX.Element;
  accordionDetailsChildren: JSX.Element;
  ariaControls: string;
  expanded: boolean;
  handleAccordionExpandChange: (
    event: React.SyntheticEvent,
    expanded: boolean
  ) => void;
}

const AccordionComponent: React.FC<AccordionComponentProps> = ({
  id,
  accordionSummaryChildren,
  accordionDetailsChildren,
  expanded,
  ariaControls,
  handleAccordionExpandChange,
  ...rest
}) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionExpandChange}
      square
      {...rest}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={ariaControls}
        id={id}
      >
        {accordionSummaryChildren}
      </AccordionSummary>
      <AccordionDetails>{accordionDetailsChildren}</AccordionDetails>
    </Accordion>
  );
};
export default AccordionComponent;
