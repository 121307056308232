export function truncateName(name: string) {
  const words = name.split(' ');
  const truncatedWords = words.map((word) => {
    if (word.length > 9) {
      return word.substring(0, 9) + '...';
    }
    return word;
  });
  return truncatedWords.join(' ');
}
