export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'https://api.advocado.codeframeio.net/api/v1'; 
export const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL ?? 'wss://api.advocado.codeframeio.net';

export const API_ENDPOINTS = {
  USER: '/users/me',
  UNITS: '/units',
  CLIENTS: '/clients',
  ORGANIZATIONS: '/organizations',
  SERVICE_CATEGORIES: '/service-categories',
  UNIT_SERVICES: '/unit-services',
  UNIT_USERS: '/unit-users',
  UNIT_ROLES: '/unit-roles',
  INVITE: '/invite',
  INVITATIONS: '/invitations',
  SERVICE_PACKAGES: '/service-packages',
  TRACKERS: '/trackers',
  INVOICES: '/invoices',
  AGREEMENT: '/agreement',
  PDF: '/pdf',
  REQUISITES: '/requisites',
  SOCKETS: '/sockets',
  EVENTS: '/events',
  INTERVALS: '/intervals',
  SEARCH: '/search',
  COURT_CASES: '/court-cases',
  CASE_NUMBER: '/caseNumber',
  NOTES: '/notes',
};
