export const sxNotFoundWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const sxNotFoundContent = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
};

export const sxNotFoundIcon = {
  minHeight: '256px',
  width: 'auto',
  mb: '32px',
};

export const sxNotFoundBtn = {
  minWidth: '25%',
  marginBottom: '20px'
};

export const sxNotFoundBtnMobile = {
  minWidth: '240px',
  marginBottom: '20px'
};
