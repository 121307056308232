import { Delete, Edit, WarningAmber } from '@mui/icons-material';
import { Box, TableCell, TableRow, TextField } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import TimeTrackerPackageForm from '@/forms/Forms/TimeTrackerPackageForm';
import { CategoryData } from '@/redux/types/category';
import { Tracker } from '@/redux/types/tracker';
import ModalComponent from '../../../_common/ModalComponent';
import WarningModal from '../../../_common/WarningModal';
import { sxButtonIcon, sxDate, sxDeleteItem, sxEditItem, sxRemoveLine, sxRow } from '../TimeTrackerAccordionDetails.tsx/styles';
import { sxWarning } from '../TimeTrackerAccordionSummary/styles';
import TrackerButtons from '../TrackerButtons';
import TrackerIntervals from '../TrackerIntervals';
import { sxIntervalBtn } from '../ViewTimeTracker/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatTime } from '@/utils/formatSecondsToTime';
import { sxCategoryAndServiceBox, sxWrapperforEditFields } from './styles';
import Button from '../../../_common/Button';

interface Props {
  categories: CategoryData[];
  tracker: Tracker;
  editHandler: () => void;
  deleteHandler: () => void;
  isWarningOpen: boolean;
  setIsWarningOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditTracker: React.Dispatch<React.SetStateAction<boolean>>;
  trackerDate: string;
  handleChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditTimeTracker: React.FC<Props> = ({
  trackerDate,
  categories,
  tracker,
  editHandler,
  deleteHandler,
  isWarningOpen,
  setIsWarningOpen,
  setIsEditTracker,
  handleChangeDate,
}) => {
  const [isIntervalsPopupVisible, setIsIntervalsPopupVisible] = useState<boolean>(false);
  const handleIntervals = () => setIsIntervalsPopupVisible(true);
  const trackerDisabled = tracker.status === 'invoiced';

  return (
    <>
      <TableRow sx={sxRow} >
        <TableCell component='th' scope='row' >
          <TextField
            label='Дата треку'
            value={trackerDate}
            onChange={handleChangeDate}
            type='date'
            sx={sxDate}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: moment().date() >= 1 && moment().date() <= 4
                ? moment().subtract(1, 'month').date(5).format('YYYY-MM-DD')
                : moment().date(5).format('YYYY-MM-DD'),
              max: moment().format('YYYY-MM-DD'),
            }}
          />
        </TableCell>
        <TableCell component='th' scope='row' >
          {tracker.courtCase ? (
            <>
              {`Справа №${tracker.courtCase.caseNumber}, ${tracker.courtCase.courtName}, Суддя ${tracker.courtCase.judge}, Код ${tracker.courtCase.judgmentCode}, ${tracker.courtCase.lastStatus}`}
            </>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Cправа не додана
                <Box sx={{ marginLeft: 1 }}>
                  <WarningAmber sx={sxWarning} />
                </Box>
              </Box>
            )
          }
        </TableCell>
        <TableCell component='th' scope='row' align='center'>
          <TrackerButtons tracker={tracker} />
        </TableCell>
      </TableRow>
      <TableRow sx={sxRow} >
        <TableCell component='th' scope='row' sx={sxRemoveLine}>
          <Box sx={{ display: 'flex' }}>
            <Button
              variant='contained'
              title='Редагувати послугу'
              sx={sxEditItem}
              onClick={editHandler}
              startIcon={<Edit sx={sxButtonIcon} />}
            />
            <Button
              variant='contained'
              title='Видалити послугу'
              sx={sxDeleteItem}
              startIcon={<Delete sx={sxButtonIcon} />}
              onClick={() => {
                setIsWarningOpen(true)
              }} 
            />
            <WarningModal
              title='Дійсно видалити послугу?'
              open={isWarningOpen}
              handleAgree={deleteHandler}
              handleClose={() => setIsWarningOpen(false)}
            />
          </Box>
        </TableCell>
        <TableCell colSpan={2} sx={sxRemoveLine}>
          <Box sx={sxWrapperforEditFields}>
            <Box sx={sxCategoryAndServiceBox}>
              <TimeTrackerPackageForm
                categories={categories}
                setIsOpen={setIsEditTracker}
                trackerDate={trackerDate}
              />
            </Box>
            <Button
              sx={sxIntervalBtn}
              onClick={handleIntervals}
              disabled={trackerDisabled}
              buttonTitle={formatTime(tracker.timeSeconds)}
            />
          </Box>
        </TableCell>
      </TableRow>
      <ModalComponent
        isOpen={isIntervalsPopupVisible}
        setClose={() => setIsIntervalsPopupVisible(false)}
        title='Часові інтервали'
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TrackerIntervals tracker={tracker} />
        </LocalizationProvider>
      </ModalComponent>
    </>
  );
};

export default EditTimeTracker;
