import { AccessTime, Delete, Edit, WarningAmber } from '@mui/icons-material';
import { Box, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { Tracker } from '@/redux/types/tracker';
import { formatTime } from '@/utils/formatSecondsToTime';
import ModalComponent from '../../../_common/ModalComponent';
import WarningModal from '../../../_common/WarningModal';
import { sxButtonIcon, sxClockIcon, sxDeleteItem, sxEditItem, sxRemoveLine, sxRow } from '../TimeTrackerAccordionDetails.tsx/styles';
import { sxWarning } from '../TimeTrackerAccordionSummary/styles';
import TrackerButtons from '../TrackerButtons';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TrackerIntervals from '../TrackerIntervals';
import { sxAlignIntervalBtn, sxIntervalBtn, sxTrackerBox, sxWarningMargin } from './styles';
import Button from '../../../_common/Button';

interface Props {
  tracker: Tracker;
  editHandler: () => void;
  deleteHandler: () => void;
  isWarningOpen: boolean;
  setIsWarningOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewTimeTracker: React.FC<Props> = ({
  tracker,
  editHandler,
  deleteHandler,
  isWarningOpen,
  setIsWarningOpen,
}) => {
  const [isIntervalsPopupVisible, setIsIntervalsPopupVisible] = useState<boolean>(false);

  const handleIntervals = () => setIsIntervalsPopupVisible(true);

  const trackerDate = moment(tracker.trackerDate).format('L');
  const trackerDisabled = tracker.status === 'invoiced';

  return (
    <>
      <TableRow sx={sxRow} >
        <TableCell component='th' scope='row' height='56px'>
          {trackerDate}
        </TableCell>
        <TableCell component='th' scope='row' >
          {tracker.courtCase ? (
            <>
              {`Справа №${tracker.courtCase.caseNumber}, ${tracker.courtCase.courtName}, Суддя ${tracker.courtCase.judge}, Код ${tracker.courtCase.judgmentCode}, ${tracker.courtCase.lastStatus}`}
            </>
            ) : (
              <Box sx={sxTrackerBox}>
                Cправа не додана
                <Box sx={sxWarningMargin}>
                  <WarningAmber sx={sxWarning} />
                </Box>
              </Box>
            )
          }
        </TableCell>
        <TableCell component='th' scope='row' align='center'>
          <TrackerButtons tracker={tracker} trackerDisabled={trackerDisabled} />
        </TableCell>
      </TableRow>
      <TableRow sx={sxRow} >
        <TableCell component='th' scope='row' sx={sxRemoveLine}>
          <Box sx={{ display: 'flex' }}>
            <Button
              variant='contained'
              title='Редагувати послугу'
              sx={sxEditItem}
              onClick={editHandler}
              disabled={trackerDisabled}
              startIcon={<Edit sx={sxButtonIcon} />}
            />
            <Button
              variant='contained'
              title='Видалити послугу'
              sx={sxDeleteItem}
              startIcon={<Delete sx={sxButtonIcon} />}
              onClick={() => {
                setIsWarningOpen(true)
              }} 
            />
            <WarningModal
              title='Дійсно видалити послугу?'
              open={isWarningOpen}
              handleAgree={deleteHandler}
              handleClose={() => setIsWarningOpen(false)}
            />
          </Box>
        </TableCell>
        <TableCell component='th' scope='row' sx={sxRemoveLine}>
          {tracker.service && tracker.service.name
            ? tracker.service.name : (
              <Box sx={sxTrackerBox}>
                Сервіс не доданий
                <Box sx={sxWarningMargin}>
                  <WarningAmber sx={sxWarning} />
                </Box>
              </Box>
            )
          }
        </TableCell>
        <TableCell component='th' scope='row' sx={sxRemoveLine} align='center'>
          {tracker.status === 'tracking' ? (
            <AccessTime sx={ sxClockIcon } />
          ) : (
            tracker.timeSeconds
            ? (
              <Button
                sx={sxIntervalBtn}
                onClick={handleIntervals}
                disabled={trackerDisabled}
                buttonTitle={formatTime(tracker.timeSeconds)}
              />
            ) : (
              <Box sx={sxAlignIntervalBtn}>
                <Button
                  sx={sxIntervalBtn}
                  onClick={handleIntervals}
                  disabled={trackerDisabled}
                  buttonTitle={formatTime(tracker.timeSeconds)}
                />
                <Box sx={sxWarningMargin}>
                  <WarningAmber sx={sxWarning} />
                </Box>
              </Box>
            )
          )}
        </TableCell>
      </TableRow>
      
      <ModalComponent
        isOpen={isIntervalsPopupVisible}
        setClose={() => setIsIntervalsPopupVisible(false)}
        title='Часові інтервали'
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TrackerIntervals tracker={tracker} />
        </LocalizationProvider>
      </ModalComponent>
    </>
  );
};

export default ViewTimeTracker;
