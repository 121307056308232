export interface SearchData {
  search: Search[];
  searchPhrase: string | null;
  searchStatus: string | null;
  searchError: string | null;
}

export interface Search {
  id: string;
  userId: string;
  unitUserId: string;
  entityBody: string;
  entityId: string;
  type: SearchType;
}

export enum SearchType {
  UnitUser = 'unit-user',
  Client = 'client',
  CourtCase = 'court-case',
  UnitService = 'unit-service',
}
