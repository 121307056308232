/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch } from 'react-redux';
import { LOCAL_STORAGE, ROUTES } from '@/constants';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import { setUser } from '@/redux/slices/userSlice';
import Http from '@/services/http';
import { useNavigate } from 'react-router-dom';
import { User } from '@/redux/types/user';

const useUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUser = async () => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.USER}`;
      const res: User = await Http.get(url);

      if (res) {
        dispatch(setUser(res));
        localStorage.setItem(LOCAL_STORAGE.USER, res.email);
        localStorage.setItem(
          LOCAL_STORAGE.ORGANIZATION_UNIT_ID,
          res.organizations[0].organizationUnits[0].id
        );
      }
    } catch (e: any) {
      console.error('getUnit request:', e.message);
      navigate(ROUTES.LOGIN);
    }
  };
  
  return {
    getUser,
  };
};

export default useUser;
