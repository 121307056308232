import { COLORS } from '@/assets/сolors/colors';

export const sxTimeDatePicker = {
  '& label.Mui-focused': {
    color: COLORS.SUCCESS,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.MAIN_LIGHT,
    },
    '&:hover fieldset': {
      borderColor: COLORS.SUCCESS,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.SUCCESS,
    },
  },
  marginBottom: '10px',
};

export const sxTimeColumn = {
  width: '20%',
};

export const sxEditDateTimePickerColumn = {
  textAlign: 'center',
};

export const sxIntervalModal = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
};

export const sxTableScroll = {
  maxHeight: '600px',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
};
