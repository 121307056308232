import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { getSearch, getSearchPhrase, setSearch, setSearchPhrase } from '@/redux/slices/SearchSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { StyledIconWrapper, StyledInputBase, StyledSearch } from './styles';

const AppBarSearch: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchPhrase = useAppSelector(getSearchPhrase) || '';
  const { searchError } = useAppSelector(state => state.search);
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith(ROUTES.SEARCH)) {
      dispatch(setSearchPhrase(null));
      dispatch(setSearch(null));
    }
  }, [location.pathname]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchQuery = event.target.value;
    dispatch(setSearchPhrase(newSearchQuery));
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!searchPhrase) {
        return;
      }
      navigate(`${ROUTES.SEARCH}?query=${searchPhrase}`);
      setTimeout(() => {
        dispatch(getSearch(searchPhrase));
      }, 1000);
    }
  };

  const handleSearchClick = () => {
    if (!searchPhrase) {
      return;
    }
    navigate(`${ROUTES.SEARCH}?query=${searchPhrase}`);
    setTimeout(() => {
      dispatch(getSearch(searchPhrase));
    }, 100);
  }

  if (searchError) {
    navigate(ROUTES.ERROR);
  }

  return (
    <StyledSearch>
      <StyledIconWrapper>
        <SearchIcon onClick={handleSearchClick} />
      </StyledIconWrapper>
      <StyledInputBase
        value={searchPhrase}
        placeholder='Введіть значення…'
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearchChange}
        onKeyPress={handleInputKeyPress}
        type='text'
      />
    </StyledSearch>
  );
};

export default AppBarSearch;
