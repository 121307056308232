/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL, API_ENDPOINTS } from '@/constants/api';
import Http from '@/services/http';
import { MyStore } from '../store';
import { Trackers } from '../types/tracker';
import { setSuccess } from './notificationSlice';

export const postTracker = createAsyncThunk(
  'tracker/postTracker',
  async ({ unitId, body }: { unitId: string, body: any }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.TRACKERS}`;
      const res = await Http.post(url, body);

      if (!res) {
        throw new Error('Немає відповіді з сервера при створенні трекера!');
      }

      dispatch(setSuccess('Трек створено успішно'));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getTrackers = createAsyncThunk(
  'tracker/getTrackers',
  async ({ page = 1, limit = 50 }: { page?: number, limit?: number }, { rejectWithValue }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.TRACKERS}?page=${page}&limit=${limit}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі списку трекерів!');
      }

      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getTracker = createAsyncThunk(
  'tracker/getTracker',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.TRACKERS}/${id}`;
      const res = await Http.get(url);

      if (!res) {
        throw new Error('Немає відповіді з сервера при виводі трекера!');
      }

      dispatch(setSelectedTracker(res));
      return res;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const editTracker = createAsyncThunk(
  'tracker/editTracker',
  async ({ unitId, body }: { unitId: string, body: any }, { rejectWithValue, dispatch }) => {
    try {
      const { id } = body;
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}/${unitId}${API_ENDPOINTS.TRACKERS}/${id}`;
      const res = await Http.patch(url, body);

      if (res) {
        dispatch(setSelectedTracker(res));
        dispatch(setSuccess('Інформацію змінено успішно'));

        return res;
      } else {
        throw new Error('Немає відповіді з сервера при редагуванні трекера!');
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const editTrackerInterval = createAsyncThunk(
  'tracker/editTrackerInterval',
  async ({ id, intervalId, body }: { id: string, intervalId: string, body: any }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.TRACKERS}/${id}${API_ENDPOINTS.INTERVALS}/${intervalId}`;
      const res = await Http.patch(url, body);

      if (res) {
        dispatch(setSelectedTracker(res));
        dispatch(setSuccess('Інформацію змінено успішно'));

        return res;
      } else {
        throw new Error('Немає відповіді з сервера при редагуванні інтервала трекера!');
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteTracker = createAsyncThunk(
  'tracker/deleteTracker',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.TRACKERS}/${id}`;
      await Http.delete(url);

      dispatch(setSuccess('Трек видалено успішно'));
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteTrackerInterval = createAsyncThunk(
  'tracker/deleteTrackerInterval',
  async ({ id, intervalId }: { id: string, intervalId: string }, { rejectWithValue, dispatch }) => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.UNITS}${API_ENDPOINTS.TRACKERS}/${id}${API_ENDPOINTS.INTERVALS}/${intervalId}`;
      await Http.delete(url);

      dispatch(setSuccess('Інтервал видалено успішно'));
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const setLoading = (state: any) => {
  state.trackerStatus = 'loading';
  state.trackerError = null;
}

const setTrackerResolved = (state: any, action: any) => {
  state.trackerStatus = 'resolved';
  state.trackers = action.payload;
}

const setResolved = (state: any) => {
  state.trackerStatus = 'resolved';
}

const setError = (state: any, action: any) => {
  state.trackerStatus = 'rejected';
  state.trackerError = action.payload;
}

const initialState: Trackers = {
  trackers: null,
  selectedTracker: null,
  activeTrackers: [],
  trackerStatus: null,
  trackerError: null,
};
const trackerSlice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    setActiveTrackers: (state, action) => {
      state.activeTrackers = action.payload;
    },
    setSelectedTracker: (state, action) => {
      state.selectedTracker = action.payload;
    },
    setTrackerError: (state, action) => {
      state.trackerError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postTracker.pending, setLoading)
      .addCase(getTrackers.pending, setLoading)
      .addCase(getTracker.pending, setLoading)
      .addCase(editTracker.pending, setLoading)
      .addCase(editTrackerInterval.pending, setLoading)
      .addCase(deleteTracker.pending, setLoading)
      .addCase(deleteTrackerInterval.pending, setLoading)
      .addCase(postTracker.fulfilled, setResolved)
      .addCase(getTrackers.fulfilled, setTrackerResolved)
      .addCase(getTracker.fulfilled, setResolved)
      .addCase(editTracker.fulfilled, setResolved)
      .addCase(editTrackerInterval.fulfilled, setResolved)
      .addCase(deleteTracker.fulfilled, setResolved)
      .addCase(deleteTrackerInterval.fulfilled, setResolved)
      .addCase(postTracker.rejected, setError)
      .addCase(getTrackers.rejected, setError)
      .addCase(getTracker.rejected, setError)
      .addCase(editTracker.rejected, setError)
      .addCase(editTrackerInterval.rejected, setError)
      .addCase(deleteTracker.rejected, setError)
      .addCase(deleteTrackerInterval.rejected, setError);
  },
});

export const { setActiveTrackers, setSelectedTracker, setTrackerError } = trackerSlice.actions;

export const getAllTrackers = (state: MyStore) => state[trackerSlice.name];
export const getActiveTrackers = (state: MyStore) => state[trackerSlice.name].activeTrackers;
export const getSelectedTracker = (state: MyStore) => state[trackerSlice.name].selectedTracker;
export const getTrackerError = (state: MyStore) => state[trackerSlice.name].trackerError;

export default trackerSlice;
