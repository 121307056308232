import { COLORS } from '@/assets/сolors/colors';

export const sxPrintTopButtons = {
  width: '100%',
};

export const sxPrintTopButtonsMobile = {
  minWidth: '240px',
};

export const sxPrintButton = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mb: '12px',
};

export const sxWarningIcon = {
  color: COLORS.DELETE_LIGHT,
  minWidth: '37px',
  minHeight: '37px',
};

export const sxWarningIconHiden = {
  display: 'none',
};

export const sxWarningIconBox = {
  display: 'flex',
  justifyContent: 'center',
  alighItems: 'center',
};
